<app-header-distribuidor></app-header-distribuidor>
<div class="d-flex justify-content-center align-items-center flex-column">
  <div class="contenido w-100 pb-5">
    <!--Volver-->
    <div class="volver-container d-flex flex-wrap w-100 pb-4">
      <div class="col-lg-2 col-md-3 col-sm-4 col-12 p-0">
        <button class="boton-volver" routerLink="/clientes">
          <fa-icon [icon]="faChevronLeft"></fa-icon>
          Volver
        </button>
      </div>
      <div class="col-lg-10 col-md-9 col-sm-8 col-12 d-flex align-items-center p-0">
        <h3>{{ puntoEntrega?.nombre || 'Punto de entrega' }}</h3>
      </div>
    </div>
    <!-- Container detalle -->
    <div class="container-detalle mt-3" *ngIf="establecimiento">
      <!-- Información cliente -->
      <div class="card-client">
        <div class="header-card mt-3 d-flex justify-content-between">
          <div class="d-flex ml-3 p-3">
            <img src="{{ establecimiento?.logo }}" alt="logo_cliente" class="logo-cliente" />
            <div class="information-client ml-2">
              <h6>{{ establecimiento.nombre_establecimiento }}</h6>
              {{ puntoEntrega?.informacion_contacto[0] }} {{ puntoEntrega?.informacion_contacto[1] }}
            </div>
          </div>
          <div class="p-3">
            <p>NIT/CC. {{ establecimiento?.nit }}</p>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-start">
          <div class="specification-client ml-4">
            <p class="m-0 pill">Trabajador encargado</p>
          </div>
        </div>
        <div class="d-flex justify-content-around mt-3 px-4">
          <p class="tipo-negocio col-6 pill">
            <span class="px-2 ellipsis w-100">
              {{ establecimiento?.tipo_negocio }}
            </span>
          </p>
          <p class="chair col-3 pill">{{ puntoEntrega?.sillas }} sillas</p>
          <div class="col-3 p-0">
            <p *ngIf="dataDistribuidor?.convenio === true"
              class="convenio w-100 pill w-100 d-flex justify-content-center">$ Convenio</p>
          </div>
        </div>
        <div class="border"></div>
        <div class="p-3 ml-3">
          <div class="d-flex align-items-center">
            <img class="icono mr-4" src="../../../assets/img/icons/icon-users.png" alt="" />
            <p class="m-0">{{ puntoEntrega?.numero_trabajadores }} integrantes en el equipo</p>
          </div>
          <div class="d-flex align-items-center">
            <img class="icono mr-4" src="../../../assets/img/icons/icono-26.png" alt="" />
            <p class="m-0">{{ statisticsDistribuidor?.pedidos_entregados }} pedidos entregados</p>
          </div>
        </div>
        <div class="border"></div>
        <div class="p-3 ml-3">
          <p class="m-0">Promedio de compra mensual</p>
          <p >{{ totalCompras | currency: '$':'symbol':'1.0-0' }}</p>
          <p class="m-0">Promedio no. de referencias x pedido</p>
          <p >{{ totalProductos| number: '1.0-1' }}</p>
          <p class="m-0">Servicio a domicilio</p>
          <p class="m-0">{{ puntoEntrega?.domicilios ? 'Sí' : 'No' }}</p>
        </div>
      </div>
      <!-- Información contacto -->
      <div class="information-contact">
        <div class="p-3">
          <div>
            <h5 class="font-weight-300">Información de contacto</h5>
          </div>
          <div class="d-flex justify-content-between">
            <p>Ciudad</p>
            <p>{{ puntoEntrega?.ciudad }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p>Dirección</p>
            <p>{{ puntoEntrega?.direccion }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p>Teléfono</p>
            <p>{{ puntoEntrega?.informacion_contacto[3] }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p class="m-0">Email</p>
            <p class="m-0">{{ puntoEntrega?.informacion_contacto[2] }}</p>
          </div>
        </div>
        <div class="p-3" *ngIf="propietario" style="display: none;">
          <div>
            <h5 class="font-weight-300">Información propietario</h5>
          </div>
          <div class="d-flex justify-content-between">
            <p>Nombre</p>
            <p>{{ propietario?.nombres}} {{ propietario?.apellidos}}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p>Telefono</p>
            <p>{{ propietario?.telefono }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p>Email</p>
            <p>{{ propietario?.correo }}</p>
          </div>
        </div>
      </div>
      <!-- Botones -->
      <div class="botones">
        <div class="d-flex mt-3" *ngIf="dataDistribuidor?.estado == 'Pendiente'">
          <button class="rechazar mr-3 bg-light" (click)="rechazarDistribuidor()">Rechazar</button>
          <button class="aprobar bg-light" (click)="onAprobar()">Aprobar</button>
        </div>
        <div class="d-flex mt-3" *ngIf="dataDistribuidor?.estado == 'Aprobado' && cambios.length > 0">
          <button class="guardar" (click)="onSaveChanges()">Guardar cambios</button>
        </div>
      </div>
      <!-- Otros puntos -->
      <div class="otros-puntos">
        <div *ngIf="puntosAdicionales?.length">
          <h5 class="font-weight-300">Otros puntos de entrega</h5>
          <p>
            Este cliente tiene {{puntosAdicionales.length}} punto{{puntosAdicionales.length > 1 ? 's' : ''}} de entrega.
          </p>
          <div class="limit-height">
            <app-puntos-card [punto]="punto" *ngFor="let punto of puntosAdicionales"></app-puntos-card>
          </div>
        </div>
        <div *ngIf="!puntosAdicionales?.length">
          <h5>Otros puntos de entrega</h5>
          <p>Este cliente no tiene otros puntos de entrega.</p>
        </div>
        <app-info-adicional-cliente *ngIf="dataDistribuidor?.estado != 'Pendiente'"
          [distribuidorVinculado]="dataDistribuidor" 
          (onVendedoresChange)="onVendedoresChange($event)"
          (onInfoAdicionalChange)="onInfoAdicionalChange($event)"
          ></app-info-adicional-cliente>
      </div>
      <!-- Documentos -->
      <div class="documentos">
        <h5 class="font-weight-300">Documentos</h5>
        <div class="card-documents p-3 mt-3" (click)="openDocument(CCPDF)" role="button">
          <fa-icon [icon]="iconDownload" [styles]="{ color: '#41403E' }" id="fa-icon" class="mr-3"></fa-icon>
          Doc. Representante Legal
        </div>
        <div class="card-documents p-3 mt-3" (click)="openDocument(camComPDF)" role="button">
          <fa-icon [icon]="iconDownload" [styles]="{ color: '#41403E' }" id="fa-icon" class="mr-3"></fa-icon>
          Cámara de Comercio
        </div>
        <div class="card-documents p-3 mt-3" (click)="openDocument(rutPDF)" role="button">
          <fa-icon [icon]="iconDownload" [styles]="{ color: '#41403E' }" id="fa-icon" class="mr-3"></fa-icon>
          RUT
        </div>
      </div>
    </div>
  </div>
</div>