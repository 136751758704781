import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { Distribuidor } from 'src/app/models/distribuidor.model';
import { RestService } from 'src/app/services/rest/rest.service';
import { NgbActiveModal, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from '../simple/simple.component';
import { Router } from '@angular/router';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-filtro-distribuidores',
  templateUrl: './filtro-distribuidores.component.html',
  styleUrls: ['./filtro-distribuidores.component.css'],
})
export class FiltroDistribuidoresComponent implements OnInit {
  /** Referencias a íconos FontAwesome para la UI */
  public faTimes = faTimes;
  public faStar = faStar;
  public faStarSolid = faStarSolid;

  /** Lista completa de distribuidores para los filtros */
  public buffer_distribuidores: Distribuidor[] = [];

  /** Lista completa de categorias (nombres y estado) */
  public categorias_filtro_distribuidores: any[] = [];

  /** Lista de los distribuidores vinculados con sus categorias */
  public categoriasDistribuidor: any = [];
  public categoriasSeleccionadas: any = [];

  /** Modal de carga para dar feedback al usuario */
  modalCarga?: NgbModalRef;

  /** Lista de distribuidores a filtrar */
  @Input() public distribuidores_componente_padre: any[] = [];
  /** Cantidad de estrellas a filtrar */
  @Input() ranking = 0;
  /** Categorias */
  @Input() todos_filtros_desactivados = false;
  @Input() public categorias_estado: boolean[] = [];
  /** Tiempos de entrega */
  @Input() tiempo_entrega = 'Selecciona';
  /** Callback entre componentes */
  @Input() punto_entrega: any;
  callback = (
    rank: number,
    tiempo: string,
    todos_filtros_desactivados: boolean,
    categorias_estado: boolean[],
    distribuidores_componente_padre: any[]
  ) => {};
  catSeleccionada = '';
  constructor(
    private rest: RestService,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.fetchCategorias();
    /** Buffer de los distribuidores sin filtros */
    this.buffer_distribuidores = this.distribuidores_componente_padre;
    if (this.punto_entrega && Object.keys(this.punto_entrega)?.length > 0) {
      this.fetchCategoriasDistribuidor();
    }
  }

  /**
   * Trae los datos de las categorías de productos
   */
  async fetchCategorias() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    /** Nombre de las categorías */
    await this.rest
      .getJWT('categoria_producto')
      .toPromise()
      .then((resp: any) => {
        this.categorias_filtro_distribuidores = resp.data;
        this.modalCarga?.close();
      })
      .catch((err) => {
        this.modalCarga?.close();
        const ngbModalOptions: NgbModalOptions = {
          backdrop: 'static',
          keyboard: false,
        };
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.callback = this.activeModal.close();
      });
  }

  fetchCategoriasDistribuidor() {
    if (this.router.url === '/distribuidores') {
      const distribuidores = this.buffer_distribuidores.map((dist: any) => dist._id);
      const body = { distribuidores };
      this.rest
        .postJWT(`distribuidores-no-vinculados-filtro`, body)
        .toPromise()
        .then((resp: any) => {
          this.categoriasDistribuidor = resp;
        });
    } else {
      this.rest
        .getJWT(`distribuidores_vinculados_and_categories/${this.punto_entrega?._id}/Aprobado`)
        .toPromise()
        .then((resp: any) => {
          this.categoriasDistribuidor = resp;
        });
    }
  }

  /**
   * Verifica el estado de todos los filtros de las categorias y retorna un array de dist. filtrados
   * Verifica el estado de los botones seleccionados y establece sus estados, por ejemplo:
   * si se selecciona "Todos" deja los demas botones desactivas
   * @param on_boton_todos False si el click  viene de un filtro o True si viene de "Todos"
   */
  handleClickCategoria(on_boton_todos: boolean) {
    /**
     * Por problemas en el biding para mostrar los filtros del padre en el DOM del hijo,
     * se copia la información del padre en un array del hijo solventando este inconveniente
     */
    /********************** Reinicia el array de distribuidores sin filtros aplicados *********************/
    this.distribuidores_componente_padre = this.buffer_distribuidores;
    /******************************** Verifica si existen filtros aplciados *******************************/
    let is_filtro_aplicado = 0; // 0  NO | >0  SI
    for (const cat_estado of this.categorias_estado) {
      cat_estado == true ? (is_filtro_aplicado += 1) : (is_filtro_aplicado += 0);
    }
    if ((on_boton_todos === true && this.todos_filtros_desactivados === true) || is_filtro_aplicado === 0) {
      /************************************** NO hay filtros aplciados *************************************/
      /** Reset de los botonoes y booleanos */
      this.todos_filtros_desactivados = true;
      this.categorias_estado = this.categorias_estado.map((element: boolean) => (element = false));
    } else {
      /************************************** SI hay filtros aplciados *************************************/
      /** Activa el botón del filtro "Todos" */
      this.todos_filtros_desactivados = false;
      /** Crea un array con las categorías seleccionadas para el filtro */
      const categorias_seleccionadas: any = [];
      for (let index = 0; index < this.categorias_estado.length; index++) {
        if (this.categorias_estado[index] === true) {
          categorias_seleccionadas.push(this.categorias_filtro_distribuidores[index]);
        }
      }
      /** Filtra los distribuidores que no están en el array de cat. seleccionadas */
      if (this.router.url == '/inicio') {
        /**La informaciíon de los distribuidores en inicio viene con otro modelo */
        this.distribuidores_componente_padre = this.distribuidores_componente_padre.filter((el: any) => {
          return categorias_seleccionadas.some((f: any) => {
            return f.nombre == el.distribuidor.tipo;
          });
        });
      } else {
        /* this.distribuidores_componente_padre = this.distribuidores_componente_padre.filter((el: any) => {
          return categorias_seleccionadas.some((f: any) => {
            return f.nombre == el.tipo;
          });
        });*/
      }
    }
  }

  seleccionCategoria(todos: boolean, id?: string) {
    if (todos) {
      this.todos_filtros_desactivados = true;
      this.categoriasSeleccionadas = [];
    } else {
      if (this.categoriasSeleccionadas.includes(id)) {
        const index = this.categoriasSeleccionadas.findIndex((cat: any) => cat === id);
        this.categoriasSeleccionadas.splice(index, 1);
      } else {
        this.categoriasSeleccionadas.push(id);
      }
    }
  }

  filtrarCategoria() {
    const listaDistribuidores: any = [];
    if (this.categoriasSeleccionadas.length === 0) {
      this.distribuidores_componente_padre = this.buffer_distribuidores;
      return;
    }

    for (const datosDistribuidor of this.categoriasDistribuidor) {
      let categoria_existe = 0;
      for (const cat of datosDistribuidor.categoriasDistribuidor) {
        if (this.categoriasSeleccionadas.includes(cat._id)) {
          categoria_existe = 1;
          break;
        }
      }
      if (categoria_existe > 0) {
        const dataDistribuidor = datosDistribuidor.data_distribuidor[0];
        listaDistribuidores.push(dataDistribuidor._id);
      }
    }

    this.distribuidores_componente_padre = this.distribuidores_componente_padre.filter(
      (dist: any) =>
        listaDistribuidores.includes(dist.distribuidor?._id) ||
        listaDistribuidores.includes(dist.data_distribuidor?.[0]?._id) ||
        listaDistribuidores.includes(dist?._id)
    );
  }

  /**
   * Ajusta el filtrado de calificación de distribuidores
   * Si entra el ranking que ya estaba puesto, se va a 0
   * En caso contrario se asigna el ranking necesario
   * @param rank La nueva calificación a filtrar
   */
  setRanking(rank: number) {
    if (this.ranking == rank) {
      this.ranking = 0;
    } else {
      this.ranking = rank;
    }
  }

  /**
   * Este metodo tiene como objeto realizar el filtro por ranking
   */
  filtroRanking() {
    if (this.ranking != 0) {
      console.log('this raking filtro', this.ranking)
      if (this.router.url == '/distribuidores') {
        this.distribuidores_componente_padre = this.distribuidores_componente_padre.filter(
          (obj) => obj.ranking == this.ranking
        );
      } else if (this.router.url === '/saldos-promociones') {
        this.distribuidores_componente_padre = this.distribuidores_componente_padre.filter(
          (obj) => obj.data_distribuidor[0]?.ranking == this.ranking
        );
      } else {
        this.distribuidores_componente_padre = this.distribuidores_componente_padre.filter(
          (obj) => obj.distribuidor.ranking == this.ranking
        );
      }
    }
  }

  /**
   * Este metodo tiene como objeto realizar el filtro por tiempo de entrega
   */
  filtroTiempoEntrega() {
    if (this.tiempo_entrega != 'Selecciona') {
      if (this.router.url == '/distribuidores') {
        this.distribuidores_componente_padre = this.distribuidores_componente_padre.filter(
          (obj) => obj.tiempo_entrega == this.tiempo_entrega
        );
      } else if (this.router.url === '/saldos-promociones') {
        this.distribuidores_componente_padre = this.distribuidores_componente_padre.filter(
          (obj) => obj.data_distribuidor[0]?.tiempo_entrega == this.tiempo_entrega
        );
      } else {
        this.distribuidores_componente_padre = this.distribuidores_componente_padre.filter(
          (obj) => obj.distribuidor.tiempo_entrega == this.tiempo_entrega
        );
      }
    }
  }

  /**
   * Reinicia los filtros de este modal
   */
  limpiarFiltros() {
    this.ranking = 0;
    this.tiempo_entrega = 'Selecciona';
    this.todos_filtros_desactivados = true;
    this.handleClickCategoria(true);
  }

  /**
   * Devuelve la data al componente padre luego de aplicar los filtros
   */
  filtrar() {
    if (this.punto_entrega && Object.keys(this.punto_entrega)?.length > 0) {
      /**
       * El filtro de categoría se ejecuta durante el uso del modal
       * El ranking y tiempo de entrega solo hasta el final/callback
       */
      this.filtrarCategoria();
      this.filtroRanking();
      this.filtroTiempoEntrega();
      /** Se envia información al padre */
      this.callback(
        this.ranking,
        this.tiempo_entrega,
        this.todos_filtros_desactivados,
        this.categorias_estado,
        this.distribuidores_componente_padre
      );
    }
    this.activeModal.close();
  }
}
