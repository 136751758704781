<app-header-register></app-header-register>
<div class="container-fluid fondo" [formGroup]="contactoForm" *ngIf="registroAutomatico">
    
    <div class="d-flex justify-content-center mt-4">
      <div class="card-contact container" *ngIf="pasoRegistro ===1">
        <div class="row">
          <div class="col mt-4 text-center" *ngIf="registroAutomatico">
            <span class="aviso-contacto">La siguiente información fue registrada por el distribuidor: <b>{{registroAutomatico.distribuidor.nombre}}</b>  </span>
          </div>
        </div>
        <div class="row" >
            <div class="col-12" style="margin-top: 2rem;">
                <h6>Información establecimiento:</h6>
                <hr />
            </div>
        </div>
       
        <div class="row mt-3">
          <div class="col-12 col-md-12">
            *Nombre Establecimiento
            <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
              formControlName="nombre_estblecimiento" 
              />
            <span class="reactive-text text-danger"
              *ngIf="contactoForm.get('nombre_estblecimiento')?.hasError('required') && contactoForm.get('nombre_estblecimiento')?.touched">
              <span class="alerta"> El campo nombre del establecimiento es obligatorio</span>
            </span>
          </div>
        </div>
        <div class="row mt-3">
            <div class="col-12 col-md-12">
              *Razón Social
              <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
                formControlName="razon_social_establecimiento" />
              <span class="reactive-text text-danger"
                *ngIf="contactoForm.get('razon_social_establecimiento')?.hasError('required') && contactoForm.get('razon_social_establecimiento')?.touched">
                <span class="alerta"> El campo razón social es obligatorio</span>
            </span>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-md-6">
              *Tipo de negocio
              <select class="input-defecto mt-2 px-3" [(ngModel)]="tipoNegocioSelected" formControlName="tipo_negocio_establecimiento" >
                <option value="BAR / DISCOTECA">BAR / DISCOTECA</option>
                <option value="CAFETERÍA / HELADERÍA / SNACK">CAFETERÍA / HELADERÍA / SNACK</option>
                <option value="CATERING SERVICE / SERVICIO ALIMENTACIÓN">CATERING SERVICE / SERVICIO ALIMENTACIÓN</option>
                <option value="COCINA OCULTA">COCINA OCULTA</option>
                <option value="CENTRO DE DIVERSIÓN">CENTRO DE DIVERSIÓN</option>
                <option value="CENTRO DEPORTIVO Y GIMNASIOS">CENTRO DEPORTIVO Y GIMNASIOS</option>
                <option value="CLUB SOCIAL / NEGOCIOS">CLUB SOCIAL / NEGOCIOS</option>
                <option value="COMEDOR DE EMPLEADOS">COMEDOR DE EMPLEADOS</option>
                <option value="COMIDA RÁPIDA" >COMIDA RÁPIDA</option>
                <option value="HOGAR" ng-reflect-value="HOGAR">HOGAR</option>
                <option value="MAYORISTA / MINORISTA">MAYORISTA / MINORISTA</option>
                <option value="OFICINA / COWORKING">OFICINA / COWORKING</option>
                <option value="PANADERÍA / REPOSTERÍA">PANADERÍA / REPOSTERÍA</option>
                <option value="PROPIEDAD HORIZONTAL">PROPIEDAD HORIZONTAL</option>
                <option value="RESTAURANTE">RESTAURANTE</option>
                <option value="RESTAURANTE DE CADENA">RESTAURANTE DE CADENA</option>
                <option value="SECTOR EDUCACIÓN">SECTOR EDUCACIÓN</option>
                <option value="SECTOR HOTELERO">SECTOR HOTELERO</option>
                <option value="SECTOR SALUD">SECTOR SALUD</option>
                <option value="SECTOR PÚBLICO / PRIVADO">SECTOR PÚBLICO / PRIVADO</option>
                </select>





              <span class="reactive-text text-danger"
                *ngIf="contactoForm.get('tipo_negocio_establecimiento')?.hasError('required') && contactoForm.get('tipo_negocio_establecimiento')?.touched">
                <span class="alerta"> El campo tipo de negocio es obligatorio</span>
              </span>
            </div>
            <div class="col-12 col-md-6">
                *Tipo de persona

                <select class="input-defecto mt-2 px-3" formControlName="tipo_usuario"  [(ngModel)]="tipoDoc">
                    <option value="Natural">Natural</option>
                    <option value="Jurídica">Jurídica</option>
                </select>
                <span class="reactive-text text-danger"
               
                  *ngIf="contactoForm.get('tipo_usuario')?.hasError('required') && contactoForm.get('tipo_usuario')?.touched">
                  <span class="alerta"> El campo tipo de persona es obligatorio</span>
                </span>
              </div>
          </div>
          <div class="row mt-3">
            <div class="col-12 col-md-6">
              *Tipo de documento
                <select formcontrolname="tipo_documento" class="input-defecto mt-2 px-3"  *ngIf="tipoDoc=== 'Natural'">
                    <option value="Cédula de ciudadanía">Cédula de ciudadanía</option>
                    <option value="Cédula de extranjería">Cédula de extranjería</option>
                </select>
                <select formcontrolname="tipo_documento" class="input-defecto mt-2 px-3"  *ngIf="tipoDoc !== 'Natural'">
                    <option value="Nit">Nit</option>
                </select>
              <span class="reactive-text text-danger"
                *ngIf="contactoForm.get('tipo_documento')?.hasError('required') && contactoForm.get('tipo_documento')?.touched">
                <span class="alerta"> El campo tipo de documento es obligatorio</span>
            </span>
            </div>
            <div class="col-12 col-md-6">
                *Número de documento
                <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
                  formControlName="nit_establecimiento" />
                <span class="reactive-text text-danger"
                  *ngIf="contactoForm.get('nit_establecimiento')?.hasError('required') && contactoForm.get('nit_establecimiento')?.touched">
                  <span class="alerta"> El campo número de documento es obligatorio</span>
                </span>
              </div>
          </div>
        <div class="row mt-3">
          <div class="col-12 col-md-6">
            *Correo de facturación
            <input type="email" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
              formControlName="email_establecimiento" />
            <span class="reactive-text text-danger"
              *ngIf="contactoForm.get('email_establecimiento')?.hasError('required')  && contactoForm.get('email_establecimiento')?.touched">
              <span class="alerta"> El campo correo de facturación es obligatorio</span>
            </span>
            <span class="reactive-text text-danger"
              *ngIf="contactoForm.get('email_establecimiento')?.hasError('email')  && contactoForm.get('email_establecimiento')?.touched">
              <span class="alerta">Ingresa un email valido</span>
            </span>
          </div>
          <div class="col-12 col-md-6 mt-3 mt-md-0">
            *Teléfono movil de contacto
            <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aquí"
                   formControlName="celular_establecimiento" />
            <span class="reactive-text text-danger"
                  *ngIf="contactoForm.get('celular_establecimiento')?.hasError('required')">
              El campo teléfono movil es obligatorio.
            </span>
            <span class="reactive-text text-danger"
                  *ngIf="contactoForm.get('celular_establecimiento')?.hasError('pattern')">
              Ingrese un número de teléfono movil de 10 dígitos.
            </span>
          </div>
        </div>
        <div class="row mb-4" style="margin-top: 2rem;">
          <div class="col-12  text-center mt-2">
            <button class="btn-enviar" 
            (click)="verifiCarFrm('contactoForm')" [disabled]="!contactoForm.valid"
            [style.background-color]="contactoForm.invalid ? 'grey' : ''"
            >Siguiente</button>
          </div>
        </div>
      </div>
      <div class="card-contact container punto-entrega" *ngIf="pasoRegistro ===2">
      
        <div class="row" >
            <div class="col-12" style="margin-top: 2rem;">
                <button class="boton-volver" (click)="pasoRegistro = 1">
                    <fa-icon [icon]="faChevronLeft"></fa-icon>
                  </button> Información Punto:
                <hr />
            </div>
        </div>
        <div id="main-container" class="p-5" style="padding: 0 !important;">
            
            <div class="container" *ngIf="punto_entrega != undefined">
              <div [formGroup]="puntoForm">

                <div class="row mt-3">
                    <div class="col-12 col-md-12">
                      *Nombre del punto de entrega
                      <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
                        formControlName="puntoNombreEstablecimiento" />
                      <span class="reactive-text text-danger"
                        *ngIf="puntoForm.get('puntoNombreEstablecimiento')?.hasError('required') && puntoForm.get('puntoNombreEstablecimiento')?.touched">
                        <span class="alerta">El campo nombre del punto de entrega es obligatorio</span>
                    </span>
                    </div>
                  </div>
                 
                  <div class="row mt-3">
                    <div class="col-12 col-md-12">
                        <span *ngIf="tipoNegocioSelected !== 'SECTOR HOTELERO' "> *Número de sillas (Tamaño del negocio)</span>
                        <span *ngIf="tipoNegocioSelected === 'SECTOR HOTELERO'">*Número de habitaciones</span>

                   
                      <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
                        formControlName="puntoSillas" />
                        <span class="reactive-text text-danger"
                        *ngIf="puntoForm.get('puntoSillas')?.hasError('required')">
                    El campo del sillas es obligatorio.
                  </span>
                  <span class="reactive-text text-danger"
                        *ngIf="puntoForm.get('puntoSillas')?.hasError('pattern')">
                    Ingrese un número válido.
                  </span>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 col-md-12">
                      *¿Tiene servicio a domicilio?
                        <select formControlName="puntoDomicilio" name="select"  class="input-defecto mt-2 px-3"
                        >
                        <option [ngValue]=true>Sí ofrece domicilios</option>
                        <option [ngValue]=false>No ofrece domicilios</option>
                      </select>

                      <span class="reactive-text text-danger"
                        *ngIf="puntoForm.get('puntoSillas')?.hasError('required') && puntoForm.get('puntoSillas')?.touched">
                        <span class="alerta"> El campo es obligatorio</span>
                    </span>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 col-md-12">
                      *País
                      <select name="pais" id="" class="input-defecto mt-2 px-3" formControlName="puntoPais"
                      [ngClass]="{'invalid-warning': (puntoForm.get('puntoPais')?.hasError('required') && puntoForm.get('puntoPais')?.touched)}">
                      <option value="Colombia" selected>Colombia</option>
                    </select>
                      <span class="reactive-text text-danger"
                        *ngIf="puntoForm.get('pais')?.hasError('required') && puntoForm.get('pais')?.touched">
                        <span class="alerta"> El campo país es obligatorio</span>
                    </span>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 col-md-12">
                      *Departamento
                        <select name="departamento" id="" formControlName="puntoDepartamento" (change)="getPlaces()"
                        class="input-defecto mt-2 px-3">
                        <option *ngFor="let dpto of departamentos; index as i;" [ngValue]="dpto.name">
                          {{ dpto.name }}
                        </option>
                      </select>
                      <span class="reactive-text text-danger"
                        *ngIf="puntoForm.get('puntoDepartamento')?.hasError('required') && puntoForm.get('puntoDepartamento')?.touched">
                        <span class="alerta"> El campo departamento es obligatorio</span>
                    </span>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12 col-md-12">
                      *Ciudad

                        <select formControlName="puntoCiudad" name="select" (change)="buscarDireccion(true)"
                        class="input-defecto mt-2 px-3">
                        <option *ngFor="let city of ciudades; index as i;" value={{city.name}}>
                          {{ city.name }}
                        </option>
                      </select>
                      <span class="reactive-text text-danger"
                        *ngIf="puntoForm.get('puntoCiudad')?.hasError('required') && puntoForm.get('puntoCiudad')?.touched">
                        <span class="alerta"> El campo ciudad es obligatorio</span>
                    </span>
                    </div>
                  </div>
                  
                  <div class="row mt-3">
                    <div class="col-12 col-md-12">
                      *Dirección
                      <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
                      #editAddress
                      (keyup.enter)="enterMap()"
                      (input)="getCoordAutocomplete()"
                        (blur)="getCoordAutocomplete()"
                        formControlName="puntoDireccion"/>
                      <span class="reactive-text text-danger"
                        *ngIf="puntoForm.get('puntoDireccion')?.hasError('required') && puntoForm.get('puntoDireccion')?.touched">
                        <span class="alerta"> El campo dirección es obligatorio</span>
                    </span>
                    </div>
                  </div>

               
                <h6><span class="asterisco">*</span>Confirma el punto en el mapa</h6>
               <div class="map">
                  <agm-map
                    #AgmMap
                    fit="true"
                    [latitude]="map.lat"
                    [longitude]="map.lng"
                    style="width: 100%; height: 15rem;"
                    (mapReady)="mapReadyHandler($event)">
                    <agm-marker [latitude]="map.lat" [longitude]="map.lng"></agm-marker>
                  </agm-map>
                </div>
                <div class="row mt-3">
                
                  <div class="col-12 col-md-12 mt-3 mt-md-0">
                      *Teléfono movil de contacto del punto
                      <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aquí"
                             formControlName="puntoTelefono" />
                      <span class="reactive-text text-danger"
                            *ngIf="puntoForm.get('puntoTelefono')?.hasError('required')">
                        El campo del celular es obligatorio.
                      </span>
                      <span class="reactive-text text-danger"
                            *ngIf="puntoForm.get('puntoTelefono')?.hasError('pattern')">
                        Ingrese un número de celular válido de 10 dígitos.
                      </span>
                    </div>


                </div>
                <br />
                <h6>Horarios de atención para entregas</h6>
                <!-- Días Atención -->
                <h6><span class="asterisco">*</span>Días de atención</h6>
                <span class="w-100 pl-3 text-danger fw-bold text-right"
                  *ngIf="validator_dia_seleccionado == false && puntoForm.get('puntoHorarios')?.touched">
                  Seleccione al menos un día
                </span>
                <div class="checkBoxes">
                    <mat-checkbox *ngFor="let item of dias; let i = index" [checked]="dias_atencion[i]" formControlName={{item}}
                    style="padding-left: 1rem !important;"
                                      (change)="getCheckbox(i)"
                                      class="my-checkbox">
                                      {{item}}
                                    </mat-checkbox>
                  </div>
                <!-- Horario -->
                <div class="row row-slider">
                  <div class="col-12">
                    <h6><span class="asterisco">*</span>Indica el horario</h6>
                  </div>
                  <div class="col-12 custom-slider">
                    <ngx-slider [(value)]="minValue" [(highValue)]="maxValue" [options]="options"></ngx-slider>
                  </div>
                  <div class="col-12">
                    <p>
                      {{ valueToTime(minValue) }} - {{ valueToTime(maxValue) }}
                    </p>
                  </div>
                </div>
                <br />
                <div class="row mb-4" style="margin-top: 2rem;">
                    <div class="col-12  text-center mt-2">
                      <button class="btn-enviar" 
                      (click)="guardarCambios()" [disabled]="!puntoForm.valid"
                      [style.background-color]="puntoForm.invalid ? 'grey' : ''"
                      >Siguiente</button>
                    </div>
                  </div>
              </div>            
            </div>
          </div>
      </div>
      <div class="card-contact container" *ngIf="pasoRegistro ===3">

        <div [formGroup]="usuarioForm">
          <div class="row">
            <div class="col text-left mt-5">
              <h4 class="tituloBienvenido">¡Bienvenido a Feat!</h4>
              <span  class="tituloInfo" style="font-weight: 100;font-size: 20px;">Completa tu registro como establecimiento.</span>
            </div>
          </div>
            <div class="row" >
                <div class="col-12 tituloInfo">
                  Diligencia tu información personal
                </div>
            </div>
            <!-- validar nombre --->
            <div class="row mt-3">
              <div class="col-12 col-md-6">
               <span class="labelInput"> Nombre(s)* </span>
                <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
                  formControlName="nombre_usuario" />
                <span class="reactive-text text-danger"
                  *ngIf="usuarioForm.get('nombre_usuario')?.hasError('required') && usuarioForm.get('nombre_usuario')?.touched">
                  <span class="alerta">Campo requerido</span>
                </span>
              </div>
              <div class="col-12 col-md-6 respons">
                Apellido(s)*
                <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
                  formControlName="apellido_usuario" />
                <span class="reactive-text text-danger"
                  *ngIf="usuarioForm.get('apellido_usuario')?.hasError('required') && usuarioForm.get('apellido_usuario')?.touched">
                  <span class="alerta">Campo requerido</span>
                </span>
              </div>
            </div>
           
            <div class="row mt-3">
              <div class="col-12 col-md-6">
                  Número de contacto*
                  <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
                    formControlName="celular_usuario" />
                  <span class="reactive-text text-danger"
                    *ngIf="usuarioForm.get('celular_usuario')?.hasError('required') && usuarioForm.get('celular_usuario')?.touched">
                    <span class="alerta">Campo requerido</span>
                  </span>
                  <span class="reactive-text text-danger"
                    *ngIf="usuarioForm.get('celular_usuario')?.hasError('pattern') && usuarioForm.get('celular_usuario')?.touched">
                    <span class="alerta">Ingresa un número válido</span>
                  </span>
                </div>
            </div>
            <div class="row" >
              <div class="col-12 tituloInfo">
                Credenciales de acceso
              </div>
          </div>
                <div class="row mt-3">
                <div class="col-12 col-md-6">
                    Correo de contacto*
                    <input type="text" class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
                    formControlName="email_usuario" />
                    <span class="reactive-text text-danger"
                    *ngIf="usuarioForm.get('email_usuario')?.hasError('required') && usuarioForm.get('email_usuario')?.touched">
                    <span class="alerta">Campo requerido</span>
                    </span>
                    <span class="reactive-text text-danger "
                    *ngIf="usuarioForm.get('email_usuario')?.hasError('email') && usuarioForm.get('email_usuario')?.touched">
                    <span class="alerta">Ingresa un email válido</span>
                    </span>
                </div>
                <div class="col-12 col-md-6 respons">
                  <label for="passwordInput">Contraseña*</label>
              
                  <!-- Wrapper del input e ícono para que no se altere con los mensajes -->
                  <div class="input-wrapper position-relative">
                      <input id="passwordInput" [type]="showPassword ? 'text' : 'password'" 
                             class="input-defecto mt-2 px-3" placeholder="Escribe aquí"
                             formControlName="password" />
                             
                      <!-- Ícono del ojo fijo dentro del input -->
                      <span class="password-toggle" (click)="togglePassword()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" *ngIf="!showPassword">
                          <path d="M15.0005 12C15.0005 13.6569 13.6574 15 12.0005 15C10.3437 15 9.00049 13.6569 9.00049 12C9.00049 10.3431 10.3437 9 12.0005 9C13.6574 9 15.0005 10.3431 15.0005 12Z" stroke="#41403E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z" stroke="#41403E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>

                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" *ngIf="showPassword">
                            <path d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5" stroke="#41403E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                      </span>
                  </div>
              
                  <!-- Mensajes de validación para el input -->
                  <div class="reactive-text text-danger" 
                       *ngIf="usuarioForm.get('password')?.hasError('required') && usuarioForm.get('password')?.touched">
                      <span class="alerta">Campo requerido</span>
                  </div>
              
                  <div class="reactive-text text-danger" 
                       *ngIf="usuarioForm.get('password')?.hasError('pattern') && usuarioForm.get('password')?.touched">
                      <span class="alerta">Tu contraseña debe tener un mínimo de 6 caracteres y combinar letras y números.</span>
                  </div>
              </div>
                </div>
              <div class="row mt-3">
                <div class="col-12 col-md-6">
                  Confirmar contraseña*
                

                    <div class="input-wrapper position-relative">
                      <input  [type]="showPasswordReset ? 'text' : 'password'"  class="input-defecto mt-2 px-3" placeholder="Escribe aqui"
                      formControlName="repeat_password" />
                             
                      <!-- Ícono del ojo fijo dentro del input -->
                      <span class="password-toggle" (click)="togglePasswordReset()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" *ngIf="!showPasswordReset">
                          <path d="M15.0005 12C15.0005 13.6569 13.6574 15 12.0005 15C10.3437 15 9.00049 13.6569 9.00049 12C9.00049 10.3431 10.3437 9 12.0005 9C13.6574 9 15.0005 10.3431 15.0005 12Z" stroke="#41403E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M12.0012 5C7.52354 5 3.73326 7.94288 2.45898 12C3.73324 16.0571 7.52354 19 12.0012 19C16.4788 19 20.2691 16.0571 21.5434 12C20.2691 7.94291 16.4788 5 12.0012 5Z" stroke="#41403E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                          </svg>

                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" *ngIf="showPasswordReset">
                            <path d="M2.99902 3L20.999 21M9.8433 9.91364C9.32066 10.4536 8.99902 11.1892 8.99902 12C8.99902 13.6569 10.3422 15 11.999 15C12.8215 15 13.5667 14.669 14.1086 14.133M6.49902 6.64715C4.59972 7.90034 3.15305 9.78394 2.45703 12C3.73128 16.0571 7.52159 19 11.9992 19C13.9881 19 15.8414 18.4194 17.3988 17.4184M10.999 5.04939C11.328 5.01673 11.6617 5 11.9992 5C16.4769 5 20.2672 7.94291 21.5414 12C21.2607 12.894 20.8577 13.7338 20.3522 14.5" stroke="#41403E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                      </span>
                  </div>



                  <span class="reactive-text text-danger"
                    *ngIf="usuarioForm.get('repeat_password')?.hasError('required') && usuarioForm.get('repeat_password')?.touched">
                    <span class="alerta">Campo requerido</span>
                  </span>
                  <span class="reactive-text text-danger"
                  *ngIf="usuarioForm.get('repeat_password')?.touched && usuarioForm.get('repeat_password')?.value !== usuarioForm.get('password')?.value">
                  <span class="alerta">Las contraseñas no son iguales. Intenta nuevamente</span>
                </span>
                </div>
              </div>

              <div class="mt-3">
                <div class="row px-3">
                  <div class="pl-2 col-11 d-flex align-content-center">
                    <input type="checkbox" formControlName="contactoTerminos" />
                    <span class="paddingL">
                      He leído y acepto los 
                      <a class=" cursorPointer"
                    > <span class="link-terminos" (click)="verDocumento()">Términos y Condiciones</span> <span class="textDec"> y la </span><span class="link-terminos" (click)="verDocumento()">Política de Privacidad.</span></a>
                    </span>
                  </div>
                  
                </div>
              </div>
              <!--<div class="mt-3">
                <div class="row px-3">
                  
                  <div class="pl-2 col-11 d-flex  align-content-center">
                    <input type="checkbox" formControlName="contactoPolitica" />
                    <span class="paddingL">
                      Acepto las <a class="link-terminos cursorPointer"
                    (click)="verDocumento()"> Políticas de
                    Privacidad</a>
                    </span>
                  </div>
                  <span class="col-12 text-danger fw-bold text-left p-0"
                    *ngIf="usuarioForm.get('contactoPolitica')?.hasError('required') && usuarioForm.get('contactoPolitica')?.touched">
                    <span class="alerta">Debes aceptar las políticas de privacidad</span>
                  </span>
                </div>
              </div>-->

              <div class="row mb-4" style="margin-top: 2rem;">
                <div class="col-12  text-center mt-2" 
                *ngIf="usuarioForm.get('repeat_password')?.value !== usuarioForm.get('password')?.value"
                >
                  <button class="btn-enviar" 
                  disabled="true"
                  [style.background-color]="usuarioForm.get('repeat_password')?.value !== usuarioForm.get('password')?.value ? 'grey' : ''"
                  >Continuar
                  <div *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </button>
                </div>
                <div class="col-12  text-center mt-2"
                *ngIf="usuarioForm.get('repeat_password')?.value === usuarioForm.get('password')?.value"
                >
                  <button class="btn-enviar" 
                  (click)="procesarRegistro()" [disabled]="isLoading || !usuarioForm.valid"
                  [style.background-color]="usuarioForm.invalid ? 'grey' : ''"
                  >Continuar
                  <div *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status">
                    <span class="visually-hidden"></span>
                  </div>
                </button>
                </div>
              </div>
        </div>
        
      </div>
    </div>
  </div>