import { Component, OnDestroy, OnInit } from '@angular/core';
import { faSearch, faTimesCircle, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Categoria } from 'src/app/models/categoria.model';
import { LineaProducto } from 'src/app/models/linea_producto.model';
import { Producto } from 'src/app/models/producto.model';
import { RestService } from 'src/app/services/rest/rest.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CarritoDistService } from 'src/app/services/carrito-dist/carrito-dist.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { UsuarioHoreca } from 'src/app/models/usuario-horeca.model';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-pedidos-sugeridos',
  templateUrl: './pedidos-sugeridos.component.html',
  styleUrls: ['./pedidos-sugeridos.component.css'],
})
export class PedidosSugeridosComponent implements OnInit, OnDestroy {
  // Datos para los selectores de clientes y puntos de entrega
  public clientes: UsuarioHoreca[] = [];
  public puntos_entrega: PuntoEntrega[] = [];
  public todos_puntos_entrega: PuntoEntrega[] = [];
  //Imagen producto placeholder
  public product_placeholder = '../../assets/img/product-placeholder.png';
  public distribuidor_placeholder = '../../assets/img/icon-organizacion.png';
  // Flag para activar el componente de los pedidos sugeridos
  pedidosSugeridos = false;
  // Iconos
  public faTrash = faTrash;
  public faSearch = faSearch;
  public faTimesCircle = faTimesCircle;
  public faTimes = faTimes;
  // Productos del distribuidor (los arreglos de precios y fotos son necesarios por un problema de posible nulidad en typescript)
  public productos: Producto[] = [];
  public productos_saldos_promos: any;
  public precios: any[] = [];
  // fotos: any[] = [];
  // organizaciones: string[] = [];
  public unidades_saldo_promo: any[] = [];
  public unidades: number[] = [];
  public cajas: number[] = [];
  // Listas de categorias y lineas de productos
  public categorias: Categoria[] = [];
  public lineas: LineaProducto[] = [];
  public lineas_select: boolean[] = [];
  // Datos filtrados según categorias y lineas de productos
  public unidades_filtradas: number[] = [];
  public cajas_filtradas: number[] = [];
  // Modal de carga de informacion de cliente
  modalCarga?: NgbModalRef;
  //Guardamos dinamicamente los valores de autocompletado
  public filteredOptions: any;
  public mySearch = new FormControl();
  public searchTerm = '';
  // Variables de filtrado
  public lineas_productos_estado: boolean[] = [];
  public categorias_filtro_distribuidores: any[] = [];
  // Guarda los distribuidores sin filtrar
  public buffer_productos: any = [];
  // Nombre a buscar entre los productos y sus resultados
  public prods_filtrados: any[] = [];
  // filtro busqueda
  public flag_borar_filtro_busqueda = false;
  // Funcionalidad de filtros por categoría y líneas
  public buffer_categorias_productos: any[] = [];
  public categorias_productos: any[] = [];
  public categorias_productos_seleccionadas: any[] = [];
  public categorias_productos_estados: any[] = [];
  public categorias_productos_estados_todos = true;
  public buffer_lineas_productos: any[] = [];
  public lineas_productos: any[] = [];
  public lineas_productos_estado_todos = true;
  public lineas_productos_actuales_distribuidor: any[] = [];
  // Catalogo/Saldos promociones
  public pestana_saldos_promociones = false;
  stBusq: any;
  unidadesAux: any = {};
  productosAux: any = {};
  cajasAux: any = {};

  constructor(
    private rest: RestService,
    private auth: AuthService,
    public carritoDist: CarritoDistService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.traerClientesYPuntos();
    this.traerProductos();
    this.traerCodigos();
  }
  ngOnDestroy() {
    this.carritoDist.limpiarCarritoAlternativo();
  }
  ngDoCheck(): void {
    if (this.stBusq && this.stBusq.length > 2) {
      this.gen(this.stBusq);
    }
  }
  filterByValue(array: any, string: any) {
    return array.filter(
      (o: any) =>
        o.nombre.toLowerCase().includes(string.toLowerCase()) ||
        o.codigo_distribuidor_producto.toLowerCase().includes(string.toLowerCase()) ||
        o.codigo_organizacion_producto.toLowerCase().includes(string.toLowerCase())
    );
  }
  gen(st: any) {
    this.prods_filtrados = this.filterByValue(this.buffer_productos, st);
  }
  /******************** Recupera los productos del catalogo  ******************/
  /**
   * Consulta al back los productos asociados a este distribuidor
   */
  async traerProductos() {
    try {
      const id_dist = this.auth.user_distribuidor?._id;
      const resp_prods_x_dist: any = await this.rest
        .getJWT(`productos_por_distribuidor/distribuidor/${id_dist}`)
        .toPromise();
      if (resp_prods_x_dist && resp_prods_x_dist.productos) {
        this.prods_filtrados = resp_prods_x_dist.productos.filter((element: any) => {
          return element.estadoActualizacion === 'Aceptado' && element.saldos === false && element.promocion === false;
        });
        this.buffer_productos = this.prods_filtrados;
        for (const aux of this.prods_filtrados) {
          if (!aux.codigo_organizacion_producto) {
            aux.codigo_organizacion_producto = '';
          }
          if (!aux.codigo_distribuidor_producto) {
            aux.codigo_distribuidor_producto = '';
          }
          this.productos.push(aux);
          this.cajasAux[aux._id] = 0;
          this.unidadesAux[aux._id] = 0;
          this.precios.push(aux.precios || []);
          this.unidades.push(0);
          this.unidades_filtradas.push(0);
          this.cajas.push(0);
          this.cajas_filtradas.push(0);
        }
        this.autoCompletadoBusqueda();
        this.fetchCategoriasYLineas();
      }
      const resp_prods_saldos: any = await this.rest
        .getJWT(`saldos_promos_por_distribuidor/distribuidor/${id_dist}`)
        .toPromise();
      const listaSaldosPromociones = resp_prods_saldos?.data?.productos;
      this.productos_saldos_promos = listaSaldosPromociones.filter(
        (prod: any) => prod.estadoActualizacion === 'Aceptado'
      );
      for (let index = 0; index < this.productos_saldos_promos.length; index++) {
        this.unidades_saldo_promo[index] = 0;
      }
    } catch (err) {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
      };
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible recuperar la información de los productos. Por favor intenta de nuevo más tarde.';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
        window.location.reload();
      };
    }
  }

  /**
   * Consulta los códigos de descuento generados para este distribuidor,
   * y cuanto vale 1 punto Ft
   */
  async traerCodigos() {
    try {
      const resp_params: any = await this.rest.getJWT('parametrizacion').toPromise();
      this.carritoDist.valor_1pt = resp_params[0].valor_1puntoft;
    } catch (err) {
      const ngbModalOptions: NgbModalOptions = {
        //Evita que al hacer click por fuera se cierre el modal
        backdrop: 'static',
        keyboard: false,
        centered: true,
      };
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'Ocurrió un error recuperando la información necesaria. Intenta de nuevo más tarde.';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
        window.location.reload();
      };
    }
  }

  /******************** Seleccionar horeca y punto entrega  *******************/
  /**
   * Trae la información de los clientes y puntos de entrega
   * que se encuentran vinculados a este distribuidor
   */
  async traerClientesYPuntos() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    try {
      const id_dist: string = this.auth.user_distribuidor?._id || '';
      const tipoTrabajador: string = this.auth.user?.tipo_trabajador || '';
      let resp_aprobados: any;
      if (tipoTrabajador == 'VENDEDOR') {
        resp_aprobados = await this.rest
          .getJWT(`distribuidores_vinculados_clientes_aprobados/${id_dist}/vendedor/${this.auth.user?._id}`)
          .toPromise();
      } else {
        resp_aprobados = await this.rest.getJWT(`distribuidores_vinculados_clientes_aprobados/${id_dist}`).toPromise();
      }
      //Poblar arreglo de todos los puntos de entrega vinculados
      const ids_clientes: string[] = [];
      for (const aprobados_aux of resp_aprobados) {
        if(aprobados_aux.punto_entrega){
          this.todos_puntos_entrega.push(aprobados_aux.punto_entrega);
          if (!ids_clientes.includes(aprobados_aux.punto_entrega.usuario_horeca)) {
            ids_clientes.push(aprobados_aux.punto_entrega.usuario_horeca);
          }
        }
       
      }
      //Recuperar info de todos los clientes correspondientes a los puntos de entrega vinculados
      let cliente_aux_info: any;
      let cliente_aux: UsuarioHoreca;
      for (const id_aux of ids_clientes) {
        cliente_aux_info = await this.rest.getJWT(`usuario_horeca/${id_aux}`).toPromise();
        if (cliente_aux_info) {
          cliente_aux = new UsuarioHoreca(
            cliente_aux_info.nombres,
            cliente_aux_info.apellidos,
            cliente_aux_info.correo,
            cliente_aux_info.pais,
            cliente_aux_info.departamento,
            cliente_aux_info.ciudad,
            cliente_aux_info.tipo_documento,
            cliente_aux_info.numero_documento,
            cliente_aux_info.clave,
            cliente_aux_info.telefono,
            cliente_aux_info.celular,
            cliente_aux_info.tipo_aprobador,
            cliente_aux_info.nombre_establecimiento,
            cliente_aux_info.empresa_pais,
            cliente_aux_info.empresa_departamento,
            cliente_aux_info.empresa_ciudad,
            cliente_aux_info.empresa_telefono,
            cliente_aux_info.empresa_telefono2,
            cliente_aux_info.propietario_tipo_documento,
            cliente_aux_info.propietario_numero_documento,
            cliente_aux_info.propietario_nombres,
            cliente_aux_info.propietario_apellidos,
            cliente_aux_info.propietario_telefono,
            cliente_aux_info.propietario_correo,
            cliente_aux_info.logo,
            cliente_aux_info.solicitud_vinculacion,
            cliente_aux_info.rol,
            cliente_aux_info.razon_social,
            cliente_aux_info.nit,
            cliente_aux_info.tipo_usuario,
            cliente_aux_info.tipo_negocio,
            cliente_aux_info.estado,
            cliente_aux_info._id
          );
          this.clientes.push(cliente_aux);
        }
      }
      this.modalCarga.close();
    } catch (err) {
      this.modalCarga?.close();
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'Ocurrió un problema al traer la información de los clientes. Intenta más tarde.';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }

  /**
   * Actualiza el arreglo de puntos de entrega para que se vean solo los
   * que corresponden a este cliente
   */
  selectCliente() {
    this.puntos_entrega = [];
    for (const punto_aux of this.todos_puntos_entrega) {
      if (punto_aux.usuario_horeca == this.carritoDist.cliente?._id) {
        this.puntos_entrega.push(punto_aux);
      }
    }
    this.carritoDist.punto_entrega = this.puntos_entrega[0];
  }

  /**
   * Actualiza el arreglo de códigos de descuento para que cuadren con
   * el punto de entrega seleccionado
   */
  selectPuntoEntrega() {
    this.carritoDist.calcularPrecios();
  }

  /************************** Busqueda autocompletado *************************/
  /**
   * Este metodo tiene como objetoautocompletar la busqueda del usuario
   */
  autoCompletadoBusqueda() {
    this.filteredOptions = this.mySearch.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.nombre)),
      map((nombre) => (nombre ? this._filter(nombre) : this.prods_filtrados.slice()))
    );
  }
  displayFn(user: any) {
    return user ? user.nombre : undefined;
  }
  returnFn(user: any) {
    return user ? user.value : undefined;
  }
  itemDisplayFn(item: any) {
    return item ? item.name : '';
  }
  private _filter(nombre: string) {
    const filterValue = this.normalizeString(nombre.toLowerCase());
    return this.prods_filtrados.filter((option: any) =>
      this.normalizeString(option.nombre).toLowerCase().includes(filterValue)
    );
  }
  clearSearch() {
    this.searchTerm = '';
  }

  /**
   * Estos metodos tienen como objeto ver el detalle de los distribuidores seleccionado en el buscador
   */
  filtroProducto(event: any) {
    this.flag_borar_filtro_busqueda = true;
    const filtrado = event.option.value._id;
    const prod = this.prods_filtrados.find((prod: any) => prod._id == filtrado);
    const cat = this.buffer_categorias_productos.find((cat: any) => cat._id == prod.categoria_producto);
    const linea = this.buffer_lineas_productos.find((linea: any) => linea._id == prod.linea_producto[0]);
    this.prods_filtrados = [prod];
    this.categorias_productos = [cat];
    this.lineas_productos = [linea];
  }

  /**
   * Borra el filtro de busqueda de un producto
   */
  borrarFiltroProducto() {
    this.flag_borar_filtro_busqueda = false;
    this.prods_filtrados = this.buffer_productos;
    this.categorias_productos = this.buffer_productos;
    this.lineas_productos = this.buffer_productos;
    this.autoCompletadoBusqueda();
  }

  /**
   * Toma el string que entra por parámetro y cambia tildes y diéresis
   * por las letras sin acento, y lo pasa a minúsculas
   * @param pWord El string a filtrar
   * @returns El string filtrado
   */
  normalizeString(pWord: string): string {
    return pWord
      .trim()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase();
  }

  /******************** Filtros por categoría y linea producto *******************/
  /**
   * Recupera las categorías y lineas de productos activas del distribuidor
   */
  async fetchCategoriasYLineas() {
    /********************* Categorías ********************/
    const resp: any = await this.rest
      .getJWT(`categorias_producto_distribuidor/distribuidor/${this.auth.user_distribuidor?._id}`)
      .toPromise();
    // Elimina los objetos repetidos
    this.categorias_productos = resp.filter((value: any, index: any) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        resp.findIndex((obj: any) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    // Guarda un buffer para la funcionalidad de filtros
    this.buffer_categorias_productos = this.categorias_productos;
    /**
     * Crea un array con el estado de las categorias de productos.
     * True (filtro activo) o False (filtro NO activo) Todas inician en false
     */
    this.categorias_productos.forEach(() => {
      this.categorias_productos_estados.push(false);
    });
    /********************* Líneas ********************/
    const resp_lineas: any = await this.rest
      .getJWT(`lineas_producto_distribuidor/distribuidor/${this.auth.user_distribuidor?._id}`)
      .toPromise();
    /** Elimina los objetos repetidos */
    this.lineas_productos = resp_lineas.filter((value: any, index: any) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        resp_lineas.findIndex((obj: any) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    // Guarda un buffer para la funcionalidad de filtros
    this.buffer_lineas_productos = this.lineas_productos;
    // Recupera las lineas de productos
    this.handleLineasProductos();
  }

  /**
   * Este metodo tiene como objeto organizar los filtros por categorías
   * @param click_filtro_todos de darse click en el filtro Todos, reinicia todas las categorías
   */
  handleClickCategoria(click_filtro_todos: boolean) {
    // Si hay un filtro de la barra de busqueda lo quita
    this.flag_borar_filtro_busqueda = false;
    this.autoCompletadoBusqueda();
    // Carga todas las categorías disponibles y deselecciona todas las categorias
    this.categorias_productos = this.buffer_categorias_productos;
    this.categorias_productos_seleccionadas = [];
    /**
     * Verifica Si está seleccionada la categoría dentro de los filtros aplicados
     * si lo está, la agrega al array de seleccionados, si no, no la toma en cuenta
     */
    for (let index = 0; index < this.categorias_productos_estados.length; index++) {
      if (this.categorias_productos_estados[index] === true) {
        this.categorias_productos_seleccionadas.push(this.categorias_productos[index]);
      }
    }
    /*************** Verifica si existen filtros aplicados ***************/
    if (this.categorias_productos_seleccionadas.length == 0 || click_filtro_todos == true) {
      /********************* NO hay filtros aplicados ********************/
      // Garantiza todas las categorias deseleccionadas y "Todos" activ
      this.categorias_productos_estados_todos = true;
      for (let index = 0; index < this.categorias_productos_estados.length; index++) {
        this.categorias_productos_estados[index] = false;
      }
      /** Todas las categorias disbonibles y seleccionadas */
      this.categorias_productos_seleccionadas = this.categorias_productos;
    } else {
      /********************* SI hay filtros aplicados ********************/
      this.categorias_productos_estados_todos = false;
    }
    /****** Organiza nuevamente las lineas de productos disponibles ******/
    /**
     * Crea un array con el estado de la selección de filtros de lineas
     * de productos. Todas inician en false, es decir, se mostrarán todas
     * el estado es true (filtro activo) o false (filtro NO activo)
     */
    for (let index = 0; index < this.lineas_productos_estado.length; index++) {
      this.lineas_productos_estado[index] = false;
    }
    this.handleLineasProductos();
  }

  /**
   * Organiza las lineas de productos de las categorías
   * Esta función reorganiza la lineas de productos cada vez que se cambian las categorías
   * Solo se mostrarán las linea de las categorías seleccionadas
   */
  handleLineasProductos() {
    this.lineas_productos = [];
    // Si no hay categorías seleccionadas, las muestra todas
    if (this.categorias_productos_seleccionadas.length == 0) {
      this.categorias_productos_seleccionadas = this.categorias_productos;
    }
    // Crea un array con todas las lineas de productos disponibles de las categorias seleccionadas
    const lineas_productos_disponibles_por_categoria: any[] = [];
    for (const categoria of this.categorias_productos_seleccionadas) {
      categoria.lineas_producto.forEach((element: any) => {
        lineas_productos_disponibles_por_categoria.push(element);
      });
    }
    /**
     * Filtra dejando solo las lineas de productos que SI están disponibles en el distribuidor.
     * Hay mas de 100, por lo que se realiza este filtro para mejorar la experiencia del usuario.
     * De las lineas disponbiles del distribuidor, se filtran según las las categorías seleccionadas.
     */
    this.lineas_productos = this.buffer_lineas_productos.filter((el: any) => {
      return lineas_productos_disponibles_por_categoria.some((f: any) => {
        return f == el._id;
      });
    });
    /**
     * Crea un array con el estado de la selección de filtros de lineas de productos.
     * Todas inician en false. Opciones: True (filtro activdo) o False (filtro NO activo).
     */
    this.lineas_productos.forEach(() => {
      this.lineas_productos_estado.push(false);
    });
    // Ya establecido los filtros se muestran los productos según aplique
    this.handleClickLineaProducto(false);
  }

  /**
   * Este metodo tiene como objeto filtrar los productos por linea de producto
   * @param click_filtro_todos de darse click en el filtro Todos, habilita todas
   * las lineas de producto disponibles y muestra toda la data respectiva
   */
  handleClickLineaProducto(click_filtro_todos: boolean) {
    // Si hay un filtro de la barra de busqueda lo quita
    this.flag_borar_filtro_busqueda = false;
    this.autoCompletadoBusqueda();
    // Reinicia el array de productos sin filtro
    this.prods_filtrados = this.buffer_productos;
    // Crea un array con las categorías de filtro seleccionadas
    let lineas_productos_seleccionadas: any = [];
    for (let index = 0; index < this.lineas_productos_estado.length; index++) {
      if (this.lineas_productos_estado[index] === true) {
        lineas_productos_seleccionadas.push(this.lineas_productos[index]);
      }
    }
    /*********** Verifica si existen filtros aplicados **********/
    if (lineas_productos_seleccionadas.length === 0 || click_filtro_todos === true) {
      /**************** NO hay filtros aplicados ***************/
      // Reinicia botones y habilita todas las lineas para mostrar todos los productos
      this.lineas_productos_estado_todos = true;
      this.lineas_productos_estado = this.lineas_productos_estado.map((element: boolean) => (element = false));
      lineas_productos_seleccionadas = this.lineas_productos;
    } else {
      /***************** SI hay filtros aplicados ****************/
      this.lineas_productos_estado_todos = false;
    }
    /*************************** Filtro **************************/
    // Filtra los productos según las lineas de productos seleccionadas
    this.prods_filtrados = this.prods_filtrados.filter((el: any) => {
      return lineas_productos_seleccionadas.some((f: any) => {
        return f._id == el.linea_producto[0];
      });
    });
  }

  /***************************** Otras funcionalidades ***************************/
  /**
   * Hace el paso entre el componente de seleccion de cliente al
   * componente de sugerir pedido
   */
  activarComponenteDelPedido() {
    //Ajustando modal
    this.pedidosSugeridos = true;
  }

  /**
   * Lo llama el template para sumar o restar unidades al producto
   * y actualizar el carrito según esto
   * @param indexProd El índice en los arreglos filtrados con la información
   * del producto
   * @param caja Indica lo que deba sumar o restar de cajas (1 o -1)
   * @param unidad  Indica lo que deba sumar o restar de unidades (1 o -1)
   */
  sumarRestarUndCajas(producto: Producto, indexProd: number, caja: number, unidad: number) {
    //Recuperar informacion relevante del producto
    const prodId = producto._id ? producto._id : '';
    const prod = producto;
    const und_x_caja: number = prod.precios?.[0].und_x_caja || 0;
    //Asignar el inventario correcto
    this.unidadesAux[prodId] += unidad;
    //Verificar si hay suficientes unidades de inventario para esta operación
    //Si no, se levanta un error y se ajustan las unidades para que se agregue el máximo
    const inventarioUnidad = prod.precios?.[0].inventario_unidad < 0 ? 0 : prod.precios?.[0].inventario_unidad;
    const max_inv: number = inventarioUnidad || 0;
    if (this.unidadesAux[prodId] > max_inv) {
      this.unidadesAux[prodId] = max_inv;
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
      };
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No hay suficientes unidades de este producto para este pedido. La cantidad se ajustó automáticamente a la cantidad máxima posible.';
      modalRef.componentInstance.btn_msg = 'Volver';
    } else {
      // Organiza el valor de las cajas
      this.cajasAux[prodId] = Math.round((this.unidadesAux[prodId] / und_x_caja) * 100) / 100;
      // Por si, por algun motivo, se resta mas de lo que debería
      if (this.unidadesAux[prodId] < 0) {
        this.unidadesAux[prodId] = 0;
      }
      if (this.cajasAux[prodId] < 0) {
        this.cajasAux[prodId] = 0;
      }
      //Se actualiza en el carrito
      this.carritoDist.actualizarProducto(prod, this.cajasAux[prodId], this.unidadesAux[prodId]);
    }
  }

  /**
   * Recibe el producto saldo o promocion y lo agrega al carrito de compras
   * valida el inventario del producto y no deja se agreguen mas de lo permitido
   * @param indexProd El índice en el arreglo de saldos y promos
   * @param unidad  Indica lo que deba sumar o restar de unidades (1 o -1)
   */
  sumarSaldosPromociones(indexProd: number, caja: number, unidad: number) {
    //Recuperar informacion relevante del producto
    const prod: Producto = this.productos_saldos_promos[indexProd];
    const indexTodos: number = this.productos_saldos_promos.indexOf(prod);
    const und_x_caja: number = prod.precios?.[0].und_x_caja || 0;

    //Asignar el inventario correcto
    this.unidades_saldo_promo[indexTodos] += unidad;
    //Verificar si hay suficientes unidades de inventario para esta operación
    //Si no, se levanta un error y se ajustan las unidades para que se agregue el máximo
    const max_inv: number = prod.precios?.[0].inventario_unidad || 0;
    if (this.unidades_saldo_promo[indexProd] > max_inv) {
      this.unidades_saldo_promo[indexProd] = max_inv;
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
      };
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'No hay suficientes unidades de este producto para este pedido.';
      modalRef.componentInstance.btn_msg = 'Volver';
      return;
    }
    // Organiza el valor de las cajas
    if (prod.saldos) {
      caja = Math.round((this.unidades_saldo_promo[indexTodos] / und_x_caja) * 100) / 100;
      if (caja < 0) {
        caja = 0;
      }
    }
    //Por si, por algun motivo, se resta mas de lo que debería
    if (this.unidades_saldo_promo[indexTodos] < 0) {
      this.unidades_saldo_promo[indexTodos] = 0;
    }
    //Se actualiza en el carrito
    this.carritoDist.actualizarProducto(prod, caja, this.unidades_saldo_promo[indexTodos]);
  }

  /**
   * Reinicia las unidades y cajas del producto que entra por parametro, y actualiza el carrito
   * para que refleje el cambio
   * @param producto El producto a eliminar del carrito
   */
  eliminarProducto(producto: Producto) {
    let indexFilt;
    if (!producto.saldos && !producto.promocion) {
      // Catalogo
      indexFilt = this.prods_filtrados.indexOf(producto);
      this.sumarRestarUndCajas(producto, indexFilt, Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER);
    } else {
      // Saldos y promociones
      indexFilt = this.productos_saldos_promos.indexOf(producto);
      this.sumarSaldosPromociones(indexFilt, Number.MIN_SAFE_INTEGER, Number.MIN_SAFE_INTEGER);
    }
  }
}
