<app-header-distribuidor></app-header-distribuidor>
<br />
<section class="contenido">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Todos</a>
      <ng-template ngbNavContent>
        <app-clientes-info></app-clientes-info>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink> Nuevos</a>
      <ng-template ngbNavContent>
        <app-clientes-nuevos></app-clientes-nuevos>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>Activos</a>
      <ng-template ngbNavContent>
        <app-clientes-activos></app-clientes-activos>
      </ng-template>
    </li>
    <li [ngbNavItem]="4">
      <a ngbNavLink>Creados</a>
      <ng-template ngbNavContent>
        <app-clientes-creados></app-clientes-creados>
      </ng-template>
    </li>
    <li [ngbNavItem]="5">
      <a ngbNavLink>Gráficas</a>
      <ng-template ngbNavContent>
        <app-clientes-graficas></app-clientes-graficas>
      </ng-template>
    </li>
   
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</section>