import { Component, DoCheck, OnInit } from '@angular/core';
import { faChevronDown, faCircle, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PedidoResumen } from 'src/app/models/pedido_resumen.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PedidosService } from 'src/app/services/pedidos/pedidos.service';

@Component({
  selector: 'app-historial-pedidos',
  templateUrl: './historial-pedidos.component.html',
  styleUrls: ['./historial-pedidos.component.css'],
})
export class HistorialPedidosComponent implements OnInit, DoCheck {
  /** Íconos FontAwesome para la UI */
  faSearch = faSearch;
  faChevronDown = faChevronDown;
  faCircle = faCircle;
  faTimesCircle = faTimesCircle;
  puntoSeleccionado = 'todos';

  /** Vars para manejar la selección de fecha */
  model?: NgbDateStruct;
  date!: { year: number; month: number };
  str_date = '';
  public listaPuntos: any[] = [];
  puntosHo: any = [];
  pedidosGenerales: any;
  copyPedidosGenerales: any;
  punto_entrega: any;
  constructor(
    private calendar: NgbCalendar,
    private rest: RestService,
    private auth: AuthService,
    private router: Router,
    private pedidosService: PedidosService
  ) {
  }
  async ngOnInit(): Promise<void> {
   await  this.consultarPuntos();
   await this.consultarPedidos();
   await this.autoCompletadoBusqueda();
   this.punto_entrega = JSON.parse(localStorage.getItem('punto_entrega_seleccionado')!);
    if(this.punto_entrega){
      this.puntoSeleccionado  = this.punto_entrega._id
    }
  }
  public consultarPuntos() {
    if (this.auth.user_horeca?._id) {
      console.log(this.auth.user_horeca?._id);
      this.pedidosService
        .getPuntosEstablecimientosHo(this.auth.user_horeca?._id)
        .toPromise()
        .then((data: any) => {
          console.log(data);
          if (this.auth.user?.tipo_trabajador === 'PROPIETARIO/REP LEGAL') {
            for (const punto of data) {
              this.listaPuntos.push(punto);
            }
          } else {
            for (const punto of data) {
              console.log(punto);
              this.puntosHo = this.auth.user?.puntos_entrega;
              if (this.auth.user?.puntos_entrega && this.puntosHo.length > 0) {
                // eslint-disable-next-line no-unsafe-optional-chaining
                for (const puntoU of this.puntosHo) {
                  console.log(puntoU);
                  let idPuntoUser;
                  if (puntoU['_id']) {
                    idPuntoUser = puntoU['_id'];
                  } else {
                    idPuntoUser = puntoU;
                  }
                  console.log(idPuntoUser, punto._id);
                  if (idPuntoUser === punto._id) {
                    this.listaPuntos.push(punto);
                  }
                }
              }
            }
          }
        })
        .catch((err: any) => {});
    }
  }
  filtrarPedidos() {
    this.pedidos_facturado_filtrados = [];
    this.pedidos_entregado_filtrados = [];
    this.pedidos_recibido_filtrados = [];
    this.pedidos_calificado_filtrados = [];
    this.pedidos_rechazado_filtrados = [];
    this.pedidos_cancelado_filtrados = [];
    this.pedidos = [];
    console.log(this.puntoSeleccionado);
    if (this.puntoSeleccionado == 'todos') {
      if (this.auth.user?.tipo_trabajador === 'PROPIETARIO/REP LEGAL') {
        for (const producto of this.copyPedidosGenerales) {
          if (producto.usuario_horeca == this.auth.user_horeca?._id) {
            this.agregarPedido(producto);
          }
        }
      } else {
        for (const producto of this.copyPedidosGenerales) {
          this.puntosHo = this.auth.user?.puntos_entrega;
          if (this.auth.user?.puntos_entrega && this.puntosHo.length > 0) {
            // eslint-disable-next-line no-unsafe-optional-chaining
            for (const punto of this.puntosHo) {
              let idPuntoUser;
              if (punto['_id']) {
                idPuntoUser = punto['_id'];
              } else {
                idPuntoUser = punto;
              }
              if (idPuntoUser === producto.punto_entrega) {
                this.agregarPedido(producto);
              }
            }
          }
        }
      }
    } else {
      for (const producto of this.pedidosGenerales) {
        if (this.puntoSeleccionado === producto.punto_entrega) {
          this.agregarPedido(producto);
        }
      }
    }
  }
  /** Lista de meses para apoyar el formateo de la fecha */
  private meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];

  /** Listas de pedidos para resumen */
  public pedidos: PedidoResumen[] = [];
  public pedidos_filtrados: PedidoResumen[] = [];
  public pedidos_facturado_filtrados: PedidoResumen[] = [];
  public pedidos_calificado_filtrados: PedidoResumen[] = [];
  public pedidos_entregado_filtrados: PedidoResumen[] = [];
  public pedidos_recibido_filtrados: PedidoResumen[] = [];
  public pedidos_rechazado_filtrados: PedidoResumen[] = [];
  public pedidos_cancelado_filtrados: PedidoResumen[] = [];

  /**  */
  public nombre_dist = '';

  /**Guardamos dinamicamente los valores de autocompletado */
  filteredOptions: any;
  mySearch = new FormControl();

  /** Imagen distribuidor placeholder */
  distribuidor_placeholder = '../../assets/img/icon-organizacion.png';

  selectToday() {
    this.model = this.calendar.getToday();
  }

  ngDoCheck(): void {
    if (this.nombre_dist == '') {
      this.pedidos_filtrados = this.pedidos;
    } else {
      this.pedidos_filtrados = [];
      for (const aux of this.pedidos) {
        if (
          this.toUnaccentedLowerCase(aux.nombre_distribuidor).includes(this.toUnaccentedLowerCase(this.nombre_dist))
        ) {
          this.pedidos_filtrados.push(aux);
        }
      }
    }

    if (this.model && this.model.day && this.model.month && this.model.year) {
      const pedidos_fecha: PedidoResumen[] = [];
      this.str_date = `${this.meses[this.model.month - 1]} ${this.model.day < 10 ? '0' : ''}${this.model.day} de ${
        this.model.year
      }`;
      for (const aux of this.pedidos_filtrados) {
        if (aux.fecha == this.str_date) {
          pedidos_fecha.push(aux);
        }
      }
      this.pedidos_filtrados = pedidos_fecha;
    } else {
      this.str_date = '';
    }
  }

  /**
   * Recibe cualquier string y devuelve el mismo string, pero
   * en minúsculas y sin tildes ni diéresis
   * @param str El string a convertir
   * @returns El string convertido
   */
  private toUnaccentedLowerCase(str: string): string {
    return str
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * Recupera la lista de todos los pedidos y agrega los que
   * pertenezcan a este usuario horeca
   
  private consultarPedidos() {
    this.rest
      .getJWT('pedido')
      .toPromise()
      .then((resp: any) => {
        console.log('resprespresp', resp);
        if (this.auth.user?.tipo_trabajador === 'PLANEADOR PEDIDOS') {
          for (const aux of resp) {
            if (aux.usuario_horeca === this.auth.user_horeca?._id && this.auth.user._id === aux.trabajador) {
              this.agregarPedido(aux);
            }
          }
        } else {
          for (const aux of resp) {
            if (aux.usuario_horeca == this.auth.user_horeca?._id) {
              this.agregarPedido(aux);
            }
          }
        }
      });
  }
*/

  private consultarPedidos() {
    if (this.auth.user_horeca?._id) {
      this.pedidosService
        .getPedidoPorEstablecimiento(this.auth.user_horeca?._id)
        .toPromise()
        .then((productos: any) => {
          this.pedidosGenerales = productos;
          this.copyPedidosGenerales = productos;
          if (this.auth.user?.tipo_trabajador === 'PROPIETARIO/REP LEGAL') {
            for (const producto of productos) {
              if (producto.usuario_horeca == this.auth.user_horeca?._id) {
                this.agregarPedido(producto);
              }
            }
          } else {
            for (const producto of productos) {
              this.puntosHo = this.auth.user?.puntos_entrega;
              if (this.auth.user?.puntos_entrega && this.puntosHo.length > 0) {
                // eslint-disable-next-line no-unsafe-optional-chaining
                for (const punto of this.puntosHo) {
                  let idPuntoUser;
                  if (punto['_id']) {
                    idPuntoUser = punto['_id'];
                  } else {
                    idPuntoUser = punto;
                  }
                  if (idPuntoUser === producto.punto_entrega) {
                    this.agregarPedido(producto);
                  }
                }
              }
            }
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }
  /**
   * Procesa los datos del pedido crudo y agregar el pedido a la lista de
   * pedidos a mostrar. El procesamiento incluye consultar el nombre y
   * el logo del distribuidor encargado del pedido
   * @param pedido Los datos crudos del pedido a agregar
   */
  private agregarPedido(pedido: any) {
    console.log('pedido', pedido);
    let idDist;
    if (pedido.distribuidor) {
      idDist = pedido.distribuidor;
    } else {
      if (pedido.data_distribuidor && pedido.data_distribuidor instanceof Array) {
        idDist = pedido.data_distribuidor[0]?._id;
      }
    }
    this.rest
      .getJWT('distribuidor/' + idDist)
      .toPromise()
      .then((resp: any) => {
        const ped: PedidoResumen = new PedidoResumen(
          pedido.estado,
          resp.nombre,
          this.procesarFecha(pedido.tiempo_tracking_hora),
          pedido.productos.length,
          this.procesarUnidades(pedido.productos),
          resp.logo || '',
          pedido._id,
          pedido.total_pedido,
          pedido.id_pedido
        );
        if (ped.estado.startsWith('Cancelado')) {
          ped.estado = 'Cancelado';
        }
        if (
          ped.estado == 'Facturado' ||
          ped.estado == 'Entregado' ||
          ped.estado == 'Recibido' ||
          ped.estado == 'Calificado' ||
          ped.estado == 'Rechazado' ||
          ped.estado == 'Cancelado'
        ) {
          this.pedidos.push(ped);
        }
        if (ped.estado == 'Facturado') {
          this.pedidos_facturado_filtrados.push(ped);
        } else if (ped.estado == 'Entregado') {
          this.pedidos_entregado_filtrados.push(ped);
        } else if (ped.estado == 'Recibido') {
          this.pedidos_recibido_filtrados.push(ped);
        } else if (ped.estado == 'Calificado') {
          this.pedidos_calificado_filtrados.push(ped);
        } else if (ped.estado == 'Rechazado') {
          this.pedidos_rechazado_filtrados.push(ped);
        } else if (ped.estado == 'Cancelado') {
          this.pedidos_cancelado_filtrados.push(ped);
        }
      });
  }
  /**
   * Toma el formato de fecha de Date y devuelve un string de la forma "Mes día"
   * (e.g. Noviembre 4)
   * @param fecha_cruda La fecha en formato <año>-<mes>-<día>T<hora>
   * @returns La fecha en formato <Mes> <día>
   */
  private procesarFecha(fecha_cruda: string): string {
    return fecha_cruda;
  }

  /**
   * Recibe un arreglo con la información de los productos del pedido
   * y cuenta las unidades totales del pedido
   * @param productos El arreglo con la información de los productos
   * @returns El número de unidades del pedido
   */
  private procesarUnidades(productos: any): number {
    let ans = 0;

    for (const aux of productos) {
      ans += aux.unidad;
    }

    return ans;
  }

  /*Este metodo tiene como objeto autocompletar la busqueda del usuario */
  autoCompletadoBusqueda() {
    this.filteredOptions = this.mySearch.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.nombre)),
      map((nombre) => (nombre ? this._filter(nombre) : this.pedidos.slice()))
    );
  }

  displayFn(user: any): string | undefined {
    console.log(user ? user.value : '');
    return user ? user.nombre : undefined;
  }

  returnFn(user: any): string | undefined {
    return user ? user.value : undefined;
  }

  itemDisplayFn(item: any) {
    return item ? item.name : '';
  }

  private _filter(nombre_distribuidor: string) {
    const filterValue = nombre_distribuidor.toLowerCase();
    return this.pedidos.filter((option: any) => option.nombre_distribuidor.toLowerCase().indexOf(filterValue) === 0);
  }

  /**Ests metodos tienen como objeto ver el detalle de los distribuidores*/
  verDetalleDistribuidor(event: any) {
    const filtrado = event.option.value._id;
    this.router.navigate(['/pedidos', filtrado]);
  }
}
