<section>
  <!-- Clientes -->
  <div class="card p-4 mt-4">
    <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
      <h5 class="m-0">Clientes</h5>
      <button class="btn-purple" (click)="descargaArchivoClientes()">Descargar datos</button>
    </span>
    <div class="card-body scroll-tabla mt-3 p-0">
      <ng2-smart-table [settings]="settings_clientes" [source]="source_clientes">
      </ng2-smart-table>
    </div>
  </div>
  <!-- Pedidos -->
  <div class="card p-4 mt-4">
    <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
      <h5 class="m-0">Pedidos</h5>
      <button class="btn-purple" (click)="descargaArchivoPedidos()">Descargar datos</button>
    </span>
    <div class="card-body scroll-tabla mt-3 p-0">
      <ng2-smart-table [settings]="settings_pedidos" [source]="source_pedidos">
      </ng2-smart-table>
    </div>
  </div>
  <!-- Ventas -->
  <div class="card p-4 mt-4">
    <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
      <h5 class="m-0">Ventas</h5>
      <button class="btn-purple" (click)="descargaArchivoVentas()">Descargar datos</button>
    </span>
    <div class="card-body scroll-tabla mt-3 p-0">
      <ng2-smart-table [settings]="settings_ventas" [source]="source_ventas">
      </ng2-smart-table>
    </div>
  </div>
  <!-- Usuarios -->
  <div class="card p-4 mt-4">
    <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
      <h5 class="m-0">Usuarios</h5>
      <button class="btn-purple" (click)="descargaArchivoUsuarios()">Descargar datos</button>
    </span>
    <div class="card-body scroll-tabla mt-3 p-0">
      <ng2-smart-table [settings]="settings_usuarios" [source]="source_usuarios"></ng2-smart-table>
    </div>
  </div>
  <!-- Portafolio -->
  <div class="card p-4 mt-4">
    <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
      <h5 class="m-0">Portafolio</h5>
      <button class="btn-purple" (click)="descargaArchivoPortafolio()">Descargar datos</button>
    </span>
    <div class="card-body scroll-tabla mt-3 p-0">
      <ng2-smart-table [settings]="settings_portafolio" [source]="source_portafolio"></ng2-smart-table>
    </div>
  </div>
  <!-- Puntos Feat -->
  <!-- <div class="card p-4 mt-4"> -->
  <app-tabla-puntos-ft hidden></app-tabla-puntos-ft>
  <!-- </div> -->
  <!-- Clientes Asignados -->
  <div class="card p-4 mt-4" hidden>
    <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
      <h5 class="m-0">Clientes asignados</h5>
      <!-- <button class="btn-purple" (click)="descargaArchivoClientesAsignados()">Descargar datos</button> -->
    </span>
    <div class="card-body scroll-tabla mt-3 p-0">
      <ng2-smart-table [settings]="settings_clientes_asignados" [source]="source_clientes_asignados"></ng2-smart-table>
    </div>
  </div>
  <!-- Saldos y Promociones -->
  <div class="card p-4 mt-4" hidden>
    <span class="d-flex flex-row align-items-center justify-content-between pt-4 pb-0">
      <h5 class="m-0">Saldos y promociones</h5>
      <!-- <button class="btn-purple" (click)="descargaArchivoSaldosPromos()">Descargar datos</button> -->
    </span>
    <div class="card-body scroll-tabla mt-3 p-0">
      <ng2-smart-table [settings]="settings_saldos_promos" [source]="source_saldos_promos"></ng2-smart-table>
    </div>
  </div>
</section>