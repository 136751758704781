<div class="mb-4 w-100">
  <h5>Indicadores de tus pedidos del mes</h5>
</div>
<!--Indicadores de los pedidos-->
<div class="indicadores">
  <div class="card-info" *ngFor="let indi of indicadores">
    <h3 class="m-0">{{ indi.cantidad }}</h3>
    <span>{{indi._id}}</span>
  </div> 
</div>
<div class="col-12">
  <div class="row h-100" style="  margin-top: 2rem;
    margin-left: -1rem;">
    <form class="col-md-8 pr-3 ng-untouched ng-pristine ng-valid my-auto">

      <div class="buscador">
        <fa-icon (click)="buscarNombre(1)" [icon]="faSearch"></fa-icon>
        <div class="linea-vertical"  *ngIf="buscadorActivo"></div>
        <fa-icon (click)="limpiarFiltros()" [icon]="faTimes" *ngIf="buscadorActivo"></fa-icon>
        <input  type="text" placeholder="Buscar por ID de pedido / nombre del establecimiento / nombre del punto / NIT"
        (input)="capturarTexto()"
        (keyup.enter)="buscarNombre(1)"
        [(ngModel)]="buscador"
        [ngModelOptions]="{standalone: true}">              
      </div>
    </form>
    <div class="col-md-4 center-right my-auto">
      <div class="row h-100">
        
        <div *ngIf="localUser && (localUser.user.tipo_trabajador === 'PROPIETARIO/REP LEGAL' || localUser.user.tipo_trabajador === 'ADMINISTRADOR') " [ngClass]="{'col-10': buscadorActivo, 'col-12': !buscadorActivo}" class="my-auto">
          <select
            *ngIf="lista_trabajadores"
              [(ngModel)]="id_trabajador"
              [ngModelOptions]="{standalone: true}"
              class="d-inline-block  select-filtro"
              (ngModelChange)="buscarNombre(1)" 
              >
              <option selected value="">Filtrar por todos los trabajadores</option>
              <option *ngFor="let trab of lista_trabajadores" [value]="trab._id">{{trab.nombres}} {{trab.apellidos}}</option>
            </select>
        </div>
        <div class="col-2 my-auto" style="display: none;">
          <fa-icon (click)="limpiarFiltros()" [icon]="faTimes" *ngIf="buscadorActivo">Limpiar filtros</fa-icon>
        </div>
      </div>
    </div>
    
  </div>
         
</div>
<div class="w-100 ml-1 mt-4">
  <h6>Estados del pedido</h6>
 
</div>
<div class="row">
  <div class="col-6">
<!--Estados para filtrar la tabla-->
<div class="mt-3">
  <div class="col p-0 col-md-6 col-lg-7 col-xl-12">
    <ul ngbNav #nav1="ngbNav" [(activeId)]="activeNav1" class="nav-pills">
      <li [ngbNavItem]="1" (click)="filtrarPorEstadoNew('curso','1', null)">
        <a ngbNavLink>Curso</a>
      </li>
      <li [ngbNavItem]="2" (click)="filtrarPorEstadoNew('historico','1', null)">
        <a ngbNavLink>Histórico</a>
      </li>
    </ul>
  </div>
</div>
  </div>
  <div class="col-6 center-right">
    <button class="button-pedidos" (click)="descargarpedidos('curso')">
      <span *ngIf="!loadingXLS"> Descargar pedidos en curso</span>
      <span *ngIf="loadingXLS">
        <i class="fa fa-spinner fa-spin"></i> Cargando ...
      </span>
    </button>
    <button class="button-pedidos" (click)="descargarpedidos('historico')">
      <span *ngIf="!loadingXLS"> Descargar pedidos histórico</span>
      <span *ngIf="loadingXLS">
        <i class="fa fa-spinner fa-spin"></i> Cargando ...
      </span>
    </button>
  </div>
</div>

<div class="mt-3">
  <div class="col p-0 col-12">
    <ul ngbNav #nav2="ngbNav" [(activeId)]="activeNav2" class="nav-pills">
      <li [ngbNavItem]="1" (click)="$event.preventDefault();filtrarPorEstadoNew(estado_actual,'1', null)">
        <a ngbNavLink>Todos</a>
      </li>
      <li *ngFor="let elemento of elementos; let i = index" (click)="filtrarPorEstadoNew(estado_actual,'1', elemento.id)" [ngbNavItem]="i + 2">
        <a ngbNavLink>{{ elemento.estado }}</a>
      </li>
      
    </ul>
  </div>
</div>
<!--Tabla de pedidos-->
<div class="card">
  <div *ngIf="loading" class="loading-overlay">
    <div class="spinner"></div>
    <span>Cargando...</span>
  </div>
  <div class="card-body scroll-tabla">
    <ng2-smart-table [settings]="settings" [source]="source" 
    (editConfirm)="onSaveConfirm($event)"
    (custom)="onCustom($event)"></ng2-smart-table>
  </div>
   <!--Paginador-->
  <div class="paginator"  *ngIf="!loading && totalClientes > 10">
    <button class="paginator-btn" (click)="goToPage(1)">
      <fa-icon [icon]="faChevronLeft"></fa-icon>
      <fa-icon [icon]="faChevronLeft"></fa-icon>
    </button> <!-- Flecha al inicio -->
    <button class="paginator-btn" (click)="goToPage(currentPage - 1)">
      <fa-icon [icon]="faChevronLeft"></fa-icon>
    </button> <!-- Flecha atrás -->
    
    <!-- Números de página -->
    <!--<button class="paginator-btn" *ngFor="let page of pageNumbers" (click)="goToPage(page)"
    [ngClass]="{'active': page === currentPage}"
    >
      {{ page }}  {{currentPage}} {{totalPaginas}}
    </button>
    <button class="paginator-btn" (click)="goToPage(currentPage + 1)">
      <fa-icon [icon]="faChevronRigth"></fa-icon>
    </button>
    <button class="paginator-btn" (click)="goToPage(totalPaginas)">
      <fa-icon [icon]="faChevronRigth"></fa-icon>
      <fa-icon [icon]="faChevronRigth"></fa-icon>
    </button>-->
    <button class="paginator-btn" *ngFor="let page of pageNumbers" (click)="goToPage(page)"
    [ngClass]="{'active': page === currentPage}">
  {{ page }}
</button>

<button class="paginator-btn" (click)="goToPage(currentPage + 1)" *ngIf="currentPage < totalPaginas">
  <fa-icon [icon]="faChevronRigth"></fa-icon>
</button>

<button class="paginator-btn" (click)="goToPage(totalPaginas)" *ngIf="currentPage < totalPaginas">
  <fa-icon [icon]="faChevronRigth"></fa-icon>
  <fa-icon [icon]="faChevronRigth"></fa-icon>
</button>
  </div>
</div>