<!--Escoger cliente y punto de entrega-->
<div *ngIf="pedidosSugeridos === false">
  <div class="seleccionar-container m-0">
    <!--Placeholder para antes de seleccionar cliente y punto de entrega-->
    <div class="estado-vacio">
      <div class="w-100 d-flex flex-column mt-3 justify-content-center">
        <div class="d-flex justify-content-center">
          <img src="../../../assets/img/icon-warning-amarillo.png" class="aviso"
            alt="Icono de una banana en señal de advertencia" />
        </div>
        <div class="text-center">
          <p>¡Ups!</p>
          <p>Selecciona primero para quién creas el pedido sugerido</p>
        </div>
      </div>
    </div>
    <!--Seleccionar cliente y punto de entrega-->
    <div class="seleccionar-punto d-flex justify-content-end">
      <div class="card-pedido pb-4">
        <div class="d-flex container flex-row flex-wrap mt-4">
          <!--Selector cliente-->
          <div class="d-flex flex-column col-12 col-sm-6">
            <label class="fontWBold">Establecimiento</label>
            <select [(ngModel)]="carritoDist.cliente" (change)="selectCliente()">
              <option value="Selecciona" [defaultSelected]="true"> Selecciona</option>
              <option *ngFor="let cliente of clientes; index as i;" [ngValue]="cliente">
                {{ cliente.nombre_establecimiento }}
              </option>
            </select>
          </div>
          <!--Selector punto de entrega-->
          <div class=" d-flex flex-column col-12 col-sm-6">
            <label class="fontWBold">Punto de entrega</label>
            <select [(ngModel)]="carritoDist.punto_entrega" (change)="selectPuntoEntrega()">
              <option value="Selecciona" [defaultSelected]="true"> Selecciona</option>
              <option *ngFor="let punto of puntos_entrega; index as i;" [ngValue]="punto">
                {{ punto.nombre }}
              </option>
            </select>

          </div>
          <div class=" d-flex flex-column col-md-12 marginDireccion">
            <label *ngIf="carritoDist.punto_entrega" class="fontWBold">Dirección del punto seleccionado:</label>
            <span *ngIf="carritoDist.punto_entrega">{{carritoDist.punto_entrega.direccion}}</span>

          </div>
        </div>
        <!--Btn continuar-->
        <div class="pl-4 mt-4 container">
          <button class="continue" (click)="activarComponenteDelPedido()"
            [disabled]="carritoDist.cliente == undefined || carritoDist.punto_entrega == undefined">
            Continuar
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="pedidosSugeridos === true">
  <div class="cards-container m-0">
    <!-- Catalogo o saldos y promociones -->
    <div class="catalogo-saldos categorias-lineas">
      <span class="cursorPointer" (click)="pestana_saldos_promociones = !pestana_saldos_promociones"
        [ngClass]="{'categorias-lineas-selected': pestana_saldos_promociones === false}">
        Catálogo
      </span>
      <span class="cursorPointer" (click)="pestana_saldos_promociones = !pestana_saldos_promociones"
        [ngClass]="{'categorias-lineas-selected': pestana_saldos_promociones === true}">
        Saldos y promociones
      </span>
    </div>
  <!--Titulo-->
  <div class="container-titulo w-100 d-flex flex-row justify-content-between align-items-center">
    <span>Escoge los productos para el pedido</span>
    <span class="d-flex flex-row justify-content-center align-items-center purple-button"
      (click)="borrarFiltroProducto()" *ngIf="flag_borar_filtro_busqueda">
      <fa-icon [icon]="faTrash"></fa-icon> Borrar filtro
    </span>
  </div>
  <!--Buscador-->
  <div class="container-busqueda barra-busqueda rounded" *ngIf="!pestana_saldos_promociones">
    <div class="d-flex flex-row autocompletado-busqueda px-0 h-100 w-100">
      <button class="col-2 col-lg-1 col-sm-2 p-0 boton-buscar d-flex justify-content-center">
        <fa-icon class="fa-icon-search m-0" [icon]="faSearch" size="lg" [styles]="{ color: '#41403E' }">
        </fa-icon>
      </button>
      <form class="example-form col-12 col-sm-10 col-lg-11 pr-3">
        <mat-form-field class="example-full-width">
          <input
          #escogido
          type="text"
          placeholder="Buscar por nombre del producto / Cod Dist. / Cod Org."
          aria-label="Buscar distribuidor"
          [(ngModel)]="stBusq"
          [ngModelOptions]="{standalone: true}"
          class="w-100 h-100 input-busqueda pr-0" />
          <!--<input #escogido type="text" placeholder="Buscar por nombre del producto" aria-label="Buscar distribuidor"
            matInput [formControl]="mySearch" [matAutocomplete]="auto" class="w-100 h-100 input-busqueda pr-0" />
          <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [displayWith]="itemDisplayFn"
            class="w-100" (optionSelected)="filtroProducto($event)">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{ option?.nombre }}
            </mat-option>
          </mat-autocomplete>-->
        </mat-form-field>
      </form>
    </div>
  </div>
  <!--Categorías-->
  <div class="categorias-productos" *ngIf="!pestana_saldos_promociones">
    <span class="pb-3">Categorías</span>
    <div class="categorias-lineas">
      <span (click)="categorias_productos_estados_todos = !categorias_productos_estados_todos"
        (click)="handleClickCategoria(true)"
        [ngClass]="{'categorias-lineas-selected': categorias_productos_estados_todos === true}">
        Todos
      </span>
      <span *ngFor="let cat of categorias_productos; index as i;"
        (click)="categorias_productos_estados[i] = !categorias_productos_estados[i]"
        (click)="handleClickCategoria(false)"
        [ngClass]="{'categorias-lineas-selected': categorias_productos_estados[i] === true}">
        {{ cat.nombre }}
      </span>
    </div>
  </div>
  <!--Líneas de producto-->
  <div class="lineas-productos" *ngIf="!pestana_saldos_promociones && !flag_borar_filtro_busqueda">
    <span class="pb-3">Líneas de producto</span>
    <div class="categorias-lineas">
      <span (click)="lineas_productos_estado_todos = !lineas_productos_estado_todos"
        (click)="handleClickLineaProducto(true)"
        [ngClass]="{'categorias-lineas-selected': lineas_productos_estado_todos === true}">
        Todos
      </span>
      <span *ngFor="let linea of lineas_productos; index as i;"
        (click)="lineas_productos_estado[i] = !lineas_productos_estado[i]" (click)="handleClickLineaProducto(false)"
        [ngClass]="{'categorias-lineas-selected': lineas_productos_estado[i] === true}">
        {{ linea.nombre }}
      </span>
    </div>
  </div>
  <!--Catalogo-->
  <div class="products-container mt-2"
    *ngIf="prods_filtrados && prods_filtrados.length > 0 && !pestana_saldos_promociones">
    <div class="productos-contenedor">
      <div class="product-card" *ngFor="let producto of prods_filtrados; index as i;">
        <div>
          <span>Código producto distribuidor:<br>{{ producto?.codigo_distribuidor_producto || '' }}</span>
        </div>
        <div>
          <div class="space">
            <span>{{ producto?.precios[0].estado }}</span>
            <span>{{ producto?.precios[0].puntos_ft_unidad }} punto ft</span>
          </div>
          <div class="container-img">
            <img src='{{ producto?.fotos[0] || product_placeholder }}' alt="{{ producto?.nombre || 'Producto'}}" />
          </div>
          <span>{{ producto?.nombre }}</span>
          <div class="space">
            <span>Precio und.</span>
            <span class="price">
              {{ producto?.precios[0].precio_unidad | currency: '$ ':'symbol':'1.0-0' }}
            </span>
          </div>
          <div class="space">
            <span>Precio {{ producto?.precios[0].unidad_medida }}</span>
            <span>
              {{ producto?.precios[0].precio_unidad/producto?.precios[0].cantidad_medida | currency:
              '$':'symbol':'1.0-0' }}
            </span>
          </div>
          <div class="space">
            <span>Caja x {{ producto?.precios[0].und_x_caja || 1 }}</span>
            <span>{{ producto?.precios[0].precio_caja | currency: '$':'symbol':'1.0-0' }}</span>
          </div>
          <div class="botones-contenedor">
            <!--Agregar al carrito-->
            <div class="d-flex justify-content-between mt-3">
            </div>
            <!--Botones para agregar al carrito-->
            <div class="d-flex justify-content-evenly align-items-center">
              <div class="d-flex flex-column">
                <div class="d-flex justify-content-center">
                  <span>Unidades</span>
                </div>
                <div>
                  <button class="btn_cantidad btn_menos" (click)="sumarRestarUndCajas(producto, i, 0, -1)"
                    [disabled]="unidadesAux[producto._id] == 0">
                    -
                  </button>
                  <input class="span_cnt" type="number" [(ngModel)]="unidadesAux[producto._id]" disabled />
                  <button class="btn_cantidad btn_mas" (click)="sumarRestarUndCajas(producto, i, 0, 1)">+</button>
                </div>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex justify-content-center">
                  <span>Cajas</span>
                </div>
                <input class="span_cnt2" type="number" [(ngModel)]="cajasAux[producto._id]" disabled />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf=" !pestana_saldos_promociones && prods_filtrados.length === 0">
    <img src="./assets/img/icon-warning-amarillo.png" alt="Advertencia" id="icon-warning"
    style="max-width: 200px;     padding-left: 9% !important;" class="w-100 p-4" />
    <br>
      No tienes productos en catálogo
  </div>
       <!-- Sadlos y promociones -->
       <div class="products-container mt-2"
       *ngIf="productos_saldos_promos && productos_saldos_promos.length > 0 && pestana_saldos_promociones">
       <div class="productos-contenedor">
         <div class="product-card" *ngFor="let producto of productos_saldos_promos; index as i;">
           <div>
             <span>Código {{producto?.saldos ? "saldo" : "promoción"}}:<br>{{ producto?.codigo_promo || '' }}</span>
           </div>
           <div>
             <div class="space">
               <span>{{ producto?.precios[0].estado }}</span>
             </div>
             <div class="container-img" *ngIf="producto?.saldos && !producto?.promocio; else carousel_promo">
               <img src='{{ producto?.fotos[0] || product_placeholder }}' alt="{{ producto?.nombre || 'Producto'}}" />
             </div>
             <ng-template #carousel_promo>
               <span class="m-0 w-100 contenedor-imagenes">
                 <div class="col-12 m-0 row_data_prod carouselCont">
                   <ngb-carousel class="w-100">
                     <ng-template ngbSlide *ngFor="let img_src of producto.fotos" class="w-100">
                       <div class="w-100 col-12 d-flex justify-content-center">
                         <img [src]="img_src" class="img-prod rounded" [alt]="'Foto de ' + producto.nombre" />
                       </div>
                     </ng-template>
                   </ngb-carousel>
                 </div>
               </span>
             </ng-template>
             <span>{{ producto?.nombre }}</span>
             <div class="space text-danger" *ngIf="producto?.saldos">
               <span>Precio anterior</span>
               <span class="price">
                 <del>
                   {{ producto?.precios[0].precio_unidad | currency: '$ ':'symbol':'1.0-0' }}
                 </del>
               </span>
             </div>
             <div class="space">
               <span>Precio und.</span>
               <span class="price" *ngIf="producto?.saldos">
                 {{ producto?.precios[0].precio_descuento | currency: '$ ':'symbol':'1.0-0' }}
               </span>
               <span class="price" *ngIf="!producto?.saldos">
                 {{ producto?.precios[0].precio_unidad | currency: '$ ':'symbol':'1.0-0' }}
               </span>
             </div>
             <div class="botones-contenedor">
               <!--Agregar al carrito-->
               <div class="d-flex justify-content-between mt-3">
               </div>
               <!--Botones para agregar al carrito-->
               <div class="d-flex justify-content-evenly align-items-center">
                 <div class="d-flex flex-column">
                   <div class="d-flex justify-content-center">
                     <span>Unidades</span>
                   </div>
                   <div>
                     <button class="btn_cantidad btn_menos" (click)="sumarSaldosPromociones(i, 0, -1)"
                       [disabled]="unidades_saldo_promo[i] == 0">
                       -
                     </button>
                     <input class="span_cnt" type="number" [(ngModel)]="unidades_saldo_promo[i]" disabled />
                     <button class="btn_cantidad btn_mas" (click)="sumarSaldosPromociones(i, 0, 1)">+</button>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </div>
        </div>
        <div *ngIf="pestana_saldos_promociones && productos_saldos_promos.length === 0">
          <img src="./assets/img/icon-warning-amarillo.png" alt="Advertencia" id="icon-warning"
          style="max-width: 300px;     padding-left: 10% !important;" class="w-100 p-4" />
          <br>
            No tienes productos en saldos y promociones
        </div>


    <!-- Informacion cliente -->
    <div class="card-information">
      <div class="d-flex container justify-content-between mt-3">
        <div class="d-flex">
          <div class="d-flex justify-content-between align-items-center pr-3">
            <img [src]="carritoDist.cliente?.logo || '../../../assets/img/bee.png'" class="logo-distribuidor"
              alt="Abeja" />
          </div>
          <div>
            <span>{{ carritoDist.cliente?.nombre_establecimiento }}</span>
            <br />
            <img src="../../../assets/img/icons/icon-pin-map.png" class="icon-pin mr-1"
              alt="icono de un pin de in mapa para indicar un sitio en un mapa" />
            <span class="text-secondary">{{ carritoDist.punto_entrega?.nombre }}</span>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <button class="edit-button" (click)="pedidosSugeridos = false">
            Editar
          </button>
        </div>
      </div>
      <div>
        <hr class="mb-0" />
      </div>
      <!--Productos del carrito-->
      <div class="container d-flex">
        <div class="scroll">
          <!--Producto individual-->
          <div *ngFor="let prod of carritoDist.productos; index as i;" class="d-flex my-3 item-carrito">
            <div>
              <img class="pr-3" [src]="carritoDist.fotos[i]" style="width: 100px" alt="{{ prod.nombre }}" />
            </div>
            <div class="w-100">
              <p>{{ prod.nombre }}</p>
              <p *ngIf="prod.saldos; else precio_normal">
                {{ carritoDist.precios[i][0].precio_descuento | currency: '$':'symbol':'1.0-0' }}/und.
              </p>
              <ng-template #precio_normal>
                <p>
                  {{ carritoDist.precios[i][0].precio_unidad | currency: '$':'symbol':'1.0-0' }}/und.
                </p>
              </ng-template>
              <div class="d-flex w-100">
                <!-- Unidades de catalogo -->
                <div class="w-100 d-flex flex-column" *ngIf="!prod.saldos && !prod.promocion">
                  <span class="text-center">Unidades</span>
                  <div>
                    <button class="btn_cantidad_morado btn_menos_morado"
                      (click)="sumarRestarUndCajas(prod, prods_filtrados.indexOf(prod), 0, -1)"
                      [disabled]="carritoDist.unidades[i] == 0">
                      -
                    </button>
                    <input class="span_cnt" type="number" [(ngModel)]="carritoDist.unidades[i]" />
                    <button class="btn_cantidad_morado btn_mas_morado"
                      (click)="sumarRestarUndCajas(prod, prods_filtrados.indexOf(prod), 0, 1)">+</button>
                  </div>
                </div>
                <!-- Unidades de saldos y promociones -->
                <div class="w-100 d-flex flex-column" *ngIf="prod.saldos || prod.promocion">
                  <span class="text-center">Unidades</span>
                  <div>
                    <button class="btn_cantidad_morado btn_menos_morado"
                      (click)="sumarSaldosPromociones(productos_saldos_promos.indexOf(prod), 0, -1)"
                      [disabled]="carritoDist.unidades[i] == 0">
                      -
                    </button>
                    <input class="span_cnt" type="number" [(ngModel)]="carritoDist.unidades[i]" />
                    <button class="btn_cantidad_morado btn_mas_morado"
                      (click)="sumarSaldosPromociones(productos_saldos_promos.indexOf(prod), 0, 1)">+</button>
                  </div>
                </div>
                <div class="w-100 d-flex flex-column">
                  <span class="text-center">Cajas</span>
                  <div>
                    <input class="span_cnt2" type="number" [(ngModel)]="carritoDist.cajas[i]" />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <fa-icon [icon]="faTimes" id="fa-icon" class="cursorPointer" (click)="eliminarProducto(prod)"></fa-icon>
            </div>
          </div>
        </div>
      </div>
      <div>
        <hr />
      </div>
      <div class="container mt-3">
        <div class="d-flex justify-content-between">
          <h4>Total</h4>
          <h4>{{ carritoDist.total | currency: '$':'symbol':'1.0-0' }}</h4>
        </div>
      </div>
      <!--Btn sugerir pedido-->
      <div class="end-card container" style="cursor: pointer;" (click)="carritoDist.sugerirPedido()">
        <h5 class="pt-3">Sugerir pedido ({{ carritoDist.productos.length }} producto{{ carritoDist.productos.length
          ==
          1 ? '' : 's' }})</h5>
      </div>
    </div>
  </div>
</div>