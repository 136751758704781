import { Component, OnInit } from '@angular/core';
import {
  faStar,
  faSearch,
  faMapMarker,
  faFilter,
  faBoxOpen,
  faAngleRight,
  faTimes,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FiltroDistribuidoresComponent } from 'src/app/modal/filtro-distribuidores/filtro-distribuidores.component';
import { SeleccionarPuntoEntregaComponent } from 'src/app/modal/seleccionar-punto-entrega/seleccionar-punto-entrega.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { Distribuidor } from 'src/app/models/distribuidor.model';
import { RestService } from 'src/app/services/rest/rest.service';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Router } from '@angular/router';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { MapService } from '../../services/map/map.service';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { map, startWith } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';

@Component({
  selector: 'app-distribuidores-buscador',
  templateUrl: './distribuidores-buscador.component.html',
  styleUrls: ['./distribuidores-buscador.component.css'],
})
export class DistribuidoresBuscadorComponent implements OnInit {
  // Referencias a íconos FontAwesome para la UI
  public faSearch = faSearch;
  public faMapMarker = faMapMarker;
  public faFilter = faFilter;
  public faStar = faStar;
  public faTimes = faTimes;
  public faBoxOpen = faBoxOpen;
  public faAngleRight = faAngleRight;
  public faArrowDown = faArrowDown;
  // Punto de entrega seleccionado
  public punto_entrega?: PuntoEntrega;
  // Todos los distribuidores
  public distribuidores: any[] = [];
  // Variables de filtrado
  public ranking = 0;
  public tiempo_entrega = 'Selecciona';
  public categorias_estado: boolean[] = [];
  public categorias_filtro_distribuidores: any[] = [];
  public todos_filtros_desactivados = true;
  /**
   * Guarda los distribuidores sin filtrar de tal forma que cuando se retiren o se cambien
   * los filtros no se tenga que hacer la petición a la base de datos
   */
  public buffer_distribuidores: any;
  // Imagen producto placeholder
  public product_placeholder = '../../assets/img/product-placeholder.png';
  // Flags para indicar si un distribuidor ya está relacionado al punto de entrega
  public vinculado: boolean[] = [];
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Variable distribuidor guarda los datos del distribuidor al consumir el servicio
  public distribuidor: any;
  // Variable donde se guarda datos de todos los distribuidores vinculados
  public vinculados: any;
  public distVinculados: any[] = [];
  public distVinculadosAprobados: any[] = [];
  public distVinculadosPendientes: any[] = [];
  // Guardamos dinamicamente los valores de autocompletado
  public filteredOptions: any;
  public mySearch = new FormControl();
  public searchTerm = '';
  // Variable en donde se guarda el id del usuario horeca al consumir el servicio del punto de entrega
  public usuarioHoreca: any;
  // Usuario horeca logueado
  public userInfo = JSON.parse(localStorage.getItem('auth') || '');
  public idUserHoreca = this.userInfo['user_horeca']['_id'];
  public iconosCategorias: any;

  constructor(
    private modalService: NgbModal,
    private auth: AuthService,
    private rest: RestService,
    private router: Router,
    private distribuidorService: DistribuidorService,
    public locallStorage: LocalStorageService,
    private map: MapService,
    private mapsApiloader: MapsAPILoader
  ) {

    this.punto_entrega = JSON.parse(localStorage.getItem('punto_entrega_seleccionado')!);
    this.auth.punto_seleccionado = this.punto_entrega;
    console.log(' this.punto_entrega',  this.punto_entrega)
    //this.selecionarPunto();
    this.fetchDistribuidores();
    this.fetchCategorias();
    this.handleClickCategoria(false);
    this.iconosCategorias = {
      '616dc4681abad918cd4ba0fb': 'aseo',
      '616dc38b1abad918cd4ba0eb': 'bebidas',
      '616dc3771abad918cd4ba0e5': 'carnicos',
      '616dc4bc1abad918cd4ba0fe': 'especializado',
      '616dc4051abad918cd4ba0ee': 'frutas',
      '616dc41a1abad918cd4ba0f4': 'licores',
      '616dc3801abad918cd4ba0e8': 'lacteos',
      '616dc40f1abad918cd4ba0f1': 'maquinaria',
    };
  }
  ngOnInit(){
    
  }
  /**
   * Si aún no se ha seleccionado ningún punto de entrega, se llama al
   * modal de selección de punto de entrega. Mientras tanto, se traen
   */
  ngOnChanges() {
    console.log('buscados...')



    /*if (this.auth.punto_seleccionado == undefined) {
      this.modalCarga?.close();
      this.selecionarPunto();
    } else {
      this.punto_entrega = this.auth.punto_seleccionado;
      this.fetchDistribuidores();
    }
    this.fetchCategorias();
    this.handleClickCategoria(false);
    this.iconosCategorias = {
      '616dc4681abad918cd4ba0fb': 'aseo',
      '616dc38b1abad918cd4ba0eb': 'bebidas',
      '616dc3771abad918cd4ba0e5': 'carnicos',
      '616dc4bc1abad918cd4ba0fe': 'especializado',
      '616dc4051abad918cd4ba0ee': 'frutas',
      '616dc41a1abad918cd4ba0f4': 'licores',
      '616dc3801abad918cd4ba0e8': 'lacteos',
      '616dc40f1abad918cd4ba0f1': 'maquinaria',
    };*/
  }

  async fetchDistribuidores() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    try {
      await this.rest
        .getJWT('distribuidorAllCat')
        .toPromise()
        .then((resp_1: any) => {
          resp_1 = resp_1.filter(
            (option: any) => option.solicitud_vinculacion === 'Aprobado' || option.solicitud_vinculacion === 'Aceptado'
          );
          /**Recupera todos los distribuidores*/
          this.distribuidorService.getDistribuidoresVinculadosPunto().subscribe((resp_2: any) => {
            /*Recupera los estados de vinculación de los distribuidores según el seleccionado punto de entrega */
            const filtro_dist_vinculados_punto = resp_2.filter(
              (option: any) => option.punto_entrega == this.auth.punto_seleccionado?._id
            );
            /********** DISTRIBUIDORES NO ASOCIADOS AL PUNTO *********/
            /**Filtra los distribuidores NO asociados al punto de entrega*/
            const my_dist_no_asociados = resp_1.filter((el: any) => {
              return !filtro_dist_vinculados_punto.some((f: any) => {
                if (f.distribuidor === null) {
                  return false;
                }
                return f.distribuidor._id == el._id;
              });
            });
            /**
             * Filtra los distribuidores NO asociadoes al punto pero que estan
             * dentro de su area de cobertura para mostrarlos en el DOM
             */
            this.filtrarPorCobertura(my_dist_no_asociados);
            this.modalCarga?.close();
            /************ DISTRIBUIDORES ASOCIADOS AL PUNTO ***********/
            /**Separa los distribuidores segun su estado */
            this.distVinculadosAprobados = this.fetchDistribuidoresAsociadosPorEstado(
              resp_1,
              filtro_dist_vinculados_punto,
              'Aprobado'
            );
            /** Agrega la cantidad de pedidos de un distribuidor para un punto */
            for (const iterator of this.distVinculadosAprobados) {
              this.rest
                .getJWT(`pedido_punto_dist/${this.auth.punto_seleccionado?._id}/${iterator._id}`)
                .toPromise()
                .then((producto: any) => {
                  iterator.pedidos = producto?.pedidos.length;
                });
            }
            this.distVinculadosPendientes = this.fetchDistribuidoresAsociadosPorEstado(
              resp_1,
              filtro_dist_vinculados_punto,
              'Pendiente'
            );
          });
          this.modalCarga?.close();
        });
    } catch (error) {
      this.modalCarga.close();
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
        this.router.navigate(['/inicio']);
      };
    }
  }

  /**
   * Filtra los distribuidores asociados al punto segun su estado
   * @param todos_distribuidores Array de objetos con todos los distribuidores
   * @param array_distribuidores Array de objetos con los distribuidores del punto de entrega
   * @param estado String con el estado que se desea filtrar
   * @returns Array de objetos con los distribuidores del punto de entrega y con el estado solicitado
   */
  fetchDistribuidoresAsociadosPorEstado(todos_distribuidores: any, array_distribuidores: any, estado: any) {
    const my_dist_asociados_por_estado = array_distribuidores.filter((obj: any) => obj.estado == estado);
    return todos_distribuidores.filter((el: any) => {
      return my_dist_asociados_por_estado.some((f: any) => {
        if (f.distribuidor === null) {
          return false;
        }
        return f.distribuidor._id == el._id;
      });
    });
  }

  /**
   * Filtra los distribuidores segun la cobertura del punto de entrega
   * @param distribuidores Array de objetos con todos los distribuidores
   * @returns Array de objetos con los distribuidores que estan dentro de la zona de cobertura del punto de entrega
   */
  async filtrarPorCobertura(distribuidores: any) {
    // Se almacenan los id de los distribuidores no vinculados
    const noVinculadosId = [];
    for (const distId of distribuidores) {
      noVinculadosId.push(distId._id);
    }
    this.distribuidores = [];
    this.distribuidoresCoordenadaPunto(noVinculadosId);
  }

  //Obtener los distribuidores segun la coordenada del punto de entrega
  distribuidoresCoordenadaPunto(distribuidoresIds: any) {
    const body = {
      noVinculados: distribuidoresIds,
    };
    this.rest
      .postJWT(`distribuidor/no_vinculados_punto/${this.auth.punto_seleccionado?._id}`, body)
      .toPromise()
      .then((distribuidores: any) => {
        this.distribuidores = distribuidores;
        /**Guardamos un array con los productos para las funcionalidades del filtro por categorias*/
        this.buffer_distribuidores = this.distribuidores;
        /** Una vez recuperada la data habilita el buscador de autocompletado */
        this.autoCompletadoBusqueda();
      });
  }

  /**
   * Lanza un modal para seleccionar el punto de entrega sobre el que se
   * van a gestionar las solicitudes
   */
  selecionarPunto() {
    this.modalCarga?.close();
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'modal-selecionar-punto',
    };
    const modalRef = this.modalService.open(SeleccionarPuntoEntregaComponent, ngbModalOptions);
    modalRef.componentInstance.callback = () => {
      this.punto_entrega = this.auth.punto_seleccionado;
      this.locallStorage.setItem<any>('punto_entrega_seleccionado', this.punto_entrega);
      this.fetchDistribuidores();
      this.fetchCategorias();
    };
  }

  /**
   * Manda una solicitud de vinculación entre el punto de entrega y el
   * distribuidor cuyo índice entra por parámetro
   * @param index La posición en el arreglo de distribuidores del distribuidor a vincular
   */
  solicitarVinculacion(index: number) {
    if (this.distribuidores[index].flag_vinculacion == true) {
      const dist_cliente_preaproados = this.distribuidores[index].clientes_preaprobados;
      // Evita se vuelva a activar la vinculacion creando doble objeto
      this.distribuidores[index].flag_vinculacion = false;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const id_punto: string = this.auth.punto_seleccionado!._id!;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const id_dist: string = this.distribuidores[index]._id!;
      const id_trab: string = this.auth.user?._id || '';
      const obj_post: any = {
        estado: 'Pendiente',
        convenio: false,
        cartera: false,
        punto_entrega: id_punto,
        distribuidor: id_dist,
        vendedor: id_trab,
        pazysalvo: true,
      };
      for (const iterator of dist_cliente_preaproados) {
        if (iterator == this.userInfo.user_horeca.nit) {
          obj_post.estado = 'Aprobado';
        }
      }
      this.rest
        .postJWT('distribuidores_vinculados', obj_post)
        .toPromise()
        .then(() => {
          const ngbModalOptions: NgbModalOptions = {
            //Evita que al hacer click por fuera se cierre el modal
            backdrop: 'static',
            keyboard: false,
          };
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          if (obj_post.estado === 'Aprobado') {
            modalRef.componentInstance.msg = 'El distribuidor ha aprobado tu solicitud de vinculación.';
          } else {
            modalRef.componentInstance.msg = 'Se envió una solicitud de vinculación al distribuidor seleccionado.';
          }
          modalRef.componentInstance.btn_msg = 'Listo';
          this.fetchDistribuidores();
        })
        .catch((err) => {
          const ngbModalOptions: NgbModalOptions = {
            //Evita que al hacer click por fuera se cierre el modal
            backdrop: 'static',
            keyboard: false,
          };
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
          modalRef.componentInstance.btn_msg = 'Volver';
        });
    }
  }

  /**
   * Este metodo tiene como objeto autocompletar la busqueda del usuario
   */
  autoCompletadoBusqueda() {
    this.filteredOptions = this.mySearch.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.nombre)),
      map((nombre) => (nombre ? this._filter(nombre) : this.distribuidores.slice()))
    );
  }
  displayFn(user: any) {
    return user ? user.nombre : undefined;
  }
  returnFn(user: any) {
    return user ? user.value : undefined;
  }
  itemDisplayFn(item: any) {
    return item ? item.name : '';
  }
  private _filter(nombre: string) {
    const filterValue = nombre.toLowerCase();
    return this.distribuidores.filter((option: any) => option.nombre.toLowerCase().indexOf(filterValue) === 0);
  }

  /**
   * Limpiar búsqueda
   */
  clearSearch() {
    this.searchTerm = '';
  }

  /**
   * Estos metodos tienen como objeto ver el detalle de los distribuidores
   * El primer metodo es para la lista completa de distribuidores
   * El segundo metodo es para los distribuidores aprobados
   */
  verDetalleDistribuidor(event: any) {
    if (event.option.value._id !== undefined) {
      const filtrado = event.option.value._id;
      this.router.navigate(['/distribuidores', filtrado]);
    }
  }

  verDetalleDistribuidorAprobado(event: any) {
    const filtrado = event.distribuidor._id;
    this.router.navigate(['/distribuidores', filtrado]);
  }

  /**
   * Llama al modal de filtrado
   */
  onFiltrar() {
    const modalRef = this.modalService.open(FiltroDistribuidoresComponent, {
      centered: true,
      windowClass: 'modal-filtro',
    });
    modalRef.componentInstance.ranking = this.ranking;
    modalRef.componentInstance.tiempo_entrega = this.tiempo_entrega;
    modalRef.componentInstance.todos_filtros_desactivados = this.todos_filtros_desactivados;
    modalRef.componentInstance.categorias_estado = this.categorias_estado;
    modalRef.componentInstance.distribuidores_componente_padre = this.buffer_distribuidores;
    modalRef.componentInstance.punto_entrega = this.punto_entrega;
    modalRef.componentInstance.callback = (
      rank: number,
      tiempos_entrega: string,
      todos_filtros_desactivados: boolean,
      categorias_estado: boolean[],
      distribuidores_componente_padre: any[]
    ) => {
      this.ranking = rank;
      this.tiempo_entrega = tiempos_entrega;
      this.todos_filtros_desactivados = todos_filtros_desactivados;
      this.categorias_estado = categorias_estado;
      this.distribuidores = distribuidores_componente_padre;
    };
  }

  /**
   * Trae los datos de las categorías de productos
   */
  async fetchCategorias() {
    const resp: any = await this.rest.getJWT('categoria_distribuidor').toPromise();
    this.categorias_filtro_distribuidores = resp.data;
    this.categorias_estado = [];
    this.categorias_filtro_distribuidores.forEach(() => {
      this.categorias_estado.push(false);
    });
  }

  /**
   * Revisa si la categoría seleccionada ya existe en el arreglo de
   * categorías seleccionada. Si no existe, la agrega. Si ya existe,
   * la elimina. Al final, vuelve a armar el arreglo de lineas seleccionadas;
   * @param selectedCat La categoría seleccionada
   * @param i La posición de la categoría en el arreglo de todas las categorías
   */
  handleClickCategoria(filtro_todos: boolean) {
    /** Reinicia el array de distribuidores con todas las opciones sin filtro */
    this.distribuidores = this.buffer_distribuidores;
    /********************** Verifica si existen filtros aplciados *********************/
    /** 0 NO hay filtro | >0 SI hay filtro */
    let is_filtro_aplicado = 0;
    for (const cat_estado of this.categorias_estado) {
      cat_estado == true ? (is_filtro_aplicado += 1) : (is_filtro_aplicado += 0);
    }
    /** si el input es -1 (presionó filtro "Todos") */
    if (filtro_todos === true || is_filtro_aplicado === 0) {
      /**************************** NO hay filtros aplciados ***************************/
      /** Reinicia los botonoes y booleanos */
      this.todos_filtros_desactivados = true;
      this.categorias_estado = this.categorias_estado.map((element: boolean) => (element = false));
    } else {
      this.todos_filtros_desactivados = false;
      /**************************** SI hay filtros aplciados ***************************/
      /** Crea un array con las categorías de filtro seleccionadas */
      const categorias_seleccionadas: any = [];
      for (let index = 0; index < this.categorias_estado.length; index++) {
        if (this.categorias_estado[index] === true) {
          categorias_seleccionadas.push(this.categorias_filtro_distribuidores[index]);
        }
      }
      /**
       * Filtra de todos los distribuidores posibles los que fueron
       * seleccionados en base al array de categorias seleccionadas
       */
      this.distribuidores = this.distribuidores.filter((el: any) => {
        return categorias_seleccionadas.some((f: any) => {
          return f.nombre == el.tipo;
        });
      });
    }
  }
}
