import { Component, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import {
  faChevronRight,
  faMapMarkerAlt,
  faSearch,
  faStoreAlt,
  faTimesCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { EstablecimientosService } from '../../services/establecimientos/establecimientos.service';
import { DistribuidorService } from '../../services/distribuidor/distribuidor.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LocalDataSource } from 'ng2-smart-table';
import { PreAprobarClienteComponent } from 'src/app/modal/pre-aprobar-cliente/pre-aprobar-cliente.component';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { Router } from '@angular/router';
import { CargarClientesExcelComponent } from 'src/app/modal/cargar-clientes-excel/cargar-clientes-excel.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
import { RestService } from 'src/app/services/rest/rest.service';
@Component({
  selector: 'app-clientes-nuevos',
  templateUrl: './clientes-nuevos.component.html',
  styleUrls: ['./clientes-nuevos.component.css']
})
export class ClientesNuevosComponent implements OnInit {
 /** Referencias a íconos FontAwesome para la UI */
 faSearch = faSearch;
 faTimesCircle = faTimesCircle;
 faChevronRight = faChevronRight;
 faStoreAlt = faStoreAlt;
 faMapMarkerAlt = faMapMarkerAlt;
 faTrash = faTrash;
 /** Extraer el id del distribuidor logeado mediante localstorage */
 userInfo = JSON.parse(localStorage.getItem('auth') || '');
 idUserInfo = this.userInfo['user_distribuidor']['_id'];
 /** Nombre del cliente a buscar */
 nombre_cliente = '';
 /**Variable con datos del cliente */
 estado: any;
 idEstablecimiento: any;
 idUserHoreca: any;
 puntosEntrega: any;
 puntoEntrega: any;
 punto: any;
 nombreEstablecimiento: any;
 distribuidores: any;
 idPuntoEntrega: any;
 pedidos: any;
 trabajador: any;
 pedido: any;
 clientes: any;
 pedidoByPunto: any;
 tipo_dist_vinculado: any;
 /** Datos y configuracion de la tabla */
 public settings: any = {};
 public data: any = [];
 cantidadMaxima: any = 0;
 totalPaginas: number = 1;
 carga_info: boolean = false;
 source: LocalDataSource = new LocalDataSource();
 public cantidadClientes: number = 0;
 public paginaActual: number = 1; // Página actual
 public cantidadPorPagina: number = 10; // Cantidad de registros por página
 totalItems = 100; // Número total de registros
 itemsPerPage = 10; // Registros por página
 currentPage = 1; // Página actual
 loading = false
 totalClientes: any;
 idDistribuidor: any
 constructor(
   private authService: AuthService,
   private establecimientosSerivce: EstablecimientosService,
   private distribuidorService: DistribuidorService,
   private clientService: ClientsService,
   private rest: RestService,
   private currency: CurrencyPipe,
   private modalService: NgbModal,
   private router: Router
 ) {
   this.configSmartTable();
 }

 ngOnInit(): void {
   this.idDistribuidor =this.authService.user_distribuidor?._id;
   this.getDistribuidoresVinculados(this.paginaActual);
 }

 /** Trae el data de los distribuidores para vincular*/
 getDistribuidoresVinculados(page: any) {
   this.loading = true;
   this.distribuidorService.getDistribuidoresVinculadosAPiNuevos(page, this.idDistribuidor).subscribe((data) => {
     this.carga_info = true;
     this.source.load(data.vinculaciones);  // Carga los datos en la tabla
     this.source.refresh(); // Refresca el source para actualizar la tabla
     this.loading = false;

   });
 }
 

 /**
  * Configura la tabla
  */
 private configSmartTable(): void {
   this.settings = {
    pager: {
      display: true,
      perPage: 50, // 10 elementos por página
      // Aquí puedes agregar más configuraciones si es necesario
      showFirstLast: true, // Muestra los botones de primera y última página
      showPrevNext: true, // Muestra los botones de siguiente y anterior
      align: 'center', // Alineación del paginador
    },
     actions: {
       columnTitle: 'Acción',
       add: false,
       edit: false,
       delete: false,
       custom: [
         {
           name: 'ver',
           title: 'Ver',
         },
         {
           name: 'delete',
           title: 'Cancelar',
         },
       ],
     },
     edit: {
       editButtonContent: 'Editar',
       saveButtonContent: 'Guardar',
       cancelButtonContent: 'Cancelar',
     },
     hideSubHeader: false,
     noDataMessage: 'NO TIENES CLIENTES O AÚN NO TIENES CLIENTES VINCULADOS',
     columns: {
       estado_vinculacion:{
         title: 'Estado de vinculación',
         type: 'string',
       },
       tipo_negocio:{
         title: 'Tipo de negocio',
         type: 'string',
         valuePrepareFunction: (value: any) => value?.[0] || 'N/A',
       },
       nombre:{
         title: 'Establecimiento',
         type: 'string',
         valuePrepareFunction: (value: any) => value?.[0] || 'N/A',
       },
       tipo_persona:{
         title: 'Tipo de persona',
         type: 'string',
         valuePrepareFunction: (value: any) => value?.[0] || 'N/A',
       },
       nit:{
         title: 'NIT/CC/CE',
         type: 'string',
         valuePrepareFunction: (value: any) => value?.[0] || 'N/A',
       },
       razon_social:{
         title: 'Razón social',
         type: 'string',
         valuePrepareFunction: (value: any) => value?.[0] || 'N/A',
       },
       emailPropietario:{
         title: 'Email (propietario)',
         type: 'string',
         valuePrepareFunction: (value: any) => value?.[0] || 'N/A',
       },
       telPropietario:{
         title: 'Teléfono (propietario)',
         type: 'string',
         valuePrepareFunction: (value: any) => value?.[0] || 'N/A',
       },
       pais: {
         title: 'País',
         type: 'string',
       },
       departamento: {
         title: 'Departamento',
         type: 'string',
       },
       ciudad: {
         title: 'Ciudad',
         type: 'string',
       },
       punto_entrega:{
         title: 'Punto de entrega',
         type: 'string',
       },
       
       direccion: {
         title: 'Dirección',
         type: 'string',
       },
       sillas: {
         title: 'Sillas',
         type: 'number',
       },
       precio_especial:{
         title: 'Precio especial',
       },
       domicilio:{
         title: 'Domicilio',
       },
       estadoCartera:{
         title: 'Estado de cartera',
       },
       nombreEncargado:{
         title: 'Nombre del encargado del punto',
       },
       emailEncargado:{
         title: 'Email del encargado',
       },
       telefonoEncargado:{
         title: 'Teléfono móvil del encargado',
       },
       totalPedidos3Meses:{
         title: '#pedidos (últ. 3 meses)',
         valuePrepareFunction: (value: any) => value?.[0] || '0',
       },
       totalPedidos3MesesCompra:{
         title: 'Valor de pedidos (últ. 3 meses COP)',
         valuePrepareFunction: (value: any) => value?.[0] || '0',

       },
       totalProductos3Meses:{
         title: '#productos (últ. 3 meses)',
         valuePrepareFunction: (value: any) => value?.[0] || '0',

       }
     },
   };
 }
 public async onCustom(event: any): Promise<void> {
   // eslint-disable-next-line prefer-const
   let validador = await this.validatePermissions();
   if (validador) {
     if (event.action == 'ver') {
       this.router.navigate(['/detalle-cliente/' + event.data._id]);
     }
   }
 }
 async validatePermissions() {
   // eslint-disable-next-line prefer-const
   let blockUser = await this.authService.validatePermissionsUser();
   return blockUser;
 }
 /**
  * Lanza el modal que se encarga de recibir el archivo
  * de Excel con los clientes,
  * y toda la lógica necesaria para la creación de estos clientes preaprobados
  */
 async cargarClientesDesdeExcel() {
   // eslint-disable-next-line prefer-const
   let validador = await this.validatePermissions();
   if (validador) {
     const ngbModalOptions: NgbModalOptions = {
       //Evita que al hacer click por fuera se cierre el modal
       backdrop: 'static',
       keyboard: false,
       centered: true,
     };

     this.modalService.open(CargarClientesExcelComponent, ngbModalOptions);
   }
 }
}

