<app-header-horeca></app-header-horeca>
<!--Btn carrito flotante-->
<div class="carrito-flotante" [routerLink]="['/carrito']" *ngIf="estado_vinculacion_punto_distribuidor === false">
  <span class="badge-carrito badge badge-pill badge-light">{{ productcounter }}</span>
  <span class="nav-link text-header flex">Carrito - {{ totalPrice | currency: '$ ':'symbol':'1.0-0' }}</span>
  <fa-icon [icon]="faShoppingCart"></fa-icon>
</div>
<div class="d-flex justify-content-center">
  <section class="main-container p-5 w-100">
    <!--Volver-->
    <div class="volver-container d-flex flex-row w-100 pb-4">
      <div class="col-2 p-0" (click)="volver()">
        <button class="boton-volver">
          <fa-icon [icon]="faChevronLeft"></fa-icon>
          Volver
        </button>
      </div>
      <div class="col-9 d-flex align-items-center" *ngIf="distribuidor?.distribuidor?.nombre != undefined">
        <h2 class="d-flex align-items-center p-0">{{ distribuidor?.distribuidor?.nombre }}</h2>
      </div>
    </div>
        <!--Detalles del distribuidor y la vinculación-->
        <div class="info-detalles-container">
            <div class="info-aprobado">
                <fa-icon [icon]="faCircle" [styles]="{ color: '#41403E' }" id="fa-icon" *ngIf="vinculacion == null">
                </fa-icon>
                <fa-icon [icon]="faCircle" [styles]="{ color: '#E8E8E8' }" id="fa-icon" *ngIf="vinculacion == 'Pendiente'">
                </fa-icon>
                <fa-icon [icon]="faCircle" [styles]="{ color: '#97AE22' }" id="fa-icon" *ngIf="vinculacion == 'Aprobado'">
                </fa-icon>
                <fa-icon [icon]="faCircle" [styles]="{ color: '#D38989' }" id="fa-icon" *ngIf="vinculacion == 'Cancelado'">
                </fa-icon>
                <fa-icon [icon]="faCircle" [styles]="{ color: '#D38989' }" id="fa-icon" *ngIf="vinculacion == 'Rechazado'">
                </fa-icon>
                <span>{{ vinculacion || 'Sin vinculación' }}</span>
            </div>
            <div class="info-tiempo-entrega">
                <img src="../../assets/img/icons/icon-bus.png" alt="icono de un bus" srcset="" />
                <span>Tiempos de entrega: {{ distribuidor?.distribuidor?.tiempo_entrega }}</span>
            </div>
            <div class="info-ciudad">
                <img src="../../assets/img/icons/icon-pin-map.png" alt="icono de un pin para señalar un punto en un mapa"
                    srcset="" />
                <span>{{ distribuidor?.distribuidor?.ciudad }}</span>
            </div>
            <div class="info-convenio">
                <!-- <div>
                    <span>$Convenio</span>
                </div> -->
            </div>
            <div class="info-logo">
                <img src='{{distribuidor?.distribuidor?.logo  || distribuidor_placeholder}}' alt="logo del distribuidor" srcset="" />
            </div>
            <div class="info-tipo d-flex w-100 flex-row justify-content-between align-items-center">
                <span>
                    {{ distribuidor?.distribuidor?.tipo }}
                </span>
            </div>
            <div class="info-pedido-minimo d-flex flex-row justify-content-between">
                <span>
                    Pedido mínimo:
                    {{ distribuidor?.distribuidor?.valor_minimo_pedido | currency: '$ ':'symbol':'1.0-0'}}
                </span>
                <span>
                    Método de pago: {{distribuidor?.distribuidor?.metodo_pago}}
                </span>
            </div>
            <div class="info-descripcion">
                <p>{{distribuidor?.distribuidor?.descripcion }}</p>
            </div>
           
            <!--Calificaciones-->
            <div class="calificacion info-calificacion-abastecimiento">
                <div>
                    <fa-icon [icon]="faStar" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
                    <span>{{ distribuidor?.distribuidor?.ranking | number:'1.0-2' }}</span>
                </div>
                <span class="pl-2">Abastecimiento</span>
            </div>
            <div class="calificacion info-calificacion-precio">
                <div>
                    <fa-icon [icon]="faStar" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
                    <span>{{ distribuidor?.distribuidor?.ranking | number:'1.0-2' }}</span>
                </div>
                <span class="pl-2">Precio</span>
            </div>
            <div class="calificacion info-calificacion-puntualidad">
                <div>
                    <fa-icon [icon]="faStar" [styles]="{ color: '#41403E' }" id="fa-icon"></fa-icon>
                    <span>{{ distribuidor?.distribuidor?.ranking | number:'1.0-2' }}</span>
                </div>
                <span class="pl-2">Puntualidad</span>
            </div>
         
            <div class="info-vinculacion">
                
                <button class="btn-solicitar-2" [ngClass]="{'btn-solicitar-disabled': vinculacion !== null}"
                    (click)="solicitarVinculacion()" [disabled]="vinculacion !== null">
                    Solicitar vinculación
                </button>
            </div>
        </div>
    <!-- Barras de navegación-->
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills mt-3">
      <!-- Catálogo -->
      <li [ngbNavItem]="1" class="text-center p-0">
        <ng-template ngbNavContent>
          <div class="catalogo" *ngIf="distribuidor !== null">
            <!--Saldos y promociones-->
            <div
              class="saldos-promociones p-0 rounded"
              (click)="saldos_activo = !saldos_activo"
              [ngClass]="{ 'saldos-promociones-activo': saldos_activo === true }">
              <div
                class="
                  saldos-imagen
                  col-4
                  p-0
                  d-flex
                  justify-content-center
                  align-items-center
                  overflow-hidden
                  rounded-left
                "></div>
              <div class="col-8 p-0 pl-3 card-body align-items-center rounded">
                <div class="content-badge w-100">
                  <div class="badge-promo">{{totalSaldosPromociones}}</div>
                </div>
                <p class="m-0 main-title text-left pl-2 texto-saldo-promo">Saldos y promociones</p>
              </div>
            </div>
            <!--Categorías-->
            <div class="categorias rounded max-h">
              <h6 class="mb-0">Categorías</h6>
              <div class="pt-0">
                <div class="d-flex flex-column align-items-start">
                  <div class="cat-container">
                    <div class="cat-icon-container">
                      <img
                        *ngIf="!categorias_productos_estados_todos"
                        src="assets/iconos-org/todos.png"
                        style="width: 60%"
                        alt="" />
                      <img
                        *ngIf="categorias_productos_estados_todos"
                        src="assets/iconos-org/todos-selected.png"
                        style="width: 60%"
                        alt="" />
                    </div>
                    <div class="cat-name-container">
                      <span
                        (click)="
                          saldos_activo === false &&
                            (categorias_productos_estados_todos = !categorias_productos_estados_todos)
                        "
                        (click)="saldos_activo === false && handleClickCategoria(true)"
                        [style]="categorias_productos_estados_todos ? 'color: #8e6ff7' : ''">
                        Todos
                      </span>
                    </div>
                  </div>
                  <div  *ngIf="!saldos_activo">
                    <div *ngFor="let cat of categorias_productos; index as i" class="w-100 cat-container">
                        <span *ngIf="!saldos_activo">
                          <div class="cat-icon-container">
                            <div *ngIf="cat.logoOn">
                              <img
                                *ngIf="categorias_productos_estados[i]"
                                src="{{ cat.logoOn }}"
                                style="width: 60%"
                                alt="" />
                              <img
                                *ngIf="!categorias_productos_estados[i]"
                                src="{{ cat.logoOff || cat.logoOn }}"
                                style="width: 60%"
                                alt="" />
                            </div>
                            <div *ngIf="!cat.logoOn">
                              <img
                                *ngIf="!categorias_productos_estados[i]"
                                src="assets/iconos-org/todos.png"
                                style="width: 60%"
                                alt="" />
                              <img
                                *ngIf="categorias_productos_estados[i]"
                                src="assets/iconos-org/todos-selected.png"
                                style="width: 60%"
                                alt="" />
                            </div>
                          </div>
                          <div class="cat-name-container">
                            <span
                              *ngIf="saldos_activo === false"
                              (click)="categorias_productos_estados[i] = !categorias_productos_estados[i]"
                              (click)="handleClickCategoria(false)"
                              [style]="categorias_productos_estados[i] ? 'color: #8e6ff7' : ''">
                              {{ cat.nombre }}
                            </span>
                          </div>
                        </span>
                      </div>
                  </div>
                 
                </div>
              </div>
            </div>
            <!--Buscador-->
            <div class="barra-busqueda rounded">
              <div class="d-flex flex-row autocompletado-busqueda px-0 h-100 w-100">
                <button class="col-2 col-lg-1 col-sm-0 p-0 boton-buscar d-flex justify-content-center">
                  <fa-icon
                    class="fa-icon-search m-0"
                    [icon]="faSearch"
                    size="lg"
                    [styles]="{ color: '#41403E' }"></fa-icon>
                </button>
                <form class="example-form col-8 col-sm-8 col-md-10 pr-3">
                  <mat-form-field class="example-full-width">
                    <input
                      #escogido
                      type="text"
                      placeholder="Buscar por nombre del producto / Cod Dist. / Cod Org"
                      aria-label="Buscar distribuidor"
                      [(ngModel)]="stBusq"
                      [ngModelOptions]="{standalone: true}"
                      class="w-100 h-100 input-busqueda pr-0" />
                  
                  </mat-form-field>
                </form>
                <div class="col-2 col-lg-1 col-sm-0 p-0 boton-buscar d-flex justify-content-center">
                  <button class="orderBot" *ngIf="ordenActual === 'desc'" (click)="ordenarPorNombre('asc'); ordenActual = 'asc'">Z-A</button>
                  <button class="orderBot" *ngIf="ordenActual === 'asc'" (click)="ordenarPorNombre('desc'); ordenActual = 'desc'">A-Z</button>
                </div>
              </div>
            </div>
            <!--Punto de entrega-->
            <div class="punto-entrega-container rounded overflow-hidden p-0 pr-3" style="display: none;">
              <div class="punto-entrega-icono col-1">
                <img
                  src="../../assets/img/icons/icon-pin-map.png"
                  alt="icono de un pin para señalar un sitio en un mapa"
                  srcset="" />
              </div>
              <span class="text-left punto-entrega-texto col-6 col-sm-7 col-md-8 p-0" id="point">
                <span class="texto-novisible">Punto de entrega:</span>
                <span>{{ punto_entrega?.nombre || 'Selecciona un punto' }}</span>
              </span>
              <span (click)="selecionarPunto()" class="punto-entrega-cambiar col-5 col-sm-4 col-md-3 text-center m-0">
                Cambiar
              </span>
            </div>
            <!--Líneas de producto-->
            <div class="lineas-productos pb-2">
              <span class="pb-3">Líneas de producto</span>
              <!-- Lineas catalogo general -->
              <div class="categorias-lineas" *ngIf="saldos_activo === false">
                <span
                  (click)="lineas_productos_estado_todos = !lineas_productos_estado_todos"
                  (click)="handleClickLineaProducto(true)"
                  [ngClass]="{ 'categorias-lineas-selected': lineas_productos_estado_todos === true }">
                  Todos
                </span>
                <span
                  *ngFor="let linea of lineas_productos; index as i"
                  (click)="lineas_productos_estado[i] = !lineas_productos_estado[i]"
                  (click)="handleClickLineaProducto(false)"
                  [ngClass]="{ 'categorias-lineas-selected': lineas_productos_estado[i] === true }">
                  {{ linea.nombre }}
                </span>
              </div>
              <!-- Lineas catalogo saldos y promociones -->
              <div class="categorias-lineas" *ngIf="saldos_activo === true">
                <span
                  (click)="saldo_promocion_seleccionada = 'todos'"
                  (click)="filtrarSaldosPromociones('todos')"
                  [ngClass]="{ 'categorias-lineas-selected': saldo_promocion_seleccionada === 'todos' }">
                  Todos
                </span>
                <span
                  (click)="saldo_promocion_seleccionada = 'saldos'"
                  (click)="filtrarSaldosPromociones('saldos')"
                  [ngClass]="{ 'categorias-lineas-selected': saldo_promocion_seleccionada === 'saldos' }">
                  Saldos
                </span>
                <span
                  (click)="saldo_promocion_seleccionada = 'promocion'"
                  (click)="filtrarSaldosPromociones('promocion')"
                  [ngClass]="{ 'categorias-lineas-selected': saldo_promocion_seleccionada === 'promocion' }">
                  Promoción
                </span>
              </div>
            </div>
            <!--Productos-->
            <!-- Catalogo -->
            <div class="products-container" *ngIf="prods_filtrados && prods_filtrados.length > 0 && !saldos_activo">
              <div class="productos-contenedor">
                <div class="product-card" *ngFor="let producto of prods_filtrados; let i = index">
                  <!-- Detalles del producto -->
                  <div>
                    <span>
                      SKU Distribuidor:
                      <br />
                      {{ producto?.codigo_distribuidor_producto || '' }}
                    </span>
                  </div>
                  <div>
                    <div class="space state-header">
                      <span *ngIf="producto?.precios[0].inventario_unidad > 0">{{ producto?.precios[0].estado }}</span>
                      <span class="no-disponible-rojo" *ngIf="producto?.precios[0].inventario_unidad <= 0">
                        Agotado 
                      </span>
                      <span *ngIf="producto.mostrarPF" 
                      [ngClass]="{'puntosLargos': producto?.precios[0].puntos_ft_unidad > 1000}"
                      >
                        {{ producto?.precios[0].puntos_ft_unidad }} punto{{
                          producto?.precios[0].puntos_ft_unidad == 1 ? '' : 's'
                        }}
                        ft.
                      </span>
                      <span *ngIf="!producto.mostrarPF" 
                      [ngClass]="{'puntosLargos': producto?.precios[0].puntos_ft_unidad > 1000}"
                      >
                        0 ft.
                      </span>
                    </div>
                    <div
                      *ngIf="
                      producto?.prodDescuento && producto?.prodPorcentajeDesc > 0 
                      && producto?.descuentosEspeciales.length === 0
                      "
                      class="container-discount discount-indicator">
                      <span>-{{ producto.prodPorcentajeDesc }}%</span>
                    </div>
                    <div *ngIf="producto?.descuentosEspeciales[0]">
                      <div
                      *ngIf="producto?.descuentosEspeciales[0].operacionAplicada === 'descuento'
                      "
                      class="container-discount discount-indicator">
                      <span>-{{ producto?.descuentosEspeciales[0].aplicado }}%</span>
                    </div>
                    </div>
                    <div
                      *ngIf="producto?.prodPedido"
                      class="absolute-indicator order-indicator">
                      <img src="./assets/img/icons/box-open.png" alt="Icon box" title="Encargo bajo pedido">
                    </div>
                    <div
                      class="absolute-indicator bio-indicator"
                      *ngIf="producto?.prodBiodegradable">
                      <img src="./assets/img/icons/recycle.png" alt="Icon box" title="Producto biodegradable">
                    </div>
                    <div
                      class="container-img"
                      (click)="estado_vinculacion_punto_distribuidor && showAlertaEnCartera()"
                      [routerLink]="
                        estado_vinculacion_punto_distribuidor === false
                          ? ['/distribuidores', id, 'producto', producto?._id]
                          : null
                      ">
                      <img
                        src="{{ producto?.fotos[0] || product_placeholder }}"
                        alt="{{ producto?.nombre || 'Producto' }}" />
                    </div>
                    <span>{{ producto?.nombre }}</span>
                    
                    <div *ngIf="producto?.descuentosEspeciales.length > 0">
                      <!--<span style="font-size: 10px; color: red;">{{producto?.descuentosEspeciales[0].operacionApliada}}</span>-->
                      <div class="space" *ngIf="producto?.descuentosEspeciales[0].operacionApliada !== 'monto fijo' && producto?.descuentosEspeciales[0].operacionApliada !== 'incremento' ">
                        <span class="color-previous-price">Precio ant.</span>
                        <span class="color-previous-price previous-line">{{ producto?.descuentosEspeciales[0].precioAnterior | currency: 'COP':'symbol-narrow':'1.0-2' }}</span>
                      </div>
                      <div class="space">
                        <span>Precio und.</span>
                        <span class="price blockprice">
                          {{ (producto?.descuentosEspeciales[0].precioActual  | currency: 'COP':'symbol-narrow':'1.0-0') }}
                        </span>
                      </div>
                    </div>
                    <div class="space blockprice"  *ngIf="producto?.descuentosEspeciales.length > 0">
                      <span>Precio {{ producto?.precios[0].unidad_medida || 'und' }}</span>
                      <div  *ngIf="producto?.descuentosEspeciales.length > 0" style="    width: 70%;
                        text-align: right;">
                        <span class="blockprice">
                          {{ producto?.descuentosEspeciales[0].precioActual / producto?.precios[0].cantidad_medida | currency:
                            "$":"symbol":"1.2-2"}}
                        </span>
                      </div>
                      <div>

                      </div>
                    </div>
                    <div class="space blockprice"  *ngIf="producto?.descuentosEspeciales.length > 0" [ngClass]="{'separator-indicators': producto.prodPedido || producto.prodBiodegradable}">
                      <span>Caja x{{ producto?.precios[0].und_x_caja || 1 }}</span>
                      <div>
                        <span class="blockprice">{{  
                          producto?.descuentosEspeciales[0].precioActual * producto?.precios[0]?.und_x_caja
                          | currency: '$ ':'symbol':'1.0-0' }}</span>
                      </div>
                      
                      
                    </div>







                    <div *ngIf="producto?.descuentosEspeciales.length === 0">
                      <div class="space" *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc">
                        <span class="color-previous-price">Precio ant.</span>
                        <span class="color-previous-price previous-line blockprice">{{ producto?.precios[0]?.precio_unidad | currency: 'COP':'symbol-narrow':'1.0-2' }}</span>
                      </div>
                      <div class="space">
                        <span>Precio und.</span>
                        <span class="price blockprice" *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc">
                          {{ ((producto?.precios[0]?.precio_unidad - (producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100))) | currency: 'COP':'symbol-narrow':'1.0-0') }}
                        </span>
                        <span class="price blockprice" *ngIf="!producto?.prodDescuento">
                          {{ producto?.precios[0].precio_unidad | currency: '$ ':'symbol':'1.0-0' }}
                        </span>
                      </div>
                    </div>
                    

                    <div class="space"  *ngIf="producto?.descuentosEspeciales.length === 0">
                      <span>Precio {{ producto?.precios[0].unidad_medida || 'und' }}</span>
                      <div  *ngIf="producto?.descuentosEspeciales.length > 0">
                        <span class="blockprice">
                          {{
                            producto?.precios[0].precio_unidad / producto?.precios[0].cantidad_medida
                              | currency: '$ ':'symbol':'1.2-2'
                          }}
                        </span>
                      </div>
                     <div  *ngIf="producto?.descuentosEspeciales.length === 0">
                      <span *ngIf="!producto?.prodDescuento" class="blockprice">
                        {{
                          producto?.precios[0].precio_unidad / producto?.precios[0].cantidad_medida
                            | currency: '$ ':'symbol':'1.2-2'
                        }}
                      </span>
                      <span *ngIf="producto?.prodDescuento && producto?.prodPorcentajeDesc" class="blockprice">
                        {{
                          (producto?.precios[0]?.precio_unidad - (producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100))) / producto?.precios[0]?.cantidad_medida
                          | currency: 'COP':'symbol-narrow':'1.0-2'
                        }}
                      </span>
                     </div>
                    </div>
                  


                    <!-- Unidades pendientes para otra caja -->
                    <div class="w-100" *ngIf="producto?.precios[0].inventario_unidad > 0">
                      <p class="text-secondary w-100 text-center m-0 blockprice" >
                        {{
                          unidadesPendientesCaja(
                            producto?.precios[0].und_x_caja,
                            order.products[producto?.index - 1]?.unidad
                          )
                        }}
                        und pend. para otra caja
                      </p>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
            <!-- Saldos y promociones -->
            <div class="products-container saldos-container" *ngIf="saldos_activo">
              <div class="saldos-contenedor">
                <div *ngFor="let prod of productos_saldos_promos; index as index">
                  <!--Card saldo-->
                  <div class="card h-100" *ngIf="prod.saldos === true && prod.precios">
                    <div class="card-body card_body_prod h-100 pb-4">
                      <!--Header código-->
                      <div class="row header_codigo">
                        <div class="col-12 text-center">
                          <p>
                            Código saldo:
                            <br />
                            {{ prod.codigo_promo || 'No registra' }}
                          </p>
                        </div>
                      </div>
                      <span
                        class="
                          d-flex
                          flex-column
                          align-content-between
                          justify-content-between
                          contenedor-informacion-prom-saldo
                          h-100
                        ">
                        <span>
                          <!--Estado de productos y puntos-->
                          <div class="row row_data_prod">
                            <div class="col-12 text-left blockprice">
                              <p>{{ prod.precios[0].estado }}</p>
                            </div>
                          </div>
                          <!--Foto producto-->
                          <div class="row row_data_prod contenedor-imagenes">
                            <div
                              class="col-12 text-center cursorPointer"
                              [routerLink]="
                                estado_vinculacion_punto_distribuidor === false
                                  ? ['/distribuidores', id, 'producto', prod._id]
                                  : null
                              ">
                              <img
                                *ngIf="prod.fotos"
                                [src]="prod.fotos[0]"
                                class="img-prod"
                                [alt]="'Foto de ' + prod.nombre" />
                            </div>
                          </div>
                          <!--Nombre producto-->
                          <div class="row row_data_prod contenedor-nombre">
                            <div class="col-12 text-left">
                              <p class="m-0">{{ prod.nombre }}</p>
                            </div>
                          </div>
                          <!--Fecha de vencimiento-->
                          <div class="row row_data_prod">
                            <div class="col-12 text-left">
                              <p class="m-0 d-flex flex-row justify-content-between" *ngIf="prod.fecha_vencimiento">
                                Vence:
                                <span>{{ prod.fecha_vencimiento | date: 'shortDate' }}</span>
                              </p>
                            </div>
                          </div>
                          <!--Precio anterior-->
                          <div class="row row_data_prod" style="color: red">
                            <div class="col-7 text-left pr-0">
                              <p class="precio">Precio anterior</p>
                            </div>
                            <div class="col-5 text-right">
                              <h6 style="text-decoration: line-through blockprice">
                                {{ prod.precios[0].precio_unidad | currency: '$':'symbol':'1.0-0' }}
                              </h6>
                            </div>
                          </div>
                          <!--Precio por unidad-->
                          <div class="row row_data_prod">
                            <div class="col-6 text-left pr-0">
                              <p class="precio ">Precio und.</p>
                            </div>
                            <div class="col-6 text-right blockprice">
                              <h5>
                                {{ prod.precios[0].precio_descuento | currency: '$':'symbol':'1.0-0' }}
                              </h5>
                            </div>
                          </div>
                          <!-- Botones -->
                          <div class="mt-3 px-4 contenedor-botones-promo-saldo">

                            <!-- Total pedido en cajas -->
                            <div class="botones w-50 object-right">
                              <div class="suma" *ngIf="prod?.index && order.products[prod?.index - 1]?.unidad">
                                <span class="text-secondary text-center">Total cajas</span>
                                <div>
                                  <span class="cajas-container blockprice">
                                    {{
                                      totalCajasPedido(
                                        prod?.precios[0].und_x_caja,
                                        order.products[prod?.index - 1]?.unidad
                                      )
                                    }}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Unidades pendientes para otra caja -->
                          <div class="w-100" *ngIf="prod?.precios[0].inventario_unidad > 0">
                            <p class="text-secondary w-100 text-center m-0 blockprice">
                              {{
                                unidadesPendientesCaja(
                                  prod?.precios[0].und_x_caja,
                                  order.products[prod?.index - 1]?.unidad
                                )
                              }}
                              und pend. para otra caja
                            </p>
                          </div>
                          <!-- Alerta sin inventario -->
                         
                        </span>
                      </span>
                    </div>
                  </div>
                  <!--Card promoción-->
                  <div class="card card_prod h-100" *ngIf="prod.promocion === true && prod.precios">
                    <div class="card-body card_body_prod h-100 pb-4">
                      <!--Header código-->
                      <div class="row header_codigo">
                        <div class="col-12 text-center">
                          <p>
                            Código promoción:
                            <br />
                            {{ prod.codigo_promo || 'No registra' }}
                          </p>
                        </div>
                      </div>
                      <span
                        class="
                          d-flex
                          flex-column
                          align-content-between
                          justify-content-between
                          contenedor-informacion-prom-saldo
                          h-100
                        ">
                        <span>
                          <!--Estado de productos y puntos-->
                          <div class="row row_data_prod">
                            <div class="col-7 text-left">
                              <p>{{ prod.precios[0].estado }}</p>
                            </div>
                            <div class="col-5 text-right p-0">
                              <div class="promo-tag m-0">Promo</div>
                            </div>
                          </div>
                          <!--Foto producto-->
                          <span class="row w-100 contenedor-imagenes m-0">
                            <div class="row row_data_prod carouselCont">
                              <ngb-carousel class="w-100">
                                <ng-template ngbSlide *ngFor="let img_src of prod.fotos" class="w-100">
                                  <div class="w-100 col-12 d-flex justify-content-center">
                                    <img
                                      [src]="img_src"
                                      class="img-prod rounded cursorPointer"
                                      [alt]="'Foto de ' + prod.nombre"
                                      [routerLink]="
                                        estado_vinculacion_punto_distribuidor === false
                                          ? ['/distribuidores', id, 'producto', prod._id]
                                          : null
                                      " />
                                  </div>
                                </ng-template>
                              </ngb-carousel>
                            </div>
                          </span>
                          <!--Nombre producto-->
                          <div class="col-12 row_data_prod contenedor-nombre">
                            <div class="col-12 text-left">
                              <p>{{ prod.nombre }}</p>
                            </div>
                          </div>
                          <!--Precio por unidad-->
                          <div class="row row_data_prod">
                            <div class="col-6 text-left">
                              <p class="precio">Precio und.</p>
                            </div>
                            <div class="col-6 text-right blockprice">
                              <h5>
                                {{ prod.precios[0].precio_unidad | currency: '$':'symbol':'1.0-0' }}
                              </h5>
                            </div>
                          </div>
                          <br />
                          <br />
                          <br />
                          <br />
                          <!-- Botones -->
                         
                          <!-- Unidades pendientes para otra caja -->
                          <div class="w-100" *ngIf="prod?.precios[0].inventario_unidad > 0">
                            <p class="text-secondary w-100 text-center m-0 blockprice">
                              {{
                                unidadesPendientesCaja(
                                  prod?.precios[0].und_x_caja,
                                  order.products[prod?.index - 1]?.unidad
                                )
                              }}
                              und pend. para otra caja
                            </p>
                          </div>
                       
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--Estado vacío de productos filtrados-->
            <div class="products-container" *ngIf="!prods_filtrados || prods_filtrados.length == 0">
              <div class="row text-center">
                <div class="col-12">
                  <img
                    src="../../../assets/img/icon-warning-amarillo.png"
                    style="max-width: 200px; height: auto"
                    alt="ícono para indicar estado vacío" />
                </div>
                <div class="col-12">
                  <h5>No se encontraron productos que coincidan con tu búsqueda</h5>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
     
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </section>
</div>