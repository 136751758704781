import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service'; // Tu servicio de autenticación

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.token;

    if (token) {
      const authRequest = request.clone({
        setHeaders: {
          Authorization: `${token}`, // Incluye "Bearer " si es necesario
        },
      });
      return next.handle(authRequest);
    }

    return next.handle(request);
  }
}
