import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faChevronDown,
  faChevronLeft,
  faChevronUp,
  faCircle,
  faMapMarkerAlt,
  faPen,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { DistribuidorService } from '../../../services/distribuidor/distribuidor.service';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { InformacionEncargadoPuntoComponent } from 'src/app/modal/informacion-encargado-punto/informacion-encargado-punto.component';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { CarritoService } from 'src/app/services/carrito/carrito.service';
import { EditPedidoService } from 'src/app/services/editPedidos/edit-pedido.service';
@Component({
  selector: 'app-detalle-pedido-dist',
  templateUrl: './detalle-pedido-dist.component.html',
  styleUrls: ['./detalle-pedido-dist.component.css'],
})
export class DetallePedidoDistComponent implements OnInit {
  // Referencias a iconos FontAwesome para usar en la UI
  public faChevronLeft = faChevronLeft;
  public faCircle = faCircle;
  public faChevronDown = faChevronDown;
  public faChevronUp = faChevronUp;
  public faTimes = faTimes;
  public faMapMarkerAlt = faMapMarkerAlt;
  public faPen = faPen;
  // Información del pedido actual
  public id_pedido = '';
  public pedido: any = undefined;
  public codigos_descuento: any[] = [];
  public info_horeca: any = undefined;
  public info_punto: any = undefined;
  public info_vinculacion: any = undefined;
  public productos: any[] = [];
  public unidades_totales = 0;
  public trabajador_encargado = '';
  public validacionBotonEditar?: boolean = false;
  /**
   * Estado del pedido. Es necesario manejar uno crudo y uno traducido porque los
   * estados fueron pensados desde la perspectiva del establecimiento horeca,
   * entonces para el distribuidor es necesario hacer una equivalencia que tenga
   * sentido desde su perspectiva
   */
  public estado_crudo = '';
  public estado_traducido = '';
  public nuevo_estado = '';
  public nuevos_estados: string[] = [];
  public barra_estados = 1;
  public fecha_estado = '';
  public hora_estado = '';
  public validadorMensaje?: boolean = false;
  // Manejador de los paneles colapsables en la parte de precios
  public isCollapsed: boolean[] = [false, false];
  // trabajador horeca
  public trabajador_horeca: any;
  // Referencia al modal de carga
  public modalCarga?: NgbModalRef;
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'md',
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private rest: RestService,
    private auth: AuthService,
    private modalService: NgbModal,
    public localStorage: LocalStorageService,
    public authService: AuthService,
    private cartservice: CarritoService,
    private cartserviceEdit: EditPedidoService,
    private router: Router,
    private distribuidorService: DistribuidorService
  ) {}

  ngOnInit(): void {
    this.id_pedido = this.activatedRoute.snapshot.params.id;
    this.getInfoPedido();
  }

  async editarPedDist(){
    const productos = this.pedido.productos;
    const punto_entrega = this.pedido.punto_entrega?._id ? this.pedido.punto_entrega?._id : this.pedido.punto_entrega;
    const punto: any = await this.rest.getJWT(`punto_entrega/${punto_entrega}`).toPromise();
    let productos_data_completa: any = await this.rest
      .postJWT(`/producto/multiples_id`, productos)
      .toPromise();
    productos_data_completa = productos_data_completa.data;
    localStorage.setItem('punto_entrega_seleccionado', JSON.stringify(punto));
    for (const i in productos_data_completa) {
      const unidades = parseInt(productos[i].unidad.toString());
      const cajas = parseInt(productos[i].caja.toString());
      const unds_x_caja = productos_data_completa[i].precios[0].und_x_caja;
      // Se agrega el inv actual y las unidades ya pedidas al inv total
      const inventario = productos[i].unidad + productos_data_completa[i].precios[0].inventario_unidad;
      this.cartserviceEdit.addCart(productos_data_completa[i], unidades, cajas, unds_x_caja, inventario);
    }
    this.localStorage.setItem<any>('editar_pedido_curso', JSON.stringify(this.pedido));
        this.router.navigate(['edit-pedido-dist/'+this.id_pedido]);
  }
  /**
   * Recupera la información del pedido cuyo id se conoce por
   * parámetro de la ruta de este componente
   */
  async getInfoPedido() {
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    try {
      this.pedido = await this.rest.getJWT(`pedido/${this.id_pedido}`).toPromise();
      console.log('pedido', this.pedido)
      this.validarPedido(this.pedido);
      this.getInfoTrabajador(this.pedido.trabajador);
      this.getInfoProductos();
      this.getInfoHoreca();
      /*if (this.pedido.codigo_descuento.length > 0) {
        this.getInfoCodigos();
      }*/
      const resp_estado: any = await this.rest.getJWT(`pedido/ultimoTracking/${this.id_pedido}`).toPromise();
      this.estado_crudo = this.pedido.estado;
      // Validación editar pedido
      if (
        this.estado_crudo === 'Pendiente' ||
        this.estado_crudo === 'Aprobado Interno' ||
        this.estado_crudo === 'Aprobado Externo'
      ) {
        this.validacionBotonEditar = true;
      }
      const fecha_hora_cruda: string = resp_estado.createdAt;
      this.distribuidorMensajeValidacion();
      this.procesarEstado();
      this.processTimeDate(fecha_hora_cruda);
      this.modalCarga?.close();
    } catch (err) {
      this.modalCarga?.close();
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible recuperar la información de este pedido. Por favor intenta de nuevo más tarde.';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
        this.router.navigate(['pedidos-distribuidor']);
      };
    }
  }

  /**
   * Trae del back la información de los productos de este pedido
   */
  async getInfoProductos() {
    try {
      let prod_detalle: any;
      for (const prod_aux of this.pedido.productos) {
        //console.log('info pedidos....', prod_aux);
        prod_detalle = await this.rest.getJWT(`producto/${prod_aux.product}`).toPromise();
        prod_detalle['unidades_compradas'] = prod_aux.unidad;
        prod_detalle['cajas_compradas'] = prod_aux.caja;
        prod_detalle['porcentaje_descuento'] = prod_aux?.porcentaje_descuento ? true : false;
        prod_detalle['precio_caja'] = prod_aux?.precio_caja;
        prod_detalle['precio_original'] = prod_aux?.precio_original;
        prod_detalle['precioEspecial'] = prod_aux?.precioEspecial
        prod_detalle['puntos_ft_unidad'] = prod_aux?.puntos_ft_unidad
        
        this.productos.push(prod_detalle);
        this.unidades_totales += prod_aux.unidad;
      }
    } catch (err) {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible recuperar la información del detalle de los productos de este pedido. Se muestra la información del pedido sin esta información.';
      modalRef.componentInstance.btn_msg = 'Listo';
    }
  }

  /**
   * Trae del back la información del usuario horeca y del punto de entrega que hizo
   * el pedido para mostrar en la UI
   */
  async getInfoHoreca() {
    try {
      let puntoId = '';
      if (this.pedido.punto_entrega && this.pedido.punto_entrega?._id) {
        puntoId = this.pedido.punto_entrega._id;
      } else {
        puntoId = this.pedido.punto_entrega;
      }
      this.info_horeca = await this.rest.getJWT(`usuario_horeca/${this.pedido.usuario_horeca}`).toPromise();
      this.info_punto = await this.rest.getJWT(`punto_entrega/${puntoId}`).toPromise();
      this.getInfoVinculacion();
    } catch (err) {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible recuperar la información del cliente ni del punto de entrega. Por favor intenta de nuevo más tarde.';
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
        this.router.navigate(['pedidos-distribuidor']);
      };
    }
  }

  /**
   * Recupera la información del trabajador horeca
   */
  async getInfoTrabajador(_id: string) {
    try {
      this.trabajador_horeca = await this.rest.getJWT(`trabajador/${_id}`).toPromise();
    } catch (err) {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: true,
        size: 'md',
      };
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'No fue posible recuperar la información del trabajador del punto de entrega.';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }

  /**
   * Trae del back la información de la vinculación entre el punto de entrega cliente
   * y el distribuidor
   */
  async getInfoVinculacion() {
    try {
      const resp_vinc: any = await this.rest
        .getJWT(`distribuidores_vinculados_clientes_aprobados/${this.auth.user?.distribuidor}`)
        .toPromise();
      for (const vinc_aux of resp_vinc) {
          console.log('vinc_aux', vinc_aux);
        if (vinc_aux.punto_entrega._id == this.info_punto._id) {
          this.info_vinculacion = vinc_aux;
          /** Recupera el vendedor asignado por el distribuidor al punti */
          const vendedor: any = await this.rest.getJWT(`trabajador/${this.info_vinculacion.vendedor[0]}`).toPromise();
          this.trabajador_encargado = vendedor.nombres + ' ' + vendedor.apellidos;
          break;
        }
      }
    } catch (err) {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible recuperar la información de la vinculación con este cliente. Se muestra la información del pedido sin esta información.';
      modalRef.componentInstance.btn_msg = 'Listo';
    }
  }

  /**
   * Recupera la información de los códigos usados en el pedido
   */
  async getInfoCodigos() {
    try {
      for (const cod_aux of this.pedido.codigo_descuento) {
        this.codigos_descuento.push(await this.rest.getJWT(`codigos_generados/${cod_aux}`).toPromise());
      }
    } catch (err) {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg =
        'No fue posible recuperar la información de los códigos de descuento usados en este pedido. Se muestra la información del pedido sin esta información.';
      modalRef.componentInstance.btn_msg = 'Listo';
    }
  }

  /**
   * Valida desde el punto de vista del distribuidor si se puede mostrar el enlace al
   * chat dependiendo de que sea perfil vendedor en el distribuidores vinculados y el
   * estado actual del pedido
   */
  distribuidorMensajeValidacion() {
    const user = this.auth.user; //617ad0e67372c2097acc89c5
    this.distribuidorService
      .getFilteredDistribuidoresVinculadosAprobados(this.pedido.distribuidor)
      .subscribe((result) => {
        console.log('result', result)
        console.log('result', result)
        for(let item of result){
          console.log('vinculacion......', item)
          console.log('error:::', item.punto_entrega);
        }
        const vinculacion: any = result.find((x: any) => 
          x.punto_entrega?x.punto_entrega._id: '0'  == this.pedido.punto_entrega);
        if (vinculacion.vendedor.includes(user?._id)) {
          //Validación de Mensajes
          if (
            this.estado_crudo == 'Aprobado Interno' ||
            this.estado_crudo == 'Aprobado Externo' ||
            this.estado_crudo == 'Alistamiento' ||
            this.estado_crudo == 'Despachado' ||
            this.estado_crudo == 'Facturado'
          ) {
            this.validadorMensaje = true;
          }
        }
      });
  }

  /**
   * Toma el estado actual del pedido en su forma cruda
   * y asigna un equivalente que tenga sentido para el
   * distribuidor
   */
  procesarEstado() {
    switch (this.estado_crudo) {
      case 'Aprobado Interno':
        this.estado_traducido = 'Pendiente';
        this.barra_estados = 1;
        this.nuevos_estados = ['Aprobado'];
        break;

      case 'Aprobado Externo':
        this.estado_traducido = 'Aprobado';
        this.barra_estados = 2;
        this.nuevos_estados = ['Alistamiento'];
        break;

      case 'Alistamiento':
        this.estado_traducido = 'Alistamiento';
        this.barra_estados = 3;
        this.nuevos_estados = ['Despachado'];
        break;

      case 'Despachado':
        this.estado_traducido = 'Despachado';
        this.barra_estados = 4;
        this.nuevos_estados = ['Entregado'];
        break;

      case 'Entregado':
        this.estado_traducido = 'Entregado';
        this.barra_estados = 5;
        break;

      case 'Recibido':
        this.estado_traducido = 'Recibido';
        this.barra_estados = 5;
        break;

      case 'Calificado':
        this.estado_traducido = 'Calificado';
        this.barra_estados = 5;
        break;

      case 'Cancelado por horeca':
        this.estado_traducido = 'Cancelado por cliente';
        break;

      default:
        this.estado_traducido = this.estado_crudo;
        break;
    }
  }

  /**
   * Toma la fecha y hora que entra por parámetro y lo interpreta
   * para mostrarlo en el estado del pedido
   * @param fecha_hora La fecha y hora a interpretar. Se trae del último
   * estado del pedido y es de la forma {YYYY}-{MM}-{DD}T{HH}:{MM}:{SS}.{MS}Z
   */
  processTimeDate(fecha_hora: string) {
    const fecha_pedido_entrega = new Date();
    fecha_pedido_entrega.setTime(new Date(fecha_hora).getTime());
    this.hora_estado = new Date(fecha_pedido_entrega)
      .toLocaleString('en-US', { timeZone: 'America/Bogota' })
      .split(',')[1]
      .trim();
    this.fecha_estado = new Date(fecha_pedido_entrega)
      .toLocaleString('en-US', { timeZone: 'America/Bogota' })
      .split(',')[0]
      .trim();
  }

  /**
   * Toma el estado seleccionado, lo interpreta según lo que el
   * back espera, y actualiza el estado del pedido. Si se logra,
   * redirige al usuario al listado de pedidos. Si no, muestra un
   * mensaje de error
   */
  async cambiarEstado() {
    let nuevo_estado_back = '';

    switch (this.nuevo_estado) {
      case 'Aprobado':
        nuevo_estado_back = 'Aprobado Externo';
        break;

      case 'Alistamiento':
        nuevo_estado_back = 'Alistamiento';
        break;

      case 'Despachado':
        nuevo_estado_back = 'Despachado';
        break;

      case 'Facturado':
        nuevo_estado_back = 'Facturado';
        break;

      case 'Entregado':
        nuevo_estado_back = 'Entregado';
        break;

      default:
        return;
    }
    this.rest
      .putJWT(`pedido/${this.id_pedido}/${this.auth.user?._id}`, { estado: nuevo_estado_back })
      .toPromise()
      .then((resp: any) => {
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        modalRef.componentInstance.msg = `El estado del pedido fue cambiado a '${this.nuevo_estado}' con éxito`;
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          if (nuevo_estado_back == 'Entregado') {
            const mensaje = {
              tipo: 'Actualización de pedido',
              mensaje: `El pedido ${this.pedido?.id_pedido} ha sido entregado por el distribuidor ${this.auth.user_distribuidor?.nombre}`,
              punto_entrega: this.pedido?.punto_entrega,
            };
            this.rest.putJWT(`notificar/trabajadores/horeca/generico`, mensaje).toPromise();
          }
          window.location.reload();
        };
      })
      .catch((error) => {
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = `No fue posible cambiar el estado del pedido. Por favor intenta de nuevo más tarde.`;
        modalRef.componentInstance.btn_msg = 'Volver';
      });
  }

  /**
   * Abre un modal con la informaci´pn del encargado de la tienda
   */
  openModalInfoEncargado() {
    const modalRef = this.modalService.open(InformacionEncargadoPuntoComponent);
    modalRef.componentInstance.info_punto = this.info_punto;
    modalRef.componentInstance.info_horeca = this.info_horeca;
  }

  /**
   * Devuelve el total de cajas a comprar en base a las unidades compradas
   * @param unidades total unidades del producto
   * @param und_x_caja cuantas unidades vienen por caja
   * @returns total de cajas a comprar
   */
  converitrUnidadesACajad(unidades: number, und_x_caja: number) {
    const total_cajas = Math.round((unidades / und_x_caja) * 100) / 100;
    return total_cajas;
  }

  /**
   * Si el pedido está en pendiente, el distribuidor no puede verlo pues el punto de entrega no lo ha aprobado
   * Si el Pedido no es del distribuidor no puede ser visto ni modificado
   * @param pedido estado y ID del pedido
   */
  validarPedido(pedido: any) {
    if (pedido.estado === 'Pendiente') {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Este pedido aún no ha sido aprobado por el punto de entrega.';
      modalRef.componentInstance.btn_msg = 'Listo';
      modalRef.componentInstance.close_callback = () => {
        this.router.navigate(['pedidos-distribuidor']);
      };
    } else if (pedido.distribuidor !== this.auth.user?.distribuidor) {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = 'Este pedido no está dirigido a tu compañia.';
      modalRef.componentInstance.btn_msg = 'Listo';
      modalRef.componentInstance.close_callback = () => {
        this.router.navigate(['pedidos-distribuidor']);
      };
    }
  }

  /**
   * Lanza el pop-up de confirmación para que el usuario decida si cancelar o no el pedido
   */
  popUpCancelarPedido() {
    const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-alert-amarillo.png';
    modalRef.componentInstance.title = '¡Alto ahí!';
    modalRef.componentInstance.msg = '¿Estás seguro que deseas cancelar el pedido en curso?';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Continuar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      this.cancelarPedido();
    };
  }

  /**
   * Actualiza el estado en el back de este pedido a 'Cancelado por distribuidor'
   */
  async cancelarPedido() {
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    this.rest
      .getJWT(`/pedido/cancelar/distribuidor/${this.id_pedido}`)
      .toPromise()
      .then((resp: any) => {
        this.modalCarga?.close();
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        modalRef.componentInstance.msg = 'El pedido fue cancelado';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          window.location.reload();
        };
      })
      .catch((error) => {
        this.modalCarga?.close();
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = error.error.message;
        modalRef.componentInstance.btn_msg = 'Volver';
      });
  }
}
