import { Component, DoCheck, ElementRef, OnInit, ViewChild } from '@angular/core';
import { faChevronLeft, faCircle, faSearch, faShoppingCart, faEye } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { CarritoService } from 'src/app/services/carrito/carrito.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SeleccionarPuntoEntregaComponent } from 'src/app/modal/seleccionar-punto-entrega/seleccionar-punto-entrega.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { DateFormatService } from 'src/app/services/date-format/date-format.service';
import * as moment from 'moment';
import { DistribuidorService } from 'src/app/services/distribuidor/distribuidor.service';

@Component({
  selector: 'app-detalle-distribuidor',
  templateUrl: './detalle-distribuidor.component.html',
  styleUrls: ['./detalle-distribuidor.component.css'],
})
export class DetalleDistribuidorComponent implements OnInit, DoCheck {
  stBusq: any;
  ordenActual = 'asc';
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  //Imagen producto placeholder
  public product_placeholder = '../../assets/img/product-placeholder.png';
  public distribuidor_placeholder = '../../assets/img/icon-organizacion.png';
  // Data del distribuidor
  public id = '';
  public distribuidor: any = {};
  //Punto de entrega seleccionado
  public punto_entrega: any = '';
  // Referencias a íconos FontAwesome para la UI
  public faStar = faStar;
  public faChevronLeft = faChevronLeft;
  public faCircle = faCircle;
  public faSearch = faSearch;
  public faShoppingCart = faShoppingCart;
  public faEye = faEye;
  //Cantidad de productos en carrito
  public productcounter = 0;
  //Productos en carrito
  public productosencarrito: any[] = [];
  //Data del pedido
  public order: any;
  //Total a pagar
  public totalPrice = 0;
  //Guardamos dinamicamente los valores de autocompletado
  public filteredOptions: any;
  public mySearch = new FormControl();
  public searchTerm = '';
  // Variables de filtrado
  public lineas_productos_estado: boolean[] = [];
  public categorias_filtro_distribuidores: any[] = [];
  // Guarda los distribuidores sin filtrar
  public buffer_productos: any;
  // Nombre a buscar entre los productos y sus resultados
  public prods_filtrados: any[] = [];
  // Estado de vinculacion punto y distribuidor
  public estado_vinculacion_punto_distribuidor = true;
  public estado_convenio_punto_distribuidor = true;
  // Funcionalidad de filtros por categoría y líneas
  public buffer_categorias_productos: any[] = [];
  public categorias_productos: any[] = [];
  public categorias_productos_seleccionadas: any[] = [];
  public categorias_productos_estados: any[] = [];
  public categorias_productos_estados_todos = true;
  public buffer_lineas_productos: any[] = [];
  public lineas_productos: any[] = [];
  public lineas_productos_estado_todos = true;
  public lineas_productos_actuales_distribuidor: any[] = [];
  // Indicador para barras de navegación
  public active = 1;
  // Indicador para catalogo saldos/promocion
  public saldos_activo = false;
  // Guarda los productps en saldo y pro
  public productos_saldos_promos: any;
  public buffer_productos_saldos_promos: any;
  // Indicador filtro promociones
  public saldo_promocion_seleccionada = 'todos';
  // Calificaicon del distribuidor
  public calificacion = {
    abastecimiento: 0,
    ponderado: 0,
    precio: 0,
    puntualidad_entrega: 0,
  };
  // Fecha actual
  public current_date = new Date(moment.utc().format('DD MMMM YYYY'));
  // Documentos distribuidor
  archivoCuenta = '';
  archivoCredito = '';
  archivoCreacionCliente = '';
  totalSaldosPromociones = 0;
  horecaId = '';

  constructor(
    private route: ActivatedRoute,
    private cartservice: CarritoService,
    private rest: RestService,
    private auth: AuthService,
    private router: Router,
    public locallStorage: LocalStorageService,
    private dateFormat: DateFormatService,
    private modalService: NgbModal,
    private distribuidorService: DistribuidorService
  ) {
    
  }

  ngDoCheck(): void {
    // Valida no se pidan mas productos que los que se tienen en inventario
   if (this.order && this.order.products.length > 0) {
      for (let index = 0; index < this.order.products.length; index++) {
        if (this.order.products[index].unidad > this.order.products[index].inventario) {
          this.order.products[index].unidad = this.order.products[index].inventario;
        }
        if (this.order.products[index].unidad <= 0) {
          this.order.products[index].unidad = 0;
        }
      }
    }
    if (this.stBusq && this.stBusq.length > 2) {
      this.gen(this.stBusq);
    }
  }
  goUrlPago(url: string) {
    window.open(url, '_blank');
  }
  async ngOnInit() {  
    this.punto_entrega = JSON.parse(localStorage.getItem('punto_entrega_seleccionado') || '');
    this.current_date = new Date(moment.utc().format('DD MMMM YYYY'));
    this.id = this.route.snapshot.params['id'];
    if (!this.id) {
      console.error('El ID no está disponible. Redirigiendo...');
      this.router.navigate(['/inicio']);
      return;
    }
    if (this.punto_entrega.usuario_horeca) {
      this.horecaId = this.punto_entrega.usuario_horeca;
    }
    await this.getDocuments();
    await this.saldosPromocionesDistribuidor();
    await this.fetchProductosSaldosPromociones();
    if (this.punto_entrega._id) {
      await this.getEstadoVinculacionDistribuidorPunto();
    }
    await this.getCalificacionesDistribuidor();
    if (localStorage.getItem('ver_saldos') == 'true') {
      this.saldos_activo = true;
      localStorage.removeItem('ver_saldos');
    }
    this.fetchDistribuidor();
    this.cartservice.orderNext();
    this.cartservice.cart$.subscribe(async (productos) => {
      console.log('productosproductos', productos)
      this.productcounter = productos.length;
    });
    this.cartservice.totalPrice$.subscribe((totalPrice) => {
      this.totalPrice = totalPrice;
    });
    this.cartservice.orderList$.subscribe(async (order) => {
      this.order = order;
    });
    this.cartservice.cart$.subscribe(async (product) => {
      console.log('cartservice ------>', product)
      this.productosencarrito = product;
      await this.encontrarProductoEnCarrito();

    });
  }

  /**
   * Traer data del distribuidor y de la vinculación con el punto de entrega seleccionado
   */
  async fetchDistribuidor() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    /**
     * De no encontrarse punto seleccionado sale de la pagina
     */
    this.punto_entrega = JSON.parse(localStorage.getItem('punto_entrega_seleccionado') || '')
    if (!this.punto_entrega._id) {
      this.modalCarga?.close();
      this.router.navigate(['distribuidores']);
      return;
    }
    /**
     * Recupera los detalles del distribuidor
     */

    await this.rest
      .getJWT(`distribuidor/${this.id}`)
      .toPromise()
      .then((resp: any) => {
        this.distribuidor['distribuidor'] = resp;
        /**
         * Recupera los productos del distribuidor
         */
        this.rest
          .getJWT(`productos_por_distribuidor/distribuidorCopy/${this.id}/${this.punto_entrega._id}`)
          .toPromise()
          .then((resp_2: any) => {
            if (resp_2 && resp_2.productos.length > 0) {
              this.distribuidor['productos'] = resp_2.productos;
            } else {
              this.distribuidor['productos'] = [];
            }
            let prueba =this.distribuidor.productos.filter((producto: any) => {
              return (
                producto.ofertass
              );
            });
            /** Se filtran los saldos y promociones del catalogo */
            this.prods_filtrados = this.distribuidor.productos.filter((producto: any) => {
              return (
                producto.estadoActualizacion === 'Aceptado' && producto.promocion === false && producto.saldos === false
              );
            });
            this.buffer_productos = this.prods_filtrados;
            /** Flag para mostrar puntos feat si estan dentro del rango de fechas */
            this.prods_filtrados.forEach((producto: any) => {
              if (!producto.codigo_organizacion_producto) {
                producto.codigo_organizacion_producto = '';
              }
              // eslint-disable-next-line prettier/prettier
              producto.flag_aplica_puntos_feat = producto.mostrarPF;
              /*const fecha_apertura = new Date(moment.utc(producto.fecha_apertura_puntosft).format('DD MMMM YYYY'));
              const fecha_cierre = new Date(moment.utc(producto.fecha_cierre_puntosft).format('DD MMMM YYYY'));
              if (
                producto.fecha_apertura_puntosft &&
                producto.fecha_cierre_puntosft &&
                fecha_apertura <= this.current_date &&
                fecha_cierre >= this.current_date
              ) {
                producto.flag_aplica_puntos_feat = true;
              }*/
            });
            this.ordenarPorNombre('asc');

            /**
             * Recupera el estado de vinculación del distribuidor, si no esta aprobado sale de la pagina
             * y redirecciona al componente de no aprobados o vinculados
             */
            this.rest
              .getJWT(`distribuidores_vinculados_dist_punto/${this.id}/${this.punto_entrega._id}`)
              .toPromise()
              .then(async (resp_3: any) => {
                const vinculacion = resp_3;
                if (vinculacion.data?.[0]?.estado !== 'Aprobado') {
                  this.router.navigateByUrl(`distribuidores/novinculado/${this.id}`);
                  this.modalCarga?.close();
                  return;
                }
                /**
                 * Encuentra los productos en el carrito
                 */
                await this.encontrarProductoEnCarrito();
                /**
                 * Se ejecutan los metodos para cargar funcionalidades de filtros y busqueda
                 */
                await this.autoCompletadoBusqueda();
                await this.fetchCategoriasYLineas();
                /**
                 * Se recuperan las lineas de producto disponibles del distribuidor
                 * estas solo contienen el ID de la linea, por lo que será el input
                 * para filtrar en el arrya de objetos de lineas de productos los que coincidan
                 * luego se eliminan los repetidos
                 */
                this.prods_filtrados.forEach((element: any) => {
                  if (element.linea_producto[0]) {
                    this.lineas_productos_actuales_distribuidor.push(element.linea_producto[0]);
                  }
                });
                this.lineas_productos_actuales_distribuidor = [...new Set(this.lineas_productos_actuales_distribuidor)];
                this.modalCarga?.close();
              });
          });
          this.ordenarPorNombre('asc');
          this.modalCarga?.close();

      })
      .catch(() => {
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'No fue posible recuperar la información, intentalo de nuevo mas tarde';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/inicio']);
        };
      });
  }

  /**
   * Recupera el estado de vinculacion entre un distribuidor y el punto
   * principlamente se usa para verificar si el distribuidor está en cartera
   */
  getEstadoVinculacionDistribuidorPunto() {
    this.rest
      .getJWT(`/distribuidores_vinculados_dist_punto/${this.id}/${this.punto_entrega._id}`)
      .toPromise()
      .then((vinculacion: any) => {
        if (vinculacion.data.length > 0) {
          this.estado_convenio_punto_distribuidor = vinculacion.data[0].convenio;
          this.estado_vinculacion_punto_distribuidor = vinculacion.data[0].cartera;
          if (this.estado_vinculacion_punto_distribuidor === true) {
            this.showAlertaEnCartera();
          }
        }
      });
  }

  /**
   * Recupera las calificaciones por item de un distribuidor
   */
  getCalificacionesDistribuidor() {
    this.rest
      .getJWT(`calificacion/${this.id}`)
      .toPromise()
      .then((calificacion: any) => {
        this.calificacion.abastecimiento = Math.round(calificacion.calificacion.abastecimiento * 100) / 100;
        this.calificacion.precio = Math.round(calificacion.calificacion.precio * 100) / 100;
        this.calificacion.puntualidad_entrega = Math.round(calificacion.calificacion.puntualidad_entrega * 100) / 100;
      });
  }

  /**
   * Recupera todos los productos que están en saldos o promociones
   */
  async fetchProductosSaldosPromociones() {
    try {
      const resp_prods: any = await this.rest
        .getJWT(`saldos_promos_por_distribuidor/distribuidor/${this.id}`)
        .toPromise();
      this.productos_saldos_promos = resp_prods.data.productos;
      this.buffer_productos_saldos_promos = this.productos_saldos_promos;
      //TODO: Completar autocompltado para Saldos y promociones
      // this.autoCompletadoBusqueda();
      /*if (this.productos_saldos_promos.length == 0) {
        const modalRef = this.modalService.open(SimpleComponent);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = 'Este distribuidor no tiene saldos ni promociones actualmente.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.saldos_activo = false;
        };
      }*/
    } catch (err) {
      console.log(err);
    }
  }

  /************************** Funcionalidades carrito *************************/
  /**
   * Esta función tiene como objetivo añadir un nuevo producto al carrito
   * @param product es el producto que se va a añadir
   * @param unidad cantidad de unidades a agregar
   * @param caja cantidad de cajas a agregar
   */
  agregarProduct(product: any, unidad: number, caja: number, und_x_caja: number, inv_unidad: number, index: number) {
    /** Si el punto está en cartera no podra hacer pedidos */
    if (this.estado_vinculacion_punto_distribuidor === true) {
      this.showAlertaEnCartera();
      return;
    }
    this.rest
      .getJWT('distribuidores_vinculados')
      .toPromise()
      .then((resp: any) => {
        const distribuidor_cartera = resp.filter(
          (obj: any) =>
            obj.estado == 'Aprobado' &&
            obj.punto_entrega == this.punto_entrega._id &&
            obj.distribuidor?._id == this.distribuidor.distribuidor._id
        );
        if (distribuidor_cartera[0].cartera == true) {
          const ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
          };
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'Comunicate con este distribuidor porque tienes saldos pendientes en cartera';
          modalRef.componentInstance.btn_msg = 'Volver';
          modalRef.componentInstance.close_callback = () => {
            this.router.navigate(['/distribuidores']);
          };
        } else {
          const unidades = parseInt(unidad.toString());
          const cajas = parseInt(caja.toString());
          const unds_x_caja = parseInt(und_x_caja.toString());
          const inventario = parseInt(inv_unidad.toString());
          localStorage.setItem('distribuidorCarrito', this.id);
          this.cartservice.addCart(product, unidades, cajas, unds_x_caja, inventario);
        }
      });
  }

  /**
   * Esta función tiene como objetivo identificar que productos existen
   * en el carrito de compras. Gracias a esto podemos saber en qué momentos hacer el cambio de botones
   * de agregar item por el de agregar y restar items
   */
  async encontrarProductoEnCarrito() {
    console.log('this.distribuidor', this.distribuidor)
    if (this.distribuidor.productos) {
      Promise.all(
        this.distribuidor.productos.map(async (element: any) => {
          let enCarrito;
          this.productosencarrito.forEach(async (carrito) => {
            console.log('carrito._id ',carrito._id , element._id )
            if (carrito._id == element._id) {
              enCarrito = carrito;
              const index = this.productosencarrito.indexOf(enCarrito);
              if (index > -1) {
                element.index = index + 1;
              }
            }
          });
        })
      );
    }
  }
  ordenarPorNombre(orden: 'asc' | 'desc') {
    this.ordenActual = orden;
    this.prods_filtrados.sort((a, b) => {
      const nombreA = a.nombre.toLowerCase();
      const nombreB = b.nombre.toLowerCase();
      if (orden === 'asc') {
        return nombreA.localeCompare(nombreB);
      } else {
        return nombreB.localeCompare(nombreA);
      }
    });
  }
  /**
   * El objetivo de esto es sumar o restar la cantidad de cajas o unidades que se comprará de cada producto
   * @param operation es la operación que sera ejecutada (false: resta, true: suma)
   * @param i index del producto en el que se aplicara la operación.
   * @param item identifica si se ejecutará la operación en cajas o productos
   */
  quantity(operation: boolean, i: number, item: string) {
    /** Si el punto está en cartera no podra hacer pedidos */
    if (this.estado_vinculacion_punto_distribuidor === true) {
      this.showAlertaEnCartera();
      return;
    }
    const unidadP = this.order.products[i].unidad;
    if (unidadP === 1 && !operation) {
      this.cartservice.removeCart(i);
      this.ngOnInit();
    } else {
      this.cartservice.sumaresta(operation, i, item, 0);
    }
  }
  /**
   * El objetivo de esto es sumar o restar la cantidad de cajas o unidades que se comprará de cada producto
   * @param operation es la operación que sera ejecutada (false: resta, true: suma)
   * @param i index del producto en el que se aplicara la operación.
   * @param item identifica si se ejecutará la operación en cajas o productos
   */
  public calculatePrice(operation: boolean, i: number, item: string, cant: any) {
    if (this.estado_vinculacion_punto_distribuidor === true) {
      this.showAlertaEnCartera();
      return;
    }
    this.cartservice.sumaresta(operation, i, item, cant);
  }
  /**
   * Lanza un modal para seleccionar el punto de entrega
   * si este no está asociado al distribuidor saldra de esta pagina
   */
  selecionarPunto() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'modal-selecionar-punto',
    };
    const modalRef = this.modalService.open(SeleccionarPuntoEntregaComponent, ngbModalOptions);
    modalRef.componentInstance.distribuidor_id = this.distribuidor.distribuidor._id;
    modalRef.componentInstance.callback = () => {
      /**Recarga la información del distribuidor */
      this.ngOnInit();
    };
  }

  /**
   * Calcula las unidades pendientes a pedir para completar otra caja
   * @param und_x_caja unidades que vienen por caja.
   * @param total_unidades total unidades pedidas por el usuario de un producto.
   */
  unidadesPendientesCaja(und_x_caja: number, total_unidades: number) {
    if (und_x_caja !== 0) {
      const unidades_pendientes_caja = und_x_caja - (total_unidades % und_x_caja);
      return unidades_pendientes_caja;
    } else {
      return 0;
    }
  }

  /**
   * Calcula el total de cajas de un producto en base a las unidades pedidas
   * @param und_x_caja unidades que vienen por caja.
   * @param total_unidades total unidades pedidas por el usuario de un producto.
   */
  totalCajasPedido(und_x_caja: number, total_unidades: number) {
    if (und_x_caja !== 0) {
      const total_cajas_pedido = Math.round((total_unidades / und_x_caja) * 100) / 100;
      return total_cajas_pedido;
    } else {
      return 0;
    }
  }
  filterByValue(array: any, string: any) {
    return array.filter(
      (o: any) =>
        o.nombre.toLowerCase().includes(string.toLowerCase()) ||
        o.codigo_distribuidor_producto.toLowerCase().includes(string.toLowerCase()) ||
        o.codigo_organizacion_producto.toLowerCase().includes(string.toLowerCase())
    );
  }
  gen(st: any) {
    this.prods_filtrados = this.filterByValue(this.buffer_productos, st);
  }
  /************************** Busqueda autocompletado *************************/

  /**
   * Este metodo tiene como objetoautocompletar la busqueda del usuario
   */
  autoCompletadoBusqueda() {
    this.filteredOptions = this.mySearch.valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.nombre)),
      map((nombre) => (nombre ? this._filter(nombre) : this.distribuidor?.productos.slice()))
    );
  }

  displayFn(user: any) {
    return user ? user.nombre : undefined;
  }

  returnFn(user: any) {
    return user ? user.value : undefined;
  }

  itemDisplayFn(item: any) {
    return item ? item.name : '';
  }

  private _filter(nombre: string) {
    const filterValue = this.normalizeString(nombre.toLowerCase());
    return this.distribuidor?.productos.filter(
      (option: any) => this.normalizeString(option.nombre).toLowerCase().indexOf(filterValue) === 0
    );
  }

  clearSearch() {
    this.searchTerm = '';
  }

  /**
   * Toma el string que entra por parámetro y cambia tildes y diéresis
   * por las letras sin acento, y lo pasa a minúsculas
   * @param pWord El string a filtrar
   * @returns El string filtrado
   */
  normalizeString(pWord: string): string {
    return pWord
      .trim()
      .normalize('NFD')
      .replace(/\p{Diacritic}/gu, '')
      .toLowerCase();
  }

  /******************** Filtros por categoría y linea producto *******************/
  /**
   * Recupera las categorías y lineas de productos activas del distribuidor
   */
  async fetchCategoriasYLineas() {
    /********************* Categorías ********************/
    const resp: any = await this.rest
      .getJWT(`categorias_productos_aprobados_distribuidor/distribuidor/${this.id}`)
      .toPromise();
    /** Elimina los objetos repetidos */
    this.categorias_productos = resp.filter((value: any, index: any) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        resp.findIndex((obj: any) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    this.categorias_productos = this.categorias_productos.sort(function (a: any, b: any) {
      if (a.nombre === undefined) return 1;
      if (b.nombre === undefined) return -1;
      if (a.nombre === b.nombre) return 0;
      return a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1;
    });
    /** Guarda un buffer para la funcionalidad de filtros */
    this.buffer_categorias_productos = this.categorias_productos;
    /**
     * Crea un array con el estado de las categorias de productos.
     * True (filtro activo) o False (filtro NO activo) Todas inician en false
     */
    this.categorias_productos.forEach(() => {
      this.categorias_productos_estados.push(false);
    });
    /********************* Líneas ********************/
    const resp_lineas: any = await this.rest
      .getJWT(`lineas_productos_aprobados_distribuidor/distribuidor/${this.id}`)
      .toPromise();
    /** Elimina los objetos repetidos */
    this.lineas_productos = resp_lineas.filter((value: any, index: any) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        resp_lineas.findIndex((obj: any) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    this.lineas_productos = this.lineas_productos.sort(function (a: any, b: any) {
      if (a.nombre === undefined) return 1;
      if (b.nombre === undefined) return -1;
      if (a.nombre === b.nombre) return 0;
      return a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1;
    });
    /** Guarda un buffer para la funcionalidad de filtros */
    this.buffer_lineas_productos = this.lineas_productos;
    /** Recupera las lineas de productos */
    this.handleLineasProductos();
  }

  /**
   * Este metodo tiene como objeto organizar los filtros por categorías
   * @param click_filtro_todos de darse click en el filtro Todos, reinicia todas las categorías
   */
  handleClickCategoria(click_filtro_todos: boolean) {
    /** Carga todas las categorías disponibles y deselecciona todas las categorias */
    this.categorias_productos = this.buffer_categorias_productos;
    this.categorias_productos_seleccionadas = [];
    /**
     * Verifica Si está seleccionada la categoría dentro de los filtros aplicados
     * si lo está, la agrega al array de seleccionados, si no, no la toma en cuenta
     */
    for (let index = 0; index < this.categorias_productos_estados.length; index++) {
      if (this.categorias_productos_estados[index] === true) {
        this.categorias_productos_seleccionadas.push(this.categorias_productos[index]);
      }
    }
    /******************** Verifica si existen filtros aplicados ********************/
    if (this.categorias_productos_seleccionadas.length == 0 || click_filtro_todos == true) {
      /************************** NO hay filtros aplicados *************************/
      /** Garantiza todas las categorias deseleccionadas y "Todos" activo*/
      this.categorias_productos_estados_todos = true;
      this.categorias_productos_estados = this.categorias_productos_estados.map(
        (element: boolean) => (element = false)
      );
      /** Todas las categorias disbonibles y seleccionadas */
      this.categorias_productos_seleccionadas = this.categorias_productos;
    } else {
      /************************** SI hay filtros aplicados *************************/
      this.categorias_productos_estados_todos = false;
    }
    /*********** Organiza nuevamente las lineas de productos disponibles ***********/
    /**
     * Crea un array con el estado de la selección de filtros de lineas
     * de productos. Todas inician en false, es decir, se mostrarán todas
     * el estado es true (filtro activo) o false (filtro NO activo)
     */
    for (let index = 0; index < this.lineas_productos_estado.length; index++) {
      this.lineas_productos_estado[index] = false;
    }
    this.handleLineasProductos();
  }

  /**
   * Organiza las lineas de productos de las categorías
   * Esta función reorganiza la lineas de productos cada vez que se cambian las categorías
   * Solo se mostrarán las linea de las categorías seleccionadas
   */
  handleLineasProductos() {
    this.lineas_productos = [];
    /** Si no hay categorías seleccionadas, las muestra todas */
    if (this.categorias_productos_seleccionadas.length == 0) {
      this.categorias_productos_seleccionadas = this.categorias_productos;
    }
    /** Crea un array con todas las lineas de productos disponibles de las categorias seleccionadas */
    const lineas_productos_disponibles_por_categoria: any[] = [];
    for (const categoria of this.categorias_productos_seleccionadas) {
      categoria.lineas_producto.forEach((element: any) => {
        lineas_productos_disponibles_por_categoria.push(element);
      });
    }
    /**
     * Filtra dejando solo las lineas de productos que SI están disponibles en el distribuidor.
     * Hay mas de 100, por lo que se realiza este filtro para mejorar la experiencia del usuario.
     * De las lineas disponbiles del distribuidor, se filtran según las las categorías seleccionadas.
     */
    this.lineas_productos = this.buffer_lineas_productos.filter((el: any) => {
      return lineas_productos_disponibles_por_categoria.some((f: any) => {
        return f == el._id;
      });
    });
    /**
     * Crea un array con el estado de la selección de filtros de lineas de productos.
     * Todas inician en false. Opciones: True (filtro activdo) o False (filtro NO activo).
     */
    this.lineas_productos.forEach(() => {
      this.lineas_productos_estado.push(false);
    });
    /** Ya establecido los filtros se muestran los productos según aplique */
    this.handleClickLineaProducto(false);
  }

  /**
   * Este metodo tiene como objeto filtrar los productos por linea de producto
   * @param click_filtro_todos de darse click en el filtro Todos, habilita todas
   * las lineas de producto disponibles y muestra toda la data respectiva
   */
  handleClickLineaProducto(click_filtro_todos: boolean) {
    /** Reinicia el array de productos sin filtro */
    this.prods_filtrados = this.buffer_productos;
    /** Crea un array con las categorías de filtro seleccionadas */
    let lineas_productos_seleccionadas: any = [];
    for (let index = 0; index < this.lineas_productos_estado.length; index++) {
      if (this.lineas_productos_estado[index] === true) {
        lineas_productos_seleccionadas.push(this.lineas_productos[index]);
      }
    }
    /**************** Verifica si existen filtros aplicados ***************/
    if (lineas_productos_seleccionadas.length === 0 || click_filtro_todos === true) {
      /********************** NO hay filtros aplicados *********************/
      /** Reinicia botones y habilita todas las lineas para mostrar todos los productos */
      this.lineas_productos_estado_todos = true;
      this.lineas_productos_estado = this.lineas_productos_estado.map((element: boolean) => (element = false));
      lineas_productos_seleccionadas = this.lineas_productos;
    } else {
      /********************** SI hay filtros aplicados *********************/
      this.lineas_productos_estado_todos = false;
    }
    /******************************** Filtro *******************************/
    /** Filtra los productos según las lineas de productos seleccionadas */
    this.prods_filtrados = this.prods_filtrados.filter((el: any) => {
      return lineas_productos_seleccionadas.some((f: any) => {
        return f._id == el.linea_producto[0];
      });
    });
  }

  /**
   * Filtro para saldos y promociones
   */
  filtrarSaldosPromociones(filtro: string) {
    if (this.saldo_promocion_seleccionada === 'todos') {
      this.productos_saldos_promos = this.buffer_productos_saldos_promos;
    } else if (this.saldo_promocion_seleccionada === 'saldos') {
      this.productos_saldos_promos = this.buffer_productos_saldos_promos.filter((producto: any) => {
        return producto.saldos === true;
      });
    } else if (this.saldo_promocion_seleccionada === 'promocion') {
      this.productos_saldos_promos = this.buffer_productos_saldos_promos.filter((producto: any) => {
        return producto.promocion === true;
      });
    }
  }
  /********************************** Otras funcionalidas *********************************/

  /**
   * Estos metodos tienen como objeto ver el detalle de los distribuidores
   * El primer metodo es para la lista completa de distribuidores
   * El segundo metodo es para los distribuidores aprobados
   */
  verDetalleProducto(event: any) {
    const filtrado = event.option.value._id;
    this.router.navigate(['/distribuidores', this.distribuidor.distribuidor._id, filtrado]);
  }

  /**
   * Toma una fecha que entra por parámetro en formato {YYYY}-{MM}-{DD}T{HH}:{MM}:{SS}.{MS}Z
   * y la devuelve
   * @param pFecha
   */
  procesarFechaVencimiento(pFecha: any) {
    const split_date: string[] = pFecha.split('T')[0].split('-');
    return `${split_date[2]} de ${this.dateFormat.months[Number.parseInt(split_date[1]) - 1]} ${split_date[0]}`;
  }

  /**
   * Voler a modulo anterior
   * dependiendo de donde haya ingresado, volvera al modulo anterior
   */
  volver() {
    if (this.router.url == `/inicio/distribuidores/${this.distribuidor.distribuidor._id}`) {
      this.router.navigate(['/inicio']);
    } else if (this.router.url == `/solicitudes-distribuidor/${this.distribuidor.distribuidor._id}`) {
      this.router.navigate(['/solicitudes-distribuidor']);
    } else if (this.router.url == `/mis-distribuidores/${this.distribuidor.distribuidor._id}`) {
      this.router.navigate(['/mis-distribuidores']);
    } else {
      this.router.navigate(['/distribuidores']);
    }
  }

  /**
   * Alerta no puede hacer pedidos por estar en cartera
   */
  showAlertaEnCartera() {
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg =
      'No puedes realizar pedidos porque tienes pagos pendientes con este distribuidor. Comunícate con el distribuidor para que puedas volver a crear pedidos.';
    modalRef.componentInstance.btn_msg = 'Volver';
  }

  calcularPrecioCaja(producto: any) {
    let precioDescuento =
      producto?.precios[0]?.precio_unidad - producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100);
    precioDescuento = Math.round(precioDescuento);
    return precioDescuento * producto?.precios[0]?.und_x_caja;
  }

  getDocuments() {
    this.distribuidorService.getDocumentDistribuidor(this.id).subscribe(
      (res) => {
        res.forEach((e: any) => {
          if (e.documento == 'Certificacion Cuenta') {
            this.archivoCuenta = e.locacion;
          } else if (e.documento == 'Creacion de cliente') {
            this.archivoCreacionCliente = e.locacion;
          } else if (e.documento == 'Aprobacion de credito') {
            this.archivoCredito = e.locacion;
          }
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  saldosPromocionesDistribuidor() {
    this.rest
      .getJWT(`saldos_promos_por_distribuidor/distribuidor/${this.id}`)
      .toPromise()
      .then((promo: any) => {
        if (promo.data?.productos) {
          this.totalSaldosPromociones = promo.data?.productos?.length;
        }
      });
  }

  actualizarProductoFavorito(accion: string, productoId: string, establecimientos: any) {
    if (accion === 'agregar') {
      if (!establecimientos) {
        establecimientos = [];
      }
      establecimientos.push(this.horecaId);
    } else if (accion == 'quitar') {
      const index = establecimientos.indexOf(this.horecaId);
      if (index >= 0) {
        establecimientos.splice(index, 1);
      }
    }
    const bodyEstablecimientos = {
      establecimientos_interesados: establecimientos,
    };
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    this.rest
      .putJWT(`producto/${productoId}`, bodyEstablecimientos)
      .toPromise()
      .then((res: any) => {
        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        if (accion === 'agregar') {
          modalRef.componentInstance.msg = 'Has agregado este producto a tu lista de favoritos';
        } else {
          modalRef.componentInstance.msg = 'Has eliminado este producto de tu lista de favoritos';
        }
        modalRef.componentInstance.btn_msg = 'Volver';
        this.modalCarga?.close();
      })
      .catch(() => {
        if (accion === 'agregar') {
          const index = establecimientos.indexOf(this.horecaId);
          if (index >= 0) {
            establecimientos.splice(index, 1);
          }
        } else {
          establecimientos.push(this.horecaId);
        }

        const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        if (accion === 'agregar') {
          modalRef.componentInstance.msg = 'No fue posible agregar este producto a tu lista de favoritos';
        } else {
          modalRef.componentInstance.msg = 'No fue posible eliminar este producto de tu lista de favoritos';
        }
        modalRef.componentInstance.btn_msg = 'Volver';
        this.modalCarga?.close();
      });
  }
}

