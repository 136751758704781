
<div class="card" >
 
  <div class="card-header">
    <div class="row">
      <div class="col-12">
        <div class="row" style="    padding: 17px;">
          <div class="col-12">
<form class="example-form">
  <div class="row">
    <div class="col-12">
      <em class="material-icons float-r cursor cerrar" (click)="onNo()">close</em>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-10">
          <input
          #escogido
          type="text"
          placeholder="Buscar por nombre del producto / Cód. Dist. / Cód. Org."
          aria-label="Buscar distribuidor"
          [(ngModel)]="stBusq"
          [readonly]="filtroNombre.length > 0 || !productosXLS || copySeleccionadosXLS"
          [ngModelOptions]="{standalone: true}"
          class="w-100 h-100 input-busqueda pr-0 form-control ng-touched" 
          style="    height: 50px !important;" />
         
          <ul class="list-group" *ngIf="stBusq.length > 3">
            <li class="list-group-item d-flex justify-content-between align-items-center" *ngFor="let prod of prods_filtrados"
            (click)="onChangeofOptions(prod)"
            >
              <img [src]="prod.fotos[0]" alt="" width="50">
              {{prod.nombre}}
        
            </li>
          </ul>
        </div>
        <div class="col-12">
          <div class="row" style="margin-top: 1rem;">
            <div class="col-3">
              <button type="button" class="btn btn-info" style="font-size: 12px;" (click)="descargaExcel()" *ngIf="productosXLS">
                Descargar convenio
              </button> 
            </div>
            <div class="col-6">
              <button [hidden]="true" type="file" class="btn btn-info" style="font-size: 12px; margin-top: 10px;" *ngIf="productosXLS"  name="files[]" multiple id="jsonFile" accept=".xlsx" (change)="openInput('selectedFile')">
                Subir portafolio
              </button>
              <input  id="selectedFile" type="file" name="files[]" multiple  #fileUploader accept=".xlsx" (change)="onFileChange($event)" *ngIf="productosXLS"/>
              <em class="material-icons float-r cursor cerrar" (click)="resetXLS(); fileUploader.value = null" *ngIf="productosEspecialesXls" style="    margin-top: -8px;
              ">delete_forever</em>
            </div>
          </div>
          
          

        </div>
      </div>
    </div>
  </div>

</form>


          </div>
        </div>
      
    </div>
    </div>
  </div>
    <div *ngIf="!productosEspecialesXls">
    <div class="card-body" *ngIf="copySeleccionados">
        <div class="row"> 
            <div class="col-12 table2"  *ngIf="copySeleccionados.length > 0">
                <table class="table"   WIDTH="100%">
                    <thead>
                      <tr>
                        <th>Imagen</th>
                        <th style="    width: 500px;">Nombre 
                          <input type="text" class="form-control "  [(ngModel)]="filtroNombre"
                          [readonly]="stBusq.length > 0"
                          placeholder="Filtrar por nombre del producto"
                          >
                        </th>
                        <th>% Descuento</th>
                        <th>% Aumento</th>
                        <th>$ Monto fijo</th>
                        <th class="text-center">Acción</th>
                      </tr>
                    </thead>
                    <tbody >
                      <tr *ngFor="let s of productosSeleccionado; let i = index" class="my-auto">
                        <td class="h-100">
                          
                            <img [src]="s.imagenRef" alt="" width="50" height="50">
                        </td>
                        <td class="h-100" style="font-size: 14px;width: 500px;">{{s.nombre}} / {{s.codigoDist}}</td>
                        <td class="h-100">
                            <input type="number" 
                            class="form-control "  
                            min="0"
                            step="1"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            pattern="[0-9]*"
                            [(ngModel)]="s.porcentaDescuento" 
                            [readonly]="(s.porcentaIncremento && s.porcentaIncremento > 0) || (s.montoFijo && s.montoFijo > 0)">
                        </td>
                        <td class="h-100">
                          <input type="number" class="form-control " 
                          min="0" 
                          step="1"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            pattern="[0-9]*"
                          [(ngModel)]="s.porcentaIncremento" 
                          [readonly]="(s.porcentaDescuento && s.porcentaDescuento > 0) || (s.montoFijo && s.montoFijo > 0)">
                        </td>
                        <td class="h-100">
                          <input type="number" class="form-control" [(ngModel)]="s.montoFijo"
                          pattern="\d*"
                          min="0"
                          step="1"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            pattern="[0-9]*"
                          [readonly]="(s.porcentaDescuento && s.porcentaDescuento > 0) || (s.porcentaIncremento && s.porcentaIncremento > 0)"
                          >
                        </td>
                        <td class="h-100 text-center" >
                          <em class="material-icons float-r cursor" (click)="eliminarProd(s)">delete</em>
                        </td>
                      </tr>
                     
                     
                    </tbody>
                  </table>
            </div>
            <div class="col-12" *ngIf="copySeleccionados.length === 0">
                              <nb-card class="card-change card-modal" >
                  <nb-card-body>
                    <div class="form-group">
                     <img src="../../../assets/img/icon-alert-amarillo.png"  alt="icono alerta">
                
                    </div>
                    <div class="form-group">
                     <h4 class="texto-modal">No dispones de precios especiales <br>para este punto de entrega.</h4>
                     </div>
                  </nb-card-body>
                </nb-card>
              </div>

        </div>
        
    </div>
    <div class="card-body" *ngIf="!copySeleccionados">
      <div class="row"> 
          <div class="col-12">
                            <nb-card class="card-change card-modal" >
                <nb-card-body>
                  <div class="form-group">
                   <img src="../../../assets/img/icon-alert-amarillo.png"  alt="icono alerta">
              
                  </div>
                  <div class="form-group">
                   <h4 class="texto-modal">No dispones de precios especiales <br>para este punto de entrega.</h4>
                   </div>
                </nb-card-body>
              </nb-card>
            </div>

      </div>
      
  </div>
    <div class="card-footer" *ngIf="copySeleccionados">
      <div class="row" *ngIf="copySeleccionados">
        <div class="col-12">
            <div class="row">
                  <div class="col-12">
                    <button id="btn_modal-yes" style="width: 20%;" (click)="onYes()" *ngIf="!idPreciosEspeciales">
                      {{ btn_msg_yes }}
                    </button>
                    <button *ngIf="idPreciosEspeciales && !loading"  id="btn_modal-yes" style="width: 20%;" (click)="update()">
                      Actualizar
                    </button>
                    <div *ngIf="loading" class="spinner-border text-primary" role="status" style="float: right;">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
            </div>
        </div>
        
      </div>
    </div>
    </div>
    <div *ngIf="copySeleccionadosXLS">
      <div *ngIf="copySeleccionadosXLS.length > 0">
        <div class="card-body">
          <div class="row">
              
              <div class="col-12 table2" >
                  <table class="table" WIDTH="100%">
                      <thead>
                        <tr>
                          <th>Imagen</th>
                          <th style="    width: 500px;">Nombre 
                            <input type="text" class="form-control "  [(ngModel)]="filtroNombreXLS"
                            [readonly]="stBusq.length > 0"
                            placeholder="Filtrar por nombre del producto"
                            >
                          </th>
                          <th>% Descuento</th>
                          <th>% Aumento</th>
                          <th>$ Monto fijo</th>
                          <th class="text-center">Acción</th>
                        </tr>
                      </thead>
                      <tbody >
                        <tr *ngFor="let s of productosEspecialesXls; let i = index" class="my-auto">
                          <td class="h-100">
                          
                            <img [src]="s.imagenRef" alt="" width="50" height="50">
                        </td>
                        <td class="h-100" style="font-size: 14px;width: 500px;">{{s.nombre}} / {{s.codigoDist}}</td>
                        <td class="h-100">
                            <input type="number" 
                            class="form-control "  
                            min="0"
                            step="1"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            pattern="[0-9]*"
                            [(ngModel)]="s.porcentaDescuento" 
                            [readonly]="(s.porcentaIncremento && s.porcentaIncremento > 0) || (s.montoFijo && s.montoFijo > 0)">
                        </td>
                        <td class="h-100">
                          <input type="number" class="form-control " 
                          min="0" 
                          step="1"
                            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                            pattern="[0-9]*"
                          [(ngModel)]="s.porcentaIncremento" 
                          [readonly]="(s.porcentaDescuento && s.porcentaDescuento > 0) || (s.montoFijo && s.montoFijo > 0)">
                        </td>
                        <td class="h-100">
                          <input type="number" class="form-control" [(ngModel)]="s.montoFijo"
                          pattern="\d*"
                          min="0"
                          step="1"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                          pattern="[0-9]*"
                          [readonly]="(s.porcentaDescuento && s.porcentaDescuento > 0) || (s.porcentaIncremento && s.porcentaIncremento > 0)"
                          >
                        </td>
                        <td class="h-100 text-center" >
                          <em class="material-icons float-r cursor" (click)="eliminarProd(s)">delete</em>
                        </td>
                        </tr>
                       
                       
                      </tbody>
                    </table>
              </div>
              
          </div>
          
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-12">
            <button id="btn_modal-reset" style="width: 50%;" (click)="resetXLS()">
              Volver
            </button>
            <button id="btn_modal-yes" style="width: 50%;" (click)="guardarXLS()">
              Guardar XLS
            </button>
          </div>
          
        </div>
      </div>
      </div>
      <div *ngIf="copySeleccionadosXLS.length === 0">
        <div class="col-12">
          <nb-card class="card-change card-modal" >
<nb-card-body>
<div class="form-group">
 <img src="../../../assets/img/icon-alert-amarillo.png"  alt="icono alerta">

</div>
<div class="form-group">
 <h4 class="texto-modal">El archivo cargado no contiene productos <br>con descuentos especiales.</h4>
 </div>
</nb-card-body>
</nb-card>
</div>
      </div>
    </div>
   
  </div>
