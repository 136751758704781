import { Component, OnInit } from '@angular/core';
import { faChevronLeft, faCircle, faSearch, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { RestService } from 'src/app/services/rest/rest.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SeleccionarPuntoEntregaComponent } from 'src/app/modal/seleccionar-punto-entrega/seleccionar-punto-entrega.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { Store } from '@ngxs/store';
import * as moment from 'moment';

@Component({
  selector: 'app-detalle-distribuidor-no-vinculado',
  templateUrl: './detalle-distribuidor-no-vinculado.component.html',
  styleUrls: ['./detalle-distribuidor-no-vinculado.component.css'],
})
export class DetalleDistribuidorNoVinculadoComponent implements OnInit {
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Referencias a íconos FontAwesome para la UI
  public faStar = faStar;
  public faChevronLeft = faChevronLeft;
  public faCircle = faCircle; 
  public faSearch = faSearch;
  public faShoppingCart = faShoppingCart;
  // Data del distribuidor
  public distribuidor: any;
  public id = '';
  //Punto de entrega seleccionado
  public punto_entrega: any = '';
  // Guarda las categorias y lineas de productos para filtros
  public categorias_productos: any[] = [];
  public lineas_productos: any[] = [];
  // Nombre a buscar entre los productos y sus resultados
  public prods_filtrados: any[] = [];
  //Imagen producto placeholder
  public product_placeholder = '../../assets/img/product-placeholder.png';
  public distribuidor_placeholder = '../../assets/img/icon-organizacion.png';
  // Estado vinculacion distribuidor
  public vinculacion: any = null;
  // Flag vinculacion
  public flag_vinculacion = true;
  // Usuario horeca logueado
  public userInfo = JSON.parse(localStorage.getItem('auth') || '');

  constructor(
    private route: ActivatedRoute,
    private rest: RestService,
    private auth: AuthService,
    private router: Router,
    private ngxsStore: Store,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.punto_entrega = this.auth.punto_seleccionado;
    this.route.params.subscribe(async (params) => {
      this.id = params.id;
    });
    this.fetchDistribuidor();
    this.fetchCategoriasYLineas();
  }

  /**
   * Traer data del distribuidor y de la vinculación con el punto de entrega seleccionado
   */
  fetchDistribuidor() {
    const current_date = new Date(moment.utc().format('DD MMMM YYYY'));
    /** Modal generíco cargando... */
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
      centered: true,
    };
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
    /**
     * De no encontrarse punto seleccionado sale de la pagina
     */
    if (!this.auth.punto_seleccionado) {
      this.modalCarga?.close();
      this.router.navigate(['distribuidores']);
      return;
    }
    /**
     * Recupera los detalles del distribuidor
     */
    this.rest
      .getJWT(`distribuidor/${this.id}`)
      .toPromise()
      .then((resp: any) => {
        this.distribuidor = resp;
        /**
         * Recupera los productos del distribuidor
         */
        this.rest
          .getJWT(`productos_por_distribuidor/distribuidor/${this.id}`)
          .toPromise()
          .then((resp_2: any) => {
            if (resp_2 && resp_2.productos.length > 0) {
              /** Flag para mostrar puntos feat si estan dentro del rango de fechas */
              resp_2.productos.forEach((producto: any) => {
                producto.flag_aplica_puntos_feat = false;
                if (
                  producto.fecha_apertura_puntosft &&
                  producto.fecha_cierre_puntosft &&
                  new Date(moment.utc(producto.fecha_apertura_puntosft).format('DD MMMM YYYY')) <= current_date &&
                  new Date(moment.utc(producto.fecha_cierre_puntosft).format('DD MMMM YYYY')) >= current_date
                ) {
                  producto.flag_aplica_puntos_feat = true;
                }
              });
              this.distribuidor['productos'] = resp_2.productos.filter((producto: any) => {
                return producto.estadoActualizacion === 'Aceptado';
              });
              for (let index = 0; index < 6; index++) {
                if (this.distribuidor.productos[index]) {
                  this.prods_filtrados.push(this.distribuidor.productos[index]);
                }
              }
            } else {
              this.distribuidor['productos'] = [];
            }
          });
        this.modalCarga?.close();
        this.getEstadoVinculacion();
      })
      .catch(() => {
        this.modalCarga?.close();
      });
  }

  /**
   * Recupera el estado de vinculación del distribuidor, si no esta aprobado sale de la pagina
   * y redirecciona al componente de no aprobados o vinculados
   */
  getEstadoVinculacion() {
    this.rest
      .getJWT(`distribuidores_vinculados_dist_punto/${this.id}/${this.auth.punto_seleccionado?._id}`)
      .toPromise()
      .then((resp_3: any) => {
        if (resp_3.data?.length > 0) {
          if ('estado' in resp_3.data[0]) {
            this.vinculacion = resp_3.data[0].estado;
          }
        }
        /**
         * Si ya es un distribuidor aprobado, lo redirige al componente aprobados
         */
        if (this.vinculacion != null && this.vinculacion === 'Aprobado') {
          this.router.navigateByUrl(`distribuidores/${this.id}`);
          return;
        }
      });
  }
  /******************** Filtros por categoría y linea producto *******************/
  /**
   * Recupera las categorías y lineas de productos activas del distribuidor
   */
  async fetchCategoriasYLineas() {
    /********************* Categorías ********************/
    const resp: any = await this.rest
      .getJWT(`categorias_productos_aprobados_distribuidor/distribuidor/${this.id}`)
      .toPromise();
    /** Elimina los objetos repetidos */
    this.categorias_productos = resp.filter((value: any, index: any) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        resp.findIndex((obj: any) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    this.categorias_productos = this.categorias_productos.sort(function (a: any, b: any) {
      if (a.nombre === undefined) return 1;
      if (b.nombre === undefined) return -1;
      if (a.nombre === b.nombre) return 0;
      return a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1;
    });
    /********************* Líneas ********************/
    const resp_lineas: any = await this.rest
      .getJWT(`lineas_productos_aprobados_distribuidor/distribuidor/${this.id}`)
      .toPromise();
    /** Elimina los objetos repetidos */
    this.lineas_productos = resp_lineas.filter((value: any, index: any) => {
      const _value = JSON.stringify(value);
      return (
        index ===
        resp_lineas.findIndex((obj: any) => {
          return JSON.stringify(obj) === _value;
        })
      );
    });
    this.lineas_productos = this.lineas_productos.sort(function (a: any, b: any) {
      if (a.nombre === undefined) return 1;
      if (b.nombre === undefined) return -1;
      if (a.nombre === b.nombre) return 0;
      return a.nombre.toLowerCase() < b.nombre.toLowerCase() ? -1 : 1;
    });
  }

  /**
   * Lanza un modal para seleccionar el punto de entrega
   * si este no está asociado al distribuidor saldra de esta pagina
   */
  selecionarPunto() {
    const ngbModalOptions: NgbModalOptions = {
      //Evita que al hacer click por fuera se cierre el modal
      backdrop: 'static',
      keyboard: false,
      centered: true,
      windowClass: 'modal-selecionar-punto',
    };
    const modalRef = this.modalService.open(SeleccionarPuntoEntregaComponent, ngbModalOptions);
    modalRef.componentInstance.distribuidor_id = this.distribuidor._id;
    modalRef.componentInstance.callback = () => {
      /**Recarga la información del distribuidor */
      this.ngOnInit();
    };
  }

  /**
   * Manda una solicitud de vinculación entre el punto de entrega y el
   * distribuidor cuyo índice entra por parámetro
   * @param index La posición en el arreglo de distribuidores del distribuidor a vincular
   */
  solicitarVinculacion() {
    const dist_cliente_preaproados = this.distribuidor.clientes_preaprobados;
    if (this.flag_vinculacion == true) {
      // Evita que se solicite doble la vinculación
      this.flag_vinculacion = false;
      const id_punto: string = this.auth.punto_seleccionado!._id!;
      const id_dist: string = this.distribuidor?._id;
      const id_trab: string = this.auth.user?._id || '';
      const obj_post: any = {
        estado: 'Pendiente',
        convenio: false,
        cartera: false,
        punto_entrega: id_punto,
        distribuidor: id_dist,
        vendedor: id_trab,
        pazysalvo: true,
      };
      for (const iterator of dist_cliente_preaproados) {
        if (iterator == this.userInfo.user_horeca.nit) {
          obj_post.estado = 'Aprobado';
        }
      }
      this.rest
        .postJWT('distribuidores_vinculados', obj_post)
        .toPromise()
        .then(() => {
          const ngbModalOptions: NgbModalOptions = {
            //Evita que al hacer click por fuera se cierre el modal
            backdrop: 'static',
            keyboard: false,
          };
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          if (obj_post.estado === 'Aprobado') {
            modalRef.componentInstance.msg = 'El distribuidor ha aprobado tu solicitud de vinculación.';
          } else {
            modalRef.componentInstance.msg = 'Te has vinculado al distribuidor seleccionado.';
          }
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            this.volver();
          };
        })
        .catch((err) => {
          console.log(err);
          const ngbModalOptions: NgbModalOptions = {
            //Evita que al hacer click por fuera se cierre el modal
            backdrop: 'static',
            keyboard: false,
          };
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
          modalRef.componentInstance.btn_msg = 'Volver';
        });
    }
  }

  /**
   * Alerta al usuario que no puede usar las funcionalidades
   * si no está vinculado y aprobado el distribuidor
   */
  alertaNoVinculacion() {
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg =
      '¡No podrás ver el portafolio o usar funcionalidades hasta que vincules y esté aprobado este distribuidor para el punto de entrega!';
    modalRef.componentInstance.btn_msg = 'Volver';
  }

  /**
   * Voler a modulo anterior
   * dependiendo de donde haya ingresado, volvera al modulo anterior
   */
  volver() {
    if (this.router.url == `/solicitudes-distribuidor/novinculado/${this.distribuidor._id}`) {
      this.router.navigate(['/solicitudes-distribuidor/']);
    } else {
      this.router.navigate(['/distribuidores']);
    }
  }
}
