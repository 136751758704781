import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { DeleteToken } from 'src/app/actions/token.actions';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { Trabajador } from 'src/app/models/trabajador.model';
import { UsuarioHoreca } from 'src/app/models/usuario-horeca.model';
import { tap } from 'rxjs/operators';
import { Observable, BehaviorSubject, throwError } from 'rxjs'; // Importa los operadores necesarios
import { environment } from 'src/environments/environment';
import { Distribuidor } from 'src/app/models/distribuidor.model';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

//import * as distribuitor_permissions from 'src/app/services/permissions_distribuidors.json'; //aqui es la ruta donde importas el archivo json
//import * as horeca_permissions from 'src/app/services/permissions_horeca'; //aqui es la ruta donde importas el archivo json

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public email = '';
  public token = '';
  public user?: Trabajador;
  public user_horeca?: UsuarioHoreca;
  public user_distribuidor?: Distribuidor;
  public punto_seleccionado?: PuntoEntrega;
  public user_organizacion?: any;
  public ciudades?: any;
  /** Rutas para autenticación */
  private authRoute = 'trabajador/authenticate';
  private authRouteORG = 'trabajador/organizacion/authenticate';

  /** Dirección del backend */
  private serverAddress = `${environment.backendUrl}/api/`;
  private tokenSubject = new BehaviorSubject<string | null>(null);
  public token$ = this.tokenSubject.asObservable();
  constructor(
    private http: HttpClient,
    private ngxsStore: Store,
    private localStorage: LocalStorageService,
    private modalService: NgbModal
  ) {
    const storedToken = this.ngxsStore.snapshot().auth.selectedToken;
      this.tokenSubject.next(storedToken);
      this.token = storedToken || ''; 
  }

  /**
   * Autentica al usuario indicado con la contraseña indicada
   * @param email Usuario a autenticar
   * @param password Contraseña del usuario a autenticar
   * @return Un objeto Observable para conocer el estado de la petición
   * de autenticación y cargar el resultado una vez se conozca
   */
  signIn(email: string, password: string) {
    /**
     * De no hacer logout, la info de punto de entrega queda en el local storage
     * se debe borrar antes de loguearse dado que esta info no debe ser visible a otro usuario
     */
    this.localStorage.removeItem('punto_entrega_seleccionado');
    this.localStorage.removeItem('punto_entrega_chat');
    this.localStorage.removeItem('chat_room_id');
    this.localStorage.removeItem('volver_pedir_pedido'),
    this.localStorage.removeItem('editar_pedido_curso'),
    this.localStorage.removeItem('cart'),
    this.localStorage.removeItem('pedidosug');
    this.punto_seleccionado = undefined;

    const body = {
      correo: email,
      clave: password,
    };
    return this.http.post(this.serverAddress + this.authRoute, body).pipe(
      tap((resp: any) => {
        if (resp && resp.token) {
          this.token = resp.token; // Actualiza this.token
          this.tokenSubject.next(resp.token); // Actualiza el BehaviorSubject
        }
      })
    );
  }

  /**
   * Autentica al usuario indicado con la contraseña indicada
   * @param email Usuario a autenticar
   * @param password Contraseña del usuario a autenticar
   * @return Un objeto Observable para conocer el estado de la petición
   * de autenticación y cargar el resultado una vez se conozca
   */
  signInOrganizacion(email: string, password: string) {
    const body = {
      correo: email,
      clave: password,
    };

    return this.http.post(this.serverAddress + this.authRouteORG, body);
  }
  /**
   * Valida permisos de usuario
   */
  async permissions_user(tipo_user: any, perfil: any, menu: any) {
    let url;
    if (tipo_user === 'organizacion') {
      url = 'trabajador_permisos_organizacion';
    }
    if (tipo_user === 'distribuidor') {
      url = 'trabajador_permisos_distribuidor';
    }
    if (tipo_user === 'horeca') {
      url = 'trabajador_permisos_horeca';
    }
    let httpHeaders: HttpHeaders = new HttpHeaders();
    httpHeaders = httpHeaders.append('Authorization', this.token || '');
    return this.http.get(`${this.serverAddress}${url}/${perfil}/${menu}`, { headers: httpHeaders });
  }
  validatePermissionsUser() {
    if (this.user?.tipo_trabajador === 'OPERATIVO COMERCIAL' || this.user?.tipo_trabajador === 'PLANEADOR PEDIDOS') {
      if (this.user?.tipo_trabajador === 'OPERATIVO COMERCIAL') {
        this.messageError();
        return false;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  messageError() {
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Debes tener un perfil de administrador para ingresar';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {};
  }
  /**
   * Cierra la sesión activa
   */
  signOut() {
    this.email = '';
    this.token = '';
    this.tokenSubject.next(null); // Limpia el BehaviorSubject
    this.user = undefined;
    this.user_horeca = undefined;
    this.user_distribuidor = undefined;
    this.user_organizacion = undefined;
    this.punto_seleccionado = undefined;
    this.localStorage.clearLocalStorage();
    this.ngxsStore.dispatch(new DeleteToken());
    this.localStorage.removeItem('punto_entrega_seleccionado');
    this.localStorage.removeItem('punto_entrega_chat');
    this.localStorage.removeItem('vistaTutorialFeatOrg');
    localStorage.removeItem('chat_room_id');
  }

  /**
   * Indica si hay algún usuario autenticado
   */
  isAuthenticated(): boolean {
    if (!this.token) { //verifica si el token ya fue cargado.
      const storedToken = this.ngxsStore.snapshot().auth.selectedToken;
      this.tokenSubject.next(storedToken);
      this.token = storedToken || ''; // Usar el token almacenado o cadena vacía si no existe
  }
  if (this.token == '' || this.email == '' || this.user == undefined) {
    this.email = this.ngxsStore.snapshot().auth.email;
    this.user = this.ngxsStore.snapshot().auth.user;
    this.user_horeca = this.ngxsStore.snapshot().auth.user_horeca;
    this.user_distribuidor = this.ngxsStore.snapshot().auth.user_distribuidor;
    this.user_organizacion = this.ngxsStore.snapshot().auth.user_organizacion;
  }

  return (
    this.token!= '' &&
    this.email!= '' &&
    this.user!= undefined &&
    (this.user_horeca!= undefined || this.user_distribuidor!= undefined || this.user_organizacion!= undefined)
  );
  }

  /**
   * Actualizar contraseña
   * @param data son las contraseñas (vieja y nueva)
   * @param id es el id del trabajador al que se le va a actualizar la contraseña
   */
  public updatePassword = (data: any, id: string): Observable<any> => {
    return this.http.put(`${environment.backendUrl}/api/trabajador/cambiarcontrasena/${id}`, data, {
      headers: new HttpHeaders({ Authorization: this.token }),
    });
  };
  /**
   * Actualizar contraseña
   * @param data son las contraseñas (vieja y nueva)
   * @param id es el id del trabajador al que se le va a actualizar la contraseña
   */
  public updatePasswordAdmin = (data: any, id: string): Observable<any> => {
    return this.http.put(`${environment.backendUrl}/api/trabajador/cambiarcontrasenaAdmin/${id}`, data, {
      headers: new HttpHeaders({ Authorization: this.token }),
    });
  };
}
