<app-header-distribuidor></app-header-distribuidor>
<br />
<section class="contenido">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
    <li [ngbNavItem]="1">
      <a ngbNavLink>Todos</a>
      <ng-template ngbNavContent>
        <app-clientes-info></app-clientes-info>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>Gráficas</a>
      <ng-template ngbNavContent>
        <app-clientes-graficas></app-clientes-graficas>
      </ng-template>
    </li>
    <li [ngbNavItem]="3" style="display: none;">
      <a ngbNavLink>Clientes Activos</a>
      <ng-template ngbNavContent>
      </ng-template>
    </li>
    <li [ngbNavItem]="4" style="display: none;">
      <a ngbNavLink>Clientes Nuevos</a>
      <ng-template ngbNavContent>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</section>