import { Component, OnInit } from '@angular/core';
import { RestService } from '../../services/rest/rest.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import * as XLSX from 'xlsx';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { LocalDataSource } from 'ng2-smart-table';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';

@Component({
  selector: 'app-informes-general',
  templateUrl: './informes-general.component.html',
  styleUrls: ['./informes-general.component.css'],
})
export class InformesGeneralComponent implements OnInit {
  /** ID horeca */
  public id_horeca = this.authService.user_horeca!._id;
  /** Datos y configuración de la tabla */
  public settings_distribuidores: any = {};
  public settings_puntos_entrega: any = {};
  public settings_usuarios: any = {};
  public settings_puntos_feat: any = {};
  public settings_pedidos: any = {};
  public data_distribuidores: any = [];
  public data_puntos_entrega: any = [];
  public data_usuarios: any = [];
  public data_puntos_feat: any = [];
  public data_pedidos: any = [];
  public source_distribuidores?: LocalDataSource;
  public source_puntos_entrega?: LocalDataSource;
  public source_puntos_feat?: LocalDataSource;
  public source_usuarios?: LocalDataSource;
  public source_pedidos?: LocalDataSource;
  /** Modal de carga para darle feedback al usuario */
  public modalCarga?: NgbModalRef;
  /** Evita que al hacer click por fuera se cierre el modal */
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };

  constructor(
    private restService: RestService,
    private currency: CurrencyPipe,
    public authService: AuthService,
    private ngxsStore: Store,
    private datePipe: DatePipe,
    private router: Router,
    private modalService: NgbModal
  ) {}

  async ngOnInit() {
    this.id_horeca = await this.ngxsStore.snapshot().auth.user_horeca._id;
    /** Modal generíco cargando... */
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    /** Configuración tabla */
    this.configSmartTableDistribuidores();
    this.configSmartTableUsuarios();
    this.configSmartTablePuntoEntrega();
    this.configSmartTablePuntosFeat();
    this.configSmartTablePedidos();
    /** Carga datos a tabla */
    await this.cargarDatosTablaDistribuidores();
    await this.cargarDatosTablaUsuarios();
    await this.cargarDatosTablaPuntoEntrega();
    await this.cargarDatosTablaPuntosFeat();
    await this.cargarDatosTablaPedidos();
    /** Cerrar Modal generíco cargando... */
    this.modalCarga?.close();
  }

  /**************************************** Distribuidores ***************************************/
  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTableDistribuidores(): void {
    this.settings_distribuidores = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      noDataMessage: 'AÚN NO TIENES DISTRIBUIDORES VINCULADOS',
      hideSubHeader: false,
      columns: {
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        punto_entrega: {
          title: 'Punto de entrega',
          editable: false,
          filter: true,
        },
        pais: {
          title: 'País',
          editable: false,
          filter: true,
        },
        departamento: {
          title: 'Departamento',
          editable: false,
          filter: true,
        },
        ciudad: {
          title: 'Ciudad',
          editable: false,
          filter: true,
        },
        estado: {
          title: 'Estado vinculación',
          editable: false,
          filter: true,
        },
        descuento: {
          title: 'Precio especial',
          editable: false,
          filter: true,
        },
        cartera: {
          title: 'Cartera',
          editable: false,
          filter: true,
        },
        razon_social: {
          title: 'Razón social',
          editable: false,
          filter: true,
        },
        tipo_persona: {
          title: 'Tipo de persona',
          editable: false,
          filter: true,
        },
        nit_cc: {
          title: 'NIT/CC/CE',
          editable: false,
          filter: true,
        },
        tipo_distribuidor: {
          title: 'Tipo de distribuidor',
          editable: false,
          filter: true,
        },
        valor_minimo_pedido: {
          title: 'Valor mín. Pedido (COP)',
          editable: false,
          filter: true,
        },
        tiempo_entrega: {
          title: 'Tiempo de Entrega',
          editable: false,
          filter: true,
        },
        cantidad_pedidos: {
          title: 'Pedidos Comprados y Entregados (últimos 3 meses)',
          editable: false,
          filter: true,
        },
        calificacion_abastecimiento: {
          title: 'Calificación del punto por Abastecimiento',
          editable: false,
          filter: true,
        },
        calificacion_precio: {
          title: 'Calificación del punto por Precio',
          editable: false,
          filter: true,
        },
        calificacion_puntualidad_entrega: {
          title: 'Calificación del punto por Puntualidad de Entrega',
          editable: false,
          filter: true,
        },
        equipo_comercial: {
          title: 'Equipo comercial asignado',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaDistribuidores(): void {
    this.restService
      .getJWT('informes/horeca/tabla/distribuidores/' + this.id_horeca)
      .toPromise()
      .then((distribuidores: any) => {
        distribuidores.data.forEach((distribuidor: any) => {
          if (distribuidor.distribuidor_nombre == null) {
            return;
          }
          const obj_pedido_distribuidores = {
            distribuidor: distribuidor.distribuidor_nombre,
            punto_entrega: distribuidor.punto_nombre,
            descuento: distribuidor.vinculacion_convenio === true ? 'Si' : 'No',
            cartera: distribuidor.vinculacion_cartera === true ? 'Si' : 'No',
            razon_social: distribuidor.distribuidor_razon_social,
            tipo_persona: distribuidor.distribuidor_tipo_persona,
            tipo_distribuidor: distribuidor.distribuidor_tipo,
            nit_cc: distribuidor.distribuidor_nit_cc,
            valor_minimo_pedido:
              distribuidor.distribuidor_valor_minimo_pedido != undefined
                ? distribuidor.distribuidor_valor_minimo_pedido
                : 0,
            tiempo_entrega: distribuidor.distribuidor_tiempo_entrega,
            pais: 'Colombia',
            departamento: distribuidor.distribuidor_departamento,
            ciudad: distribuidor.distribuidor_ciudad,
            cantidad_pedidos: distribuidor.pedidos_entregados_total,
            calificacion_abastecimiento: 0,
            calificacion_precio: 0,
            calificacion_puntualidad_entrega: 0,
            equipo_comercial: distribuidor.vendedor_nombre.join(' - '),
            estado: distribuidor.vinculacion_estado,
          };
          /** Se arma data de calificación */
          const calificacion_abastecimiento: any[] = [];
          const calificacion_precio: any[] = [];
          const calificacion_puntualidad_entrega: any[] = [];
          if (distribuidor.pedido_todos_calificados) {
            distribuidor.pedido_todos_calificados.pedido_calificacion.forEach((pedido: any) => {
              if (pedido.pedido_estado === 'Calificado') {
                calificacion_abastecimiento.push(Number(pedido.pedido_calificacion.abastecimiento));
                calificacion_precio.push(Number(pedido.pedido_calificacion.precio));
                calificacion_puntualidad_entrega.push(Number(pedido.pedido_calificacion.puntualidad_entrega));
              }
            });
            obj_pedido_distribuidores.calificacion_abastecimiento =
              Math.round(
                (calificacion_abastecimiento.reduce((a, b) => a + b, 0) / calificacion_abastecimiento.length || 0) * 100
              ) / 100;
            obj_pedido_distribuidores.calificacion_puntualidad_entrega =
              Math.round(
                (calificacion_puntualidad_entrega.reduce((a, b) => a + b, 0) /
                  calificacion_puntualidad_entrega.length || 0) * 100
              ) / 100;
            obj_pedido_distribuidores.calificacion_precio =
              Math.round((calificacion_precio.reduce((a, b) => a + b, 0) / calificacion_precio.length || 0) * 100) /
              100;
          }
          /** Se arega objeto-fila a la tabla */
          this.data_distribuidores.push(obj_pedido_distribuidores);
        });
        /** Se carga la tabla al template del DOM */
        this.source_distribuidores = new LocalDataSource(this.data_distribuidores);
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Descargue a excel listado de Usuarios
   */
  descargaArchivoDistribuidores() {
    const primeraFila: any[] = [
      'Distribuidor',
      'Punto de entrega',
      'País',
      'Departamento',
      'Ciudad',
      'Estado vinculación',
      'Precio especial',
      'Cartera',
      'Razón social',
      'Tipo de persona',
      'NIT/CC/CE',
      'Tipo de distribuidor',
      'Valor mín. Pedido',
      'Tiempo de Entrega',
      'Pedidos Comprados y Entregados (últimos 3 meses)',
      'Calificación del punto por Abastecimiento',
      'Calificación del punto por Precio',
      'Calificación del punto por Puntualidad de Entrega',
      'Equipo comercial asignado',
    ];
    const object_keys = [
      'distribuidor',
      'punto_entrega',
      'pais',
      'departamento',
      'ciudad',
      'estado',
      'descuento',
      'cartera',
      'razon_social',
      'tipo_persona',
      'nit_cc',
      'tipo_distribuidor',
      'valor_minimo_pedido',
      'tiempo_entrega',
      'cantidad_pedidos',
      'calificacion_abastecimiento',
      'calificacion_precio',
      'calificacion_puntualidad_entrega',
      'equipo_comercial',
    ];
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primeraFila);
    this.data_distribuidores.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Distribuidores.xlsx` });
  }

  /********************************************** Usuarios *********************************************/
  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTableUsuarios(): void {
    this.settings_usuarios = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      noDataMessage: 'AÚN NO TIENES USUARIOS',
      hideSubHeader: false,
      columns: {
        tipo_usuario: {
          title: 'Tipo de usuario',
          editable: false,
          filter: true,
        },
        estado: {
          title: 'Estado',
          editable: false,
          filter: true,
        },
        nombre: {
          title: 'Nombre(s)',
          editable: false,
          filter: true,
        },
        apellidos: {
          title: 'Apellidos',
          editable: false,
          filter: true,
        },
        tipo_documento: {
          title: 'Tipo de documento',
          editable: false,
          filter: true,
        },
        numero_documento: {
          title: 'Número de documento',
          editable: false,
          filter: true,
        },
        correo: {
          title: 'Correo',
          editable: false,
          filter: true,
        },
        celular: {
          title: 'Telefono movil',
          editable: false,
          filter: true,
        },
        telefono: {
          title: 'Telefono',
          editable: false,
          filter: true,
        },
        puntos: {
          title: 'Puntos de entrega asignados',
          editable: false,
          filter: true,
        },
        total_pedidos: {
          title: '# Pedidos generados',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaUsuarios(): void {
    this.restService
      .getJWT('informes/horeca/tabla/usuarios/' + this.id_horeca)
      .toPromise()
      .then((trabajadores: any) => {
        trabajadores.data.forEach((trabajador: any) => {
          const obj_pedido_usuarios = {
            tipo_usuario: trabajador.trabajador_tipo,
            estado: trabajador.trabajador_estado,
            nombre: trabajador.trabajador_nombres,
            apellidos: trabajador.trabajador_apellidos,
            tipo_documento: trabajador.trabajador_tipo_documento,
            numero_documento: trabajador.trabajador_numero_documento,
            correo: trabajador.trabajador_correo,
            celular: trabajador.trabajador_celular,
            telefono: trabajador.trabajador_telefono,
            puntos: '',
            total_pedidos: trabajador.data_pedido[0] ? trabajador.data_pedido[0].total : 0,
          };
          /** Se construye string de puntos de entrega del trabajador */
          if (trabajador.trabajador_puntos_entrega.length == 1) {
            obj_pedido_usuarios.puntos = trabajador.trabajador_puntos_entrega[0].punto_nombre;
          } else if (trabajador.trabajador_puntos_entrega.length > 1) {
            obj_pedido_usuarios.puntos =
              trabajador.trabajador_puntos_entrega[0].punto_nombre +
              '  +' +
              (trabajador.trabajador_puntos_entrega.length - 1);
          }
          /** Se arega objeto-fila a la tabla */
          this.data_usuarios.push(obj_pedido_usuarios);
        });
        this.source_usuarios = new LocalDataSource(this.data_usuarios);
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Descargue a excel listado de Usuarios
   */
  descargaArchivoUsuarios() {
    const primeraFila: any[] = [
      'Tipo de usuario',
      'Estado',
      'Nombre(s)',
      'Apellidos',
      'Tipo de documento',
      'Número de documento',
      'Correo',
      'Telefono movil',
      'Telefono',
      'Puntos de entrega asignados',
      '# Pedidos generados',
    ];
    const object_keys = [
      'tipo_usuario',
      'estado',
      'nombre',
      'apellidos',
      'tipo_documento',
      'numero_documento',
      'correo',
      'celular',
      'telefono',
      'puntos',
      'total_pedidos',
    ];
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primeraFila);
    this.data_usuarios.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Usuarios.xlsx` });
  }

  /****************************************** Puntos Entrega *****************************************/
  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTablePuntoEntrega(): void {
    this.settings_puntos_entrega = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      noDataMessage: 'AÚN NO TIENES PUNTOS DE ENTREGA',
      hideSubHeader: false,
      columns: {
        orden_punto_entrega: {
          title: 'Punto de entrega',
          editable: false,
          filter: true,
        },
        nombre_punto_entrega: {
          title: 'Nombre punto de entrega',
          editable: false,
          filter: true,
        },
        pais: {
          title: 'Pais',
          editable: false,
          filter: true,
        },
        departamento: {
          title: 'Departamento',
          editable: false,
          filter: true,
        },
        ciudad: {
          title: 'Ciudad',
          editable: false,
          filter: true,
        },
        direccion: {
          title: 'Dirección',
          editable: false,
          filter: true,
        },
        nombre_encargado: {
          title: 'Nombre encargado punto de venta',
          editable: false,
          filter: true,
        },
        celular: {
          title: 'Teléfono móvil',
          editable: false,
          filter: true,
        },
        correo: {
          title: 'Correo de ingreso',
          editable: false,
          filter: true,
        },
        numero_sillas: {
          title: 'Número de sillas',
          editable: false,
          filter: true,
        },
        domicilios: {
          title: 'Domicilio',
          editable: false,
          filter: true,
        },
        numero_trabajadores: {
          title: '# equipo de trabajadores asignados',
          editable: false,
          filter: true,
        },
        numero_distribuidores_vinculados: {
          title: '# distribuidores vinculados',
          editable: false,
          filter: true,
        },
        valor_pedidos: {
          title: 'Valor pedidos (últ. 3 meses COP)',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaPuntoEntrega(): void {
    this.restService
      .getJWT('informes/horeca/tabla/puntos_entrega/' + this.id_horeca)
      .toPromise()
      .then((puntos_entrega: any) => {
        let index = 1;
        puntos_entrega.data.forEach((punto_entrega: any) => {
          console.log('punto_entrega', punto_entrega)
          let nombre_encargado: string = '';

              if (punto_entrega && punto_entrega.punto_informacion_contacto && punto_entrega.punto_informacion_contacto.length >= 2) {
                  nombre_encargado = punto_entrega.punto_informacion_contacto[0] + ' ' + punto_entrega.punto_informacion_contacto[1];
              }
          const obj_pedido_puntos_entrega = {
            orden_punto_entrega: index,
            nombre_punto_entrega: punto_entrega.punto_nombre,
            pais: punto_entrega.punto_pais,
            departamento: punto_entrega.punto_departamento,
            ciudad: punto_entrega.punto_ciudad,
            direccion: punto_entrega.punto_direccion,
            nombre_encargado: nombre_encargado,
            celular: punto_entrega.punto_informacion_contacto?punto_entrega.punto_informacion_contacto[3]:'',
            correo: punto_entrega.punto_informacion_contacto?punto_entrega.punto_informacion_contacto[2]:'',
            numero_sillas: punto_entrega.punto_sillas,
            domicilios: punto_entrega.punto_domicilios === true ? 'Si' : 'No',
            numero_trabajadores:
              punto_entrega.punto_total_trabajadores.length > 0 ? punto_entrega.punto_total_trabajadores[0].total : 0,
            numero_distribuidores_vinculados:
              punto_entrega.punto_total_dist_aprobados.length > 0
                ? punto_entrega.punto_total_dist_aprobados[0].total
                : 0,
            valor_pedidos:
              punto_entrega.punto_total_venta_tres_meses.length > 0
                ? punto_entrega.punto_total_venta_tres_meses[0].total
                : 0,
          };
          index++;
          /** Se arega objeto-fila a la tabla */
          this.data_puntos_entrega.push(obj_pedido_puntos_entrega);
        });
        this.source_puntos_entrega = new LocalDataSource(this.data_puntos_entrega);
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Descargue a excel listado de Usuarios
   */
  descargaArchivoPuntosEntrega() {
    const primeraFila: any[] = [
      'Punto de entrega',
      'Nombre punto de entrega',
      'Pais',
      'Departamento',
      'Ciudad',
      'Dirección',
      'Nombre encargado punto de venta',
      'Teléfono móvil',
      'Correo de ingreso',
      'Número de sillas',
      'Domicilio',
      '# equipo de trabajadores asignados',
      '# distribuidores vinculados',
      'Valor pedidos (últ. 3 meses COP)',
    ];
    const object_keys = [
      'orden_punto_entrega',
      'nombre_punto_entrega',
      'pais',
      'departamento',
      'ciudad',
      'direccion',
      'nombre_encargado',
      'celular',
      'correo',
      'numero_sillas',
      'domicilios',
      'numero_trabajadores',
      'numero_distribuidores_vinculados',
      'valor_pedidos',
    ];
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primeraFila);
    this.data_puntos_entrega.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Puntos de Entrega.xlsx` });
  }

  /********************************************** Puntos Feat *********************************************/
  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTablePuntosFeat(): void {
    this.settings_puntos_feat = {
      pager: {
        display: true,
        perPage: 10,
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      noDataMessage: 'AÚN NO TIENES PUNTOS',
      columns: {
        punto_entrega: {
          title: 'Punto de entrega',
          editable: false,
          filter: true,
        },
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        nit: {
          title: 'NIT/CC/CE',
          editable: false,
          filter: true,
        },
        fecha: {
          title: 'Fecha del pedido',
          editable: false,
          filter: true,
        },
        pedido_id: {
          title: 'ID del pedido',
          editable: false,
          filter: true,
        },
        estado: {
          title: 'Estado del pedido',
          editable: false,
          filter: true,
        },
        categoria: {
          title: 'Categoría del producto',
          editable: false,
          filter: true,
        },
        linea: {
          title: 'Línea del producto',
          editable: false,
          filter: true,
        },
        organizacion: {
          title: 'Organización del producto',
          editable: false,
          filter: true,
        },
        marca: {
          title: 'Marca del producto',
          editable: false,
          filter: true,
        },
        codigo_sku: {
          title: 'Código SKU',
          editable: false,
          filter: true,
        },
        nombre_producto: {
          title: 'Producto',
          editable: false,
          filter: true,
        },
        precio_producto: {
          title: 'Precio (COP)',
          editable: false,
          filter: true,
        },
        puntos_ft_unidad: {
          title: 'Puntos FT Unidad',
          editable: false,
          filter: true,
        },
        unidades_pedidas: {
          title: 'Unidades pedidas',
          editable: false,
          filter: true,
        },
        valor_total: {
          title: 'Valor total (COP)',
          editable: false,
          filter: true,
        },
        puntos_ft_acumulados: {
          title: 'Puntos FT Acumulados',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaPuntosFeat(): void {
    this.restService
      .getJWT('informes/horeca/tabla/puntos_feat/' + this.id_horeca)
      .toPromise()
      .then((puntos_feat: any) => {
        puntos_feat.data.forEach((punto_feat: any) => {
          const obj_pedido_puntos_feat = {
            punto_entrega: punto_feat.punto_entrega,
            distribuidor: punto_feat.distribuidor,
            nit: punto_feat.nit,
            fecha: this.datePipe.transform(punto_feat.fecha_pedido, 'yyyy-MM-dd'),
            pedido_id: punto_feat.pedido_id,
            estado: punto_feat.estado_pedido,
            categoria: punto_feat.categoria,
            linea: punto_feat.linea,
            organizacion: punto_feat.organizacion,
            marca: punto_feat.marca,
            codigo_sku: punto_feat.codigo_sku,
            nombre_producto: punto_feat.nombre_producto,
            precio_producto: punto_feat.precio_producto,
            puntos_ft_unidad: punto_feat.puntos_ft_unidad,
            unidades_pedidas: punto_feat.unidades_pedidas,
            valor_total: punto_feat.valor_total,
            puntos_ft_acumulados: punto_feat.puntos_ft_acumulados,
          };
          this.data_puntos_feat.push(obj_pedido_puntos_feat);
        });
        this.source_puntos_feat = new LocalDataSource(this.data_puntos_feat);
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Descargue a excel listado de Puntos Feat
   */
  descargaArchivoPuntosFeat() {
    const primeraFila: any[] = [
      'Punto de entrega',
      'Distribuidor',
      'NIT/CC/CE',
      'Fecha del pedido',
      'ID del pedido',
      'Estado del pedido',
      'Categoría del producto',
      'Línea del producto',
      'Organización del producto',
      'Marca del producto',
      'Código SKU',
      'Producto',
      'Precio (COP)',
      'Puntos FT Unidad',
      'Unidades pedidas',
      'Valor total (COP)',
      'Puntos FT Acumulados',
    ];
    const object_keys = [
      'punto_entrega',
      'distribuidor',
      'nit',
      'fecha',
      'pedido_id',
      'estado',
      'categoria',
      'linea',
      'organizacion',
      'marca',
      'codigo_sku',
      'nombre_producto',
      'precio_producto',
      'puntos_ft_unidad',
      'unidades_pedidas',
      'valor_total',
      'puntos_ft_acumulados',
    ];
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primeraFila);
    this.data_puntos_feat.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Puntos Feat.xlsx` });
  }

  /********************************************** Pedidos *********************************************/
  /**
   * Configura los titulos y demas settings de la tabla
   * para recibir información de la base de datos
   */
  private configSmartTablePedidos(): void {
    this.settings_pedidos = {
      pager: {
        display: true,
        perPage: 10,
      },
      noDataMessage: 'AÚN NO TIENES PEDIDOS',
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
      },
      hideSubHeader: false,
      columns: {
        punto_entrega: {
          title: 'Punto de entrega',
          editable: false,
          filter: true,
        },
        numero_pedido: {
          title: 'Número Pedido',
          editable: false,
          filter: true,
        },
        fecha: {
          title: 'Fecha de Pedido',
          editable: false,
          filter: true,
        },
        estado: {
          title: 'Estado actual',
          editable: false,
          filter: true,
        },
        distribuidor: {
          title: 'Distribuidor',
          editable: false,
          filter: true,
        },
        nit_cc: {
          title: 'NIT/CC/CE',
          editable: false,
          filter: true,
        },
        categoria_producto: {
          title: 'Categoría de producto',
          editable: false,
          filter: true,
        },
        linea_producto: {
          title: 'Línea de producto',
          editable: false,
          filter: true,
        },
        marca: {
          title: 'Marca',
          editable: false,
          filter: true,
        },
        organizacion: {
          title: 'Organización',
          editable: false,
          filter: true,
        },
        cod_organizacion: {
          title: 'Código producto organización',
          editable: false,
          filter: true,
        },
        cod_producto: {
          title: 'Código producto distribuidor',
          editable: false,
          filter: true,
        },
        prod_nombre: {
          title: 'Nombre producto',
          editable: false,
          filter: true,
        },
        prod_tamano: {
          title: 'Tamaño/Cantidad',
          editable: false,
          filter: true,
        },
        prod_und_med: {
          title: 'Unidad de medida',
          editable: false,
          filter: true,
        },
        prod_cajas_solicitadas: {
          title: 'Cajas compradas',
          editable: false,
          filter: true,
        },
        prod_unidades_solicitadas: {
          title: 'Unidades compradas',
          editable: false,
          filter: true,
        },
        prod_valor: {
          title: 'Valor costo (COP)',
          editable: false,
          filter: true,
        },
        puntos_feat_acumulados: {
          title: 'Puntos Feat acumulados',
          editable: false,
          filter: true,
        },
        vendedor: {
          title: 'Vendedor distribuidor asignado',
          editable: false,
          filter: true,
        },
        tiempo_entrega: {
          title: 'Tiempo de entrega (entre el Aprobado y el Entregado)',
          editable: false,
          filter: true,
        },
      },
    };
  }

  /**
   * Se cargan los datos recuperados de la base de datos en la tabla
   */
  private cargarDatosTablaPedidos(): void {
    this.restService
      .getJWT('informes/horeca/tabla/pedidos/' + this.id_horeca)
      .toPromise()
      .then((pedidos: any) => {
        pedidos.data.forEach((pedido: any) => {
          const obj_pedido_pedidos = {
            punto_entrega: pedido.punto_nombre,
            numero_pedido: pedido.pedido_id,
            fecha: this.datePipe.transform(pedido.pedido_fecha, 'yyyy-MM-dd'),
            estado: pedido.pedido_estado_actual,
            distribuidor: pedido.producto_distribuidor_nombre,
            nit_cc: pedido.producto_distribuidor_nit_cc,
            categoria_producto: pedido.producto_categoria_nombre,
            linea_producto: pedido.producto_linea_nombre,
            marca: pedido.producto_marca_nombre,
            organizacion: pedido.producto_organizacion_nombre,
            cod_organizacion: pedido.producto_codigo_organizacion,
            cod_producto: pedido.producto_codigo_distribuidor,
            prod_nombre: pedido.producto_nombre,
            prod_tamano: pedido.producto_precios[0].cantidad_medida,
            prod_und_med: pedido.producto_precios[0].unidad_medida,
            prod_cajas_solicitadas:
              pedido.producto_precios[0].und_x_caja > 0
                ? Math.round((pedido.pedido_unidades_compradas / pedido.producto_precios[0].und_x_caja) * 100) / 100
                : 0,
            prod_unidades_solicitadas: pedido.pedido_unidades_compradas,
            prod_valor: pedido.pedido_costo * pedido.pedido_unidades_compradas,
            puntos_feat_acumulados: pedido.pedido_puntosFT_unidad * pedido.pedido_unidades_compradas,
            vendedor: pedido.vendedores_asignados ? pedido.vendedores_asignados.join('-') : '',
            tiempo_entrega: '',
          };
          /** Arma el tiempo de entrega de un pedido entregado */
          if (pedido.pedido_fecha_entregado) {
            const fecha_entrega: any = new Date(pedido.pedido_fecha_entregado);
            const fecha_pedido: any = new Date(pedido.pedido_fecha);
            const fecha_diferencia = Math.floor((fecha_entrega - fecha_pedido) / 60e3);
            if (fecha_diferencia > 1440) {
              obj_pedido_pedidos.tiempo_entrega = Math.floor(fecha_diferencia / 1440) + ' día(s)';
            } else if (fecha_diferencia > 60) {
              obj_pedido_pedidos.tiempo_entrega = Math.floor(fecha_diferencia / 60) + ' hora(s)';
            } else if (fecha_diferencia > 0) {
              obj_pedido_pedidos.tiempo_entrega = Math.floor(fecha_diferencia) + ' minuto(s)';
            }
          }
          this.data_pedidos.push(obj_pedido_pedidos);
        });
        this.source_pedidos = new LocalDataSource(this.data_pedidos);
      })
      .catch((err) => {
        console.log(err);
        this.mostrarMensajeError();
      });
  }

  /**
   * Descargue a excel listado de pedidos
   */
  descargaArchivoPedidos() {
    const primeraFila: any[] = [
      'Punto de entrega',
      'Número Pedido',
      'Fecha de Pedido',
      'Estado actual',
      'Distribuidor',
      'NIT/CC/CE',
      'Categoría de producto',
      'Línea de producto',
      'Marca',
      'Organización',
      'Código producto organización',
      'Código producto distribuidor',
      'Nombre producto',
      'Tamaño/Cantidad',
      'Unidad de medida',
      'Cajas compradas',
      'Unidades compradas',
      'Valor costo (COP)',
      'Puntos Feat acumulados',
      'Vendedor distribuidor asignado',
      'Tiempo de entrega (entre el Aprobado y el Entregado)',
    ];
    const object_keys = [
      'punto_entrega',
      'numero_pedido',
      'fecha',
      'estado',
      'distribuidor',
      'nit_cc',
      'categoria_producto',
      'linea_producto',
      'marca',
      'organizacion',
      'cod_organizacion',
      'cod_producto',
      'prod_nombre',
      'prod_tamano',
      'prod_und_med',
      'prod_cajas_solicitadas',
      'prod_unidades_solicitadas',
      'prod_valor',
      'puntos_feat_acumulados',
      'vendedor',
      'tiempo_entrega',
    ];
    /** Arma el contenido de la tabla con titulos*/
    const arreglo_final: any[] = [];
    arreglo_final.push(primeraFila);
    this.data_pedidos.forEach((element: any) => {
      const array = [];
      for (const column of object_keys) {
        array.push(element[column]);
      }
      arreglo_final.push(array);
    });
    this.DescargaExcel({ data: arreglo_final, nombreArchivo: `Pedidos.xlsx` });
  }

  /**
   * Descargue a excel un listado
   */
  public DescargaExcel(obj: any) {
    const readyToExport = obj.data;
    const workBook = XLSX.utils.book_new(); // create a new blank book
    const workSheet = XLSX.utils.aoa_to_sheet(readyToExport);
    XLSX.utils.book_append_sheet(workBook, workSheet, 'data'); // add the worksheet to the book
    XLSX.writeFile(workBook, obj.nombreArchivo); // initiate a file download in browser
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio']);
    };
  }
}
