<div class="mb-4 w-100">
  <h5>Indicadores de tus pedidos del mes</h5>
</div>
<!--Indicadores de los pedidos-->
<div class="indicadores">
  <div class="card-info" *ngFor="let indi of indicadores">
    <h3 class="m-0">{{ indi.cantidad }}</h3>
    <span>{{indi._id}}</span>
  </div>
</div>
<div class="w-100 ml-1 mt-4">
  <h6>Estados del pedido</h6>
</div>
<!--Estados para filtrar la tabla-->
<div class="mt-3">
  <div class="col p-0 col-md-6 col-lg-7 col-xl-12">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills">
      <li [ngbNavItem]="1" (click)="filtrarPorEstado('curso','1')">
        <a ngbNavLink>Curso</a>
      </li>
      <li [ngbNavItem]="2" (click)="filtrarPorEstado('historico','1')">
        <a ngbNavLink>Historico</a>
      </li>
     <!--<li [ngbNavItem]="3" (click)="filtrarPorEstado('aprobados')">
        <a ngbNavLink>Aprobados</a>
      </li>
      <li [ngbNavItem]="4" (click)="filtrarPorEstado('alistando')">
        <a ngbNavLink>Alistando</a>
      </li>
      <li [ngbNavItem]="5" (click)="filtrarPorEstado('despachados')">
        <a ngbNavLink>Despachado</a>
      </li>
      <li [ngbNavItem]="6" (click)="filtrarPorEstado('entregados')">
        <a ngbNavLink>Entregado</a>
      </li>-->
    </ul>
  </div>
</div>
<!--Tabla de pedidos-->
<div class="card">
  <div *ngIf="loading" class="loading-overlay">
    <div class="spinner"></div>
    <span>Cargando...</span>
  </div>
  <div  *ngIf="!loading  && totalClientes" class="card-body scroll-tabla">
    <ng2-smart-table [settings]="settings" [source]="source" (custom)="onCustom($event)"></ng2-smart-table>
  </div>
 



  <!--Paginador-->
  <div class="paginator"  *ngIf="!loading && totalClientes && totalClientes !== 1">
    <button class="paginator-btn" (click)="goToPage(1)">
      <fa-icon [icon]="faChevronLeft"></fa-icon>
      <fa-icon [icon]="faChevronLeft"></fa-icon>
    </button> <!-- Flecha al inicio -->
    <button class="paginator-btn" (click)="goToPage(currentPage - 1)">
      <fa-icon [icon]="faChevronLeft"></fa-icon>
    </button> <!-- Flecha atrás -->
    
    <!-- Números de página -->
    <button class="paginator-btn" *ngFor="let page of pageNumbers" (click)="goToPage(page)"
    [ngClass]="{'active': page === currentPage}"
    >
      {{ page }}
    </button>
  
    <button class="paginator-btn" (click)="goToPage(currentPage + 1)">
      <fa-icon [icon]="faChevronRigth"></fa-icon>
    </button> <!-- Flecha adelante -->
    <button class="paginator-btn" (click)="goToPage(totalPaginas)">
      <fa-icon [icon]="faChevronRigth"></fa-icon>
      <fa-icon [icon]="faChevronRigth"></fa-icon>

    </button> <!-- Flecha al final -->
  </div>
</div>