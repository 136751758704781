<div class="contenedor py-4">
  <div class="header">
    <div>
      <img *ngIf="authService.user_horeca?.logo" [src]="authService.user_horeca?.logo || ''" alt="Logo de la empresa" />
      <div>
        <h5>{{ authService.user_horeca!.nombre_establecimiento }}</h5><br *ngIf="authService.user_horeca?.logo" >
        <span>¡Hola {{ authService.user!.nombres }}!</span>
      </div>
    </div>
    <div class="cargo py-1">
      <span>{{ authService.user!.tipo_trabajador | titlecase }}</span>
    </div>
  </div>
  <hr />
  <div>
    <div class="info">
      <img src="assets/img/icons/icon-users.png" alt="trabajadores aprobados" />
      <span>{{ trabajadores_aprobados.length }} trabajadores en tu equipo</span>
    </div>
    <div class="info">
      <img src="assets/img/icons/icon-store.png" alt="puntos de entrega" />
      <span>{{ puntos_entrega.length }} puntos de entrega</span>
    </div>
    <button (click)="onContrasena()" style="font-size:12px">Cambiar contraseña</button>
    <div class="info" style="cursor: pointer;" (click)="authService.signOut()" routerLink="/inicio">
      <fa-icon [icon]="faSignOutAlt" size="lg" color="#41403E">
      </fa-icon>
      <span>Cerrar sesión</span>
    </div>
  </div>
</div>