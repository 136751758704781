<div class="inicio row align-items-center rounded m-0 p-2">
  <div class="saludo col-12 col-md-6 col-lg-12 row m-0" routerLink="/cuenta" style="cursor: pointer;">
    <div *ngIf='auth.user_horeca?.logo' class="col-2 d-flex justify-items-center align-items-center p-0" routerLink="/cuenta" style="cursor: pointer;">
      <img [src]="auth.user_horeca?.logo || ''" class="logo p-0" alt="Logo de la empresa" />
    </div>
    <div class="col-{{ auth.user_horeca?.logo ? '7' : '9' }}  nombre-empresa pt-2">
      <p class="main-title" routerLink="/cuenta" style="cursor: pointer;">
        {{ auth.user_horeca!.nombre_establecimiento! }}
      </p>
      <p class="sub-title">
        ¡Hola {{ auth.user!.nombres }}!
      </p>
    </div>
    <div class="btn-container col-3 d-flex justify-content-end align-items-center m-0 p-0">
      <button id="btn-editar" class="btn" routerLink="/cuenta" style="cursor: pointer;">
        <fa-icon [icon]="faPen" size="1x" [styles]="{ color: '#41403E' }"></fa-icon>
      </button>
    </div>
  </div>
  <div class="puntos col-12 col-md-6 col-lg-12 row align-items-center  pt-2 m-0" routerLink="/puntos-feat"
    style="cursor: pointer">
    <div class="col-2 align-items-center justify-content-center p-0 puntos-feat">
      <div class="spinner-container m-0 p-0">
        <mat-progress-spinner class="mat-progress-spinner " [value]="puntos_feat/500*100" diameter="60">
          1300
        </mat-progress-spinner>
        <h5 class="card-img-overlay p-0 m-0 d-flex flex-row"
          [ngClass]="{
          'card-img-overlay-4': puntos_feat < 100, 
          'card-img-overlay-3': puntos_feat >= 100 &&  puntos_feat < 999, 
          'card-img-overlay-2': puntos_feat >= 999 &&  puntos_feat <= 9990, 
          'card-img-overlay-1': puntos_feat > 9990  &&  puntos_feat <= 99999
        }"
        *ngIf="puntos_feat <= 99999"
        >
          {{puntos_feat}}
        </h5>
        <h5 class="card-img-overlay p-0 m-0 d-flex flex-row card-img-overlay-0"
        *ngIf="puntos_feat > 99999"
      >
        +100 K 
      </h5>
      </div>
    </div>
    <div class="col-10 col p-0">
      <p class="col-12 main-title">Tus puntos Ft. </p>
      <p class="col-12 sub-title " *ngIf="puntos_feat < minPF">
        Te faltan <strong>{{minPF - puntos_feat}}</strong> puntos para ganar tu descuento.
      </p>
      <p class="col-12 sub-title text-success" *ngIf="puntos_feat >= minPF">
        Ya completaste {{minPF}} puntos para ganar tu descuento.
      </p>
      <hr />
     
    </div>
  </div>
  <div>
    <p class="col-12 sub-title" *ngIf="puntosPorCalificar">
      Puntos en pedidos por calificar: <strong>{{puntosPorCalificar}}.</strong>
    </p>
    <p class="col-12 sub-title" *ngIf="puntosPorCalificar && diasRestantes">
      <span class="recordatorio">* Recuerda que por cada pedido tiene {{diasRestantes}} meses habíles, para calificar y poder recibir los puntos</span>
    </p>
      <hr  *ngIf="puntosPorCalificar && puntosProceso"/>
    <p class="col-12 sub-title" *ngIf="puntosProceso">
      Puntos en transito:<strong> {{puntosProceso}} Puntos FT</strong>
    </p>
  </div>
  <div class="col-12" *ngIf="this.punto_entrega && this.punto_entrega.puntos_inscripcion > 0">
    <hr />
    <div class="row">
      <p class="col-12 sub-title text-center text-success">
         ¡Enhorabuena! 
        <br>
       Recuerda que tienes un bono de <strong> {{this.punto_entrega.puntos_inscripcion}} Puntos FT.</strong>
       <br>Para usarlo cuando desees.
      </p>
    </div>
  </div>
</div>