import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlogComponent } from './blog/blog.component';
import { NoticiaComponent } from './blog/noticia/noticia.component';
import { CarritoComponent } from './carrito/carrito.component';
import { ChatComponent } from './chat/chat.component';
import { ClientesComponent } from './clientes/clientes.component';
import { DetalleClientesComponent } from './clientes/detalle-clientes/detalle-clientes.component';
import { SolicitudesComponent } from './clientes/solicitudes/solicitudes.component';
import { ComunidadComponent } from './comunidad/comunidad.component';
import { CuentaDistribuidorComponent } from './cuenta-distribuidor/cuenta-distribuidor.component';
import { DetalleTrabajadorAprobadoComponent } from './cuenta-distribuidor/trabajadores-distribuidor/detalle-trabajador-aprobado/detalle-trabajador-aprobado.component';
import { SolicitudTrabajadorComponent } from './cuenta-distribuidor/trabajadores-distribuidor/solicitud-trabajador/solicitud-trabajador.component';
import { DetalleDistribuidorNoVinculadoComponent } from './distribuidores/detalle-distribuidor/detalle-distribuidor-no-vinculado/detalle-distribuidor-no-vinculado.component';
import { DetalleDistribuidorComponent } from './distribuidores/detalle-distribuidor/detalle-distribuidor.component';
import { DetalleProductoComponent } from './distribuidores/detalle-producto/detalle-producto.component';
import { DistribuidoresComponent } from './distribuidores/distribuidores.component';
import { MisDistribuidoresComponent } from './distribuidores/mis-distribuidores/mis-distribuidores.component';
import { SolicitudesDistribuidorComponent } from './distribuidores/solicitudes-distribuidor/solicitudes-distribuidor.component';
import { AuthGuard } from './guards/auth.guard';
import { HomeGuard } from './guards/home.guard';
import { HomeComponent } from './home/home.component';
import { InformesDistribuidorComponent } from './informes-distribuidor/informes-distribuidor.component';
import { InformesComponent } from './informes/informes.component';
import { InicioDistribuidorComponent } from './inicio-distribuidor/inicio-distribuidor.component';
import { ChequeoPreciosDistComponent } from './inicio-distribuidor/inicio-perfil-dist/chequeo-precios-dist/chequeo-precios-dist.component';
import { ActualizaPlanComponent } from './inicio-distribuidor/selecciona-plan-dist/actualiza-plan/actualiza-plan.component';
import { RespuestaPagoPlanComponent } from './inicio-distribuidor/selecciona-plan-dist/respuesta-pago-plan/respuesta-pago-plan.component';
import { SeleccionaPlanDistComponent } from './inicio-distribuidor/selecciona-plan-dist/selecciona-plan-dist.component';
import { InicioComponent } from './inicio/inicio.component';
import { SaldosPromocionesComponent } from './inicio/saldos-promociones/saldos-promociones.component';
import { LoginComponent } from './login/login.component';
import { RecordarClaveComponent } from './login/recordar-clave/recordar-clave.component';
import { RegistroDistribuidorComponent } from './login/registro/registro-distribuidor/registro-distribuidor.component';
import { RegistroHorecaComponent } from './login/registro/registro-horeca/registro-horeca.component';
import { RegistroComponent } from './login/registro/registro.component';
import { MiCuentaComponent } from './mi-cuenta/mi-cuenta.component';
import { DetallePuntoEntregaComponent } from './mi-cuenta/puntos-entrega/detalle-punto-entrega/detalle-punto-entrega.component';
import { GestionarComponent } from './mi-cuenta/trabajadores/gestionar/gestionar.component';
import { SolicitudComponent } from './mi-cuenta/trabajadores/solicitud/solicitud.component';
import { CalificacionPedidoComponent } from './modal/calificacion-pedido/calificacion-pedido.component';
import { NotificacionesComponent } from './notificaciones/notificaciones.component';
import { CuentaOrganizacionesComponent } from './organizaciones/cuenta-organizaciones/cuenta-organizaciones.component';
import { ExpandirPlanOrgComponent } from './organizaciones/cuenta-organizaciones/expandir-plan-org/expandir-plan-org.component';
import { DetalleTrabajadorOrgComponent } from './organizaciones/cuenta-organizaciones/trabajadores-organizaciones/detalle-trabajador-org/detalle-trabajador-org.component';
import { ChatDistribuidoresOrganizacionComponent } from './organizaciones/distribuidores-organizaciones/contenido-distribuidores/chat-distribuidores-organizacion/chat-distribuidores-organizacion.component';
import { DetalleProductoDistribuidoresOrganizacionComponent } from './organizaciones/distribuidores-organizaciones/contenido-distribuidores/detalle-producto-distribuidores-organizacion/detalle-producto-distribuidores-organizacion.component';
import { PortafolioDistribuidorOrgComponent } from './organizaciones/distribuidores-organizaciones/contenido-distribuidores/portafolio-distribuidor-org/portafolio-distribuidor-org.component';
import { DistribuidoresOrganizacionesComponent } from './organizaciones/distribuidores-organizaciones/distribuidores-organizaciones.component';
import { EstablecimientosOrganizacionesComponent } from './organizaciones/establecimientos-organizaciones/establecimientos-organizaciones.component';
import { InformesOrganizacionesComponent } from './organizaciones/informes-organizaciones/informes-organizaciones.component';
import { InicioOrganizacionComponent } from './organizaciones/inicio-organizacion/inicio-organizacion.component';
import { DetalleProductoOrganizacionComponent } from './organizaciones/portafolio-organizacion/detalle-producto/detalle-producto.component';
import { PortafolioOrganizacionComponent } from './organizaciones/portafolio-organizacion/portafolio-organizacion.component';
import { ExpandirPuntosOrganizacionComponent } from './organizaciones/puntos-ft-organizacion/expandir-puntos-organizacion/expandir-puntos-organizacion.component';
import { PuntosFtOrganizacionComponent } from './organizaciones/puntos-ft-organizacion/puntos-ft-organizacion.component';
import { SectorComponent } from './organizaciones/sector/sector.component';
import { PedidosDistribuidorComponent } from './pedidos-distribuidor/pedidos-distribuidor.component';
import { DetallePedidoDistComponent } from './pedidos-distribuidor/pedidos-info/detalle-pedido-dist/detalle-pedido-dist.component';
import { PedidosSugHistorialComponent } from './pedidos-distribuidor/pedidos-sugeridos/pedidos-sug-historial/pedidos-sug-historial.component';
import { DetallePedidoSugeridoComponent } from './pedidos/detalle-pedido-sugerido/detalle-pedido-sugerido.component';
import { DetallePedidoComponent } from './pedidos/detalle-pedido/detalle-pedido.component';
import { MensajesPedidosComponent } from './pedidos/mensajes-pedidos/mensajes-pedidos.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { PreFacturaPedidoComponent } from './pedidos/pre-factura-pedido/pre-factura-pedido.component';
import { AgregarProductoComponent } from './portafolio/agregar-producto/agregar-producto.component';
import { EditarProductoComponent } from './portafolio/catalogo/editar-producto/editar-producto.component';
import { PortafolioComponent } from './portafolio/portafolio.component';
import { PreciosComponent } from './precios/precios.component';
import { PuntosFeatComponent } from './puntos-feat/puntos-feat.component';
import { PuntosFtComponent } from './puntos-ft/puntos-ft.component';
import { SaldosPromosDistribuidorComponent } from './saldos-promos-distribuidor/saldos-promos-distribuidor.component';
import { NewCarritoComponent } from './carrito/new-carrito/new-carrito.component';
import { PoliticasComponent } from './politicas/politicas.component';
import { EditarPedidoDistComponent } from './pedidos/editar-pedido-dist/editar-pedido-dist.component';
import { FinishRegisterComponent } from './newClients/finish-register/finish-register.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home',
  },
  {
    path: 'home',
    component: HomeComponent,
    // canActivate: [HomeGuard],
  },
  {
    path: 'blog',
    component: BlogComponent,
  },
  {
    path: 'politicas',
    component: PoliticasComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'recordar-clave/:id',
    component: RecordarClaveComponent,
  },
  {
    path: 'registro',
    component: RegistroComponent,
    children: [
      {
        path: 'horeca',
        component: RegistroHorecaComponent,
      },
      {
        path: 'distribuidor',
        component: RegistroDistribuidorComponent,
      },
    ],
  },
  {
    path: 'inicio',
    component: InicioComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'noticia-blog/:id',
    component: NoticiaComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'notificaciones',
    component: NotificacionesComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'saldos-promociones',
    component: SaldosPromocionesComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'inicio-distribuidor',
    component: InicioDistribuidorComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'selecciona-plan',
    component: SeleccionaPlanDistComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'actualiza-plan/:id',
    component: ActualizaPlanComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'consultar-pago-plan',
    component: RespuestaPagoPlanComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'comparar-precios-dist',
    component: ChequeoPreciosDistComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'cuenta',
    component: MiCuentaComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'cuenta-distribuidor',
    component: CuentaDistribuidorComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'trabajador-aprobado/:id',
    component: DetalleTrabajadorAprobadoComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'solicitudes-trabajor/:id',
    component: SolicitudTrabajadorComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'cuenta/usuario/:id',
    component: GestionarComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'distribuidores',
    component: DistribuidoresComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'pedidos',
    component: PedidosComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'pedidos/:id',
    component: DetallePedidoComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'pedidos/:id',
    component: CalificacionPedidoComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'pedidos/sugeridos/:id',
    component: DetallePedidoSugeridoComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'pedidos/mensajes/:room',
    component: MensajesPedidosComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'pedidos/mensajes/:room/:rutaprevia',
    component: MensajesPedidosComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'pedidos/pre-factura/:id',
    component: PreFacturaPedidoComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'distribuidores/:id',
    component: DetalleDistribuidorComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'solicitudes-distribuidor/:id',
    component: DetalleDistribuidorComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'mis-distribuidores/:id',
    component: DetalleDistribuidorComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'inicio/distribuidores/:id',
    component: DetalleDistribuidorComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'distribuidores/novinculado/:id',
    component: DetalleDistribuidorNoVinculadoComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'solicitudes-distribuidor/novinculado/:id',
    component: DetalleDistribuidorNoVinculadoComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'mis-distribuidores',
    component: MisDistribuidoresComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'distribuidores/:id/producto/:productoid',
    component: DetalleProductoComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'solicitudes-distribuidor',
    component: SolicitudesDistribuidorComponent,
  },
  {
    path: 'carrito',
    component: CarritoComponent,
  },
  {
    path: 'carritoNew',
    component: NewCarritoComponent,
  },
  {
    path: 'cuenta/punto/:id',
    component: DetallePuntoEntregaComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'cuenta/solicitud/:id',
    component: SolicitudComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'puntos-feat',
    component: PuntosFeatComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'informes',
    component: InformesComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'comunidad',
    component: ComunidadComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'precios',
    component: PreciosComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'portafolio',
    component: PortafolioComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'portafolio/agregar-producto',
    component: AgregarProductoComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'portafolio/editar-producto/:id',
    component: EditarProductoComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'clientes',
    component: ClientesComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'clientes/solicitudes',
    component: SolicitudesComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'detalle-cliente/:id',
    component: DetalleClientesComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'saldos-promos-dist',
    component: SaldosPromosDistribuidorComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'pedidos-distribuidor',
    component: PedidosDistribuidorComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'pedidos-distribuidor/:id',
    component: DetallePedidoDistComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'pedidos-sugeridos-historial',
    component: PedidosSugHistorialComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'puntos-ft',
    component: PuntosFtComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'informes-distribuidor',
    component: InformesDistribuidorComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'inicio-organizacion',
    component: InicioOrganizacionComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'portafolio-organizacion',
    component: PortafolioOrganizacionComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'detalle-producto-organizacion/:id/:origen',
    component: DetalleProductoOrganizacionComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'puntos-ft-organizacion',
    component: PuntosFtOrganizacionComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'distribuidores-organizacion',
    component: DistribuidoresOrganizacionesComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'cuenta-organizacion',
    component: CuentaOrganizacionesComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'detalle-trabajador-organizacion/:id',
    component: DetalleTrabajadorOrgComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'expandir-plan-organizacion',
    component: ExpandirPlanOrgComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'comprar-puntos-ft-organizacion',
    component: ExpandirPuntosOrganizacionComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'portafolio-distribuidor-organizacion/:id',
    component: PortafolioDistribuidorOrgComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'edit-pedido-dist/:pedidoID',
    component: EditarPedidoDistComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'chat-distribuidor-organizacion',
    component: ChatDistribuidoresOrganizacionComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'detalle-producto-distribuidor-organizacion/:org/:id/distribuidor/:idDist',
    component: DetalleProductoDistribuidoresOrganizacionComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'informes-organizacion',
    component: InformesOrganizacionesComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'sector-organizacion',
    component: SectorComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'establecimientos-organizacion',
    component: EstablecimientosOrganizacionesComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'chat/room/:room/distribuidor/:id_ist/organizacion/:id_org/:ruta_previa',
    component: ChatComponent,
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
  },
  {
    path: 'finalizar-registro/:id',
    component: FinishRegisterComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
