import { AgmMap } from '@agm/core';
import { CurrencyPipe } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Chart } from 'chart.js';
import { CargandoGenericoComponent } from 'src/app/modal/cargando-generico/cargando-generico.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GraficasService } from 'src/app/services/graficas-tablas/graficas.service';
import { MapService } from 'src/app/services/map/map.service';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-establecimientos-organizaciones',
  templateUrl: './establecimientos-organizaciones.component.html',
  styleUrls: ['./establecimientos-organizaciones.component.css'],
})
export class EstablecimientosOrganizacionesComponent implements OnInit {
  // ID organizacion
  public id_organizacion = this.auth.user_organizacion._id;
  // Ciudad organicacion
  public ciudad_organizacion = this.auth.user_organizacion.ciudad;
  // Data punto entrega seleccionado
  public punto_seleccionado_nombre_establecimiento = 'Selecciona un punto en el mapa';
  public punto_seleccionado_nombre = 'Tipo de establecimiento';
  public punto_seleccionado_direccion = 'Dirección punto';
  // Guarda la data de los establecimientos asociados a la org
  public establecimientos: any;
  // Guarda las coordenadas de la organización
  public coord_org: any;
  public filtro_24_meses: any = [];
  public anos_filtro: any[] = [];
  private meses: string[] = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'];
  private meses_largo: string[] = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  // Indicadores
  public promedioPedidosMes = 0;
  public promedioPedidosMesCantidad = 0;
  // Colores para las gráficas
  public colors_bar = 'rgba(115, 113, 156, 1)';
  public colors_pie = ['rgba(247, 234, 194, 1)', 'rgba(250, 211, 157, 1)'];
  // Datos para el manejo del mapa 1
  @ViewChild('AgmMap') agmMap!: AgmMap;
  public locations_puntos_entrega: any = [];
  // Biding HTML-Canvas y TS
  @ViewChild('bar_establecimiento_comprados', { static: true })
  private bar_establecimiento_comprados!: ElementRef;
  @ViewChild('bar_establecimiento_negocio', { static: true })
  private bar_establecimiento_negocio!: ElementRef;
  @ViewChild('bar_establecimiento_sillas', { static: true })
  private bar_establecimiento_sillas!: ElementRef;
  @ViewChild('bar_establecimiento_ciudad', { static: true })
  private bar_establecimiento_ciudad!: ElementRef;
  @ViewChild('bar_establecimiento_punto', { static: true })
  private bar_establecimiento_punto!: ElementRef;
  @ViewChild('bar_establecimiento_prom_prod', { static: true })
  private bar_establecimiento_prom_prod!: ElementRef;
  @ViewChild('pie_establecimiento_tipo', { static: true })
  private pie_establecimiento_tipo!: ElementRef;
  @ViewChild('pie_establecimiento_domicilio', { static: true })
  private pie_establecimiento_domicilio!: ElementRef;
  @ViewChild('pie_establecimiento_cartera', { static: true })
  private pie_establecimiento_cartera!: ElementRef;
  @ViewChild('pie_establecimiento_convenio', { static: true })
  private pie_establecimiento_convenio!: ElementRef;


  // Guarda datos de la tabla y configuraciones
  private data_bar_establecimiento_comprados: any;
  private bar_chart_establecimiento_comprados: any;
  private data_bar_establecimiento_negocio: any;
  private bar_chart_establecimiento_negocio: any;
  private data_bar_establecimiento_sillas: any;
  private bar_chart_establecimiento_sillas: any;
  private data_bar_establecimiento_ciudad: any;
  private bar_chart_establecimiento_ciudad: any;
  private data_bar_establecimiento_punto: any;
  private bar_chart_establecimiento_punto: any;
  private data_bar_establecimiento_prom_prod: any;
  private bar_chart_establecimiento_prom_prod: any;
  private data_pie_establecimiento_tipo: any;
  private pie_chart_establecimiento_tipo: any;
  private data_pie_establecimiento_domicilio: any;
  private pie_chart_establecimiento_domicilio: any;
  private data_pie_establecimiento_cartera: any;
  private pie_chart_establecimiento_cartera: any;
  private data_pie_establecimiento_convenio: any;
  private pie_chart_establecimiento_convenio: any;
  public filtro_clientes_mes: any;
  public filtro_establecimiento_negocio: any;
  public filtro_establecimiento_sillas: any;
  public filtro_establecimiento_ciudad: any;
  // Totales de las graficas
  public total_clientes_mes: any;
  public total_establecimiento_negocio: any;
  public total_establecimiento_sillas: any;
  public total_establecimiento_ciudad: any;
  public total_establecimiento_puntos: any;
  public total_establecimiento_prom_prod: any;
  public total_establecimiento_tipo: any;
  public total_establecimiento_domicilios: any;
  public total_establecimiento_cartera: any;
  public total_establecimiento_convenio: any;
  // Modal de carga para darle feedback al usuario
  public modalCarga?: NgbModalRef;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    windowClass: 'modal-selecionar-punto',
  };

  constructor(
    public auth: AuthService,
    private rest: RestService,
    private mapService: MapService,
    private modalService: NgbModal,
    private ngxsStore: Store,
    private currency: CurrencyPipe,
    private graficasService: GraficasService,
    private router: Router
  ) {
    this.data_bar_establecimiento_comprados = {
      labels: [],
      datasets: [],
    };
    this.data_bar_establecimiento_negocio = {
      labels: [],
      datasets: [],
    };
    this.data_bar_establecimiento_sillas = {
      labels: [],
      datasets: [],
    };
    this.data_bar_establecimiento_ciudad = {
      labels: [],
      datasets: [],
    };
    this.data_pie_establecimiento_cartera = {
      labels: [],
      datasets: [],
    };
    this.data_pie_establecimiento_convenio = {
      labels: [],
      datasets: [],
    };
    this.data_bar_establecimiento_punto = {
      labels: [],
      datasets: [],
    };
    this.data_bar_establecimiento_prom_prod = {
      labels: [],
      datasets: [],
    };
    this.data_pie_establecimiento_tipo = {
      labels: [],
      datasets: [],
    };
    this.data_pie_establecimiento_domicilio = {
      labels: [],
      datasets: [],
    };
  }

  async ngOnInit() {
    console.log('bar_establecimiento_comprados', this.bar_establecimiento_comprados)

    this.id_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion._id;
    this.ciudad_organizacion = await this.ngxsStore.snapshot().auth.user_organizacion.ciudad;
    this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    await this.getListaFiltroMeses();
    try {
      await this.cargarMapa();
      await this.cargarInformacionPromedioPedidosMes();
      await this.cargarInformacionPromedioPedidosCantidadMes();
      await this.cargarInformacionEstablecimientosComprado();
      await this.cargarInformacionEstablecimientosNegocio();
      await this.cargarInformacionEstablecimientosSillas();
      await this.cargarInformacionEstablecimientosCiudad();
      await this.cargarInformacionEstablecimientosPunto();
      await this.cargarInformacionEstablecimientosPromProd();
      await this.cargarInformacionEstablecimientoTipo();
      await this.cargarInformacionEstablecimientoDomicilio();
      await this.cargarInformacionEstablecimientosCartera();
      await this.cargarInformacionEstablecimientosConvenio();
      this.modalCarga?.close();
    } catch (error) {
      this.mostrarMensajeError();
    }
  }

  /**
   * Arma lista de los últimos 24 meses para el filtro de meses
   */
  getListaFiltroMeses() {
    const fecha_actual = new Date();
    let mes_actual = fecha_actual.getMonth();
    let ano_actual = fecha_actual.getFullYear();
    // Filtro por años (últimos 10 años)
    for (let index = 0; index < 10; index++) {
      this.anos_filtro.push(ano_actual - index);
    }
    this.filtro_clientes_mes = this.anos_filtro[0];
    // Filtro por meses y años
    for (let index = 0; index < 23; index++) {
      if (mes_actual >= 0) {
        const data = {
          filtro: this.meses[mes_actual] + ', ' + ano_actual,
          filtro_inicio: this.meses[mes_actual - 2] + ', ' + ano_actual,
          crudo: Number(mes_actual + 1) + ',' + ano_actual + ',' + (mes_actual - 1) + ',' + ano_actual,
        };
        if (mes_actual <= 1) {
          data.filtro_inicio = this.meses[11 - (1 - mes_actual)] + ', ' + (ano_actual - 1);
          data.crudo = Number(mes_actual + 1) + ',' + ano_actual + ',' + (11 - mes_actual) + ',' + (ano_actual - 1);
        }
        this.filtro_24_meses.push(data);
        mes_actual = mes_actual - 1;
      } else {
        mes_actual = 11;
        ano_actual = ano_actual - 1;
      }
    }
    this.filtro_establecimiento_negocio = this.filtro_24_meses[0].crudo;
    this.filtro_establecimiento_sillas = this.filtro_24_meses[0].crudo;
    this.filtro_establecimiento_ciudad = this.filtro_24_meses[0].crudo;
  }

  /**
   * Inicializa los datos de las gráficas y llama a los métodos adecuados para mostrar cada una
   */
  /****** Establecimientos comprados por mes *******/
  public async cargarInformacionEstablecimientosComprado() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_establecimiento_comprados) {
      this.bar_chart_establecimiento_comprados.destroy();
      this.data_bar_establecimiento_comprados.labels = [];
      this.data_bar_establecimiento_comprados.datasets = [];
      // Se cargan los indicadores mensuales por pedidos
      this.cargarInformacionPromedioPedidosMes();
      this.cargarInformacionPromedioPedidosCantidadMes();
    }
    const first_day = this.filtro_clientes_mes + '-01-01';
    const last_day = this.filtro_clientes_mes + '-12-31';
    this.total_clientes_mes = 0;
    await this.rest
      .getJWT(`clientesXmes/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        console.log('Compra cliente x mes', ventas_mes);
        ventas_mes = ventas_mes.data;
        ventas_mes.forEach((element: any) => {
          this.data_bar_establecimiento_comprados.labels.push(
            this.meses[element._id.split('-')[1] - 1] + '-' + element._id.split('-')[0]
          );
          this.data_bar_establecimiento_comprados.datasets.push(element.total);
          this.total_clientes_mes = this.total_clientes_mes + element.total;
        });
      })
      .catch((err) => {
        throw err;
      });
    // Se crea la grafica
    this.bar_chart_establecimiento_comprados = this.graficasService.createBarChart(
      this.bar_establecimiento_comprados,
      this.data_bar_establecimiento_comprados,
      'No. de establecimientos comprados por mes',
      'No. de establecimientos',
      'Meses'
    );
  }
  /******* Promedio Mensual Pedidos *********/
  public async cargarInformacionPromedioPedidosMes() {
    const first_day = this.filtro_clientes_mes + '-01-01';
    const last_day = this.filtro_clientes_mes + '-12-31';
    await this.rest
      .getJWT(`promedioXestablecimientos/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        if (ventas_mes.data.length === 0) {
          this.promedioPedidosMes = 0;
        } else {
          this.promedioPedidosMes = ventas_mes.data[0].total;
        }
      })
      .catch((err) => {
        throw err;
      });
  }
  /*** Promedio Mensual Cantidad Pedidos *****/
  public async cargarInformacionPromedioPedidosCantidadMes() {
    const first_day = this.filtro_clientes_mes + '-01-01';
    const last_day = this.filtro_clientes_mes + '-12-31';
    await this.rest
      .getJWT(`promedioCantidadXestablecimientos/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {

        if (ventas_mes.data.length === 0) {
          this.promedioPedidosMesCantidad = 0;
        } else {
          this.promedioPedidosMesCantidad = Math.round(ventas_mes.data[0].total)
          ;
        }
      })
      .catch((err) => {
        throw err;
      });
  }
  /****** Establecimientos comprados por mes *******/
  public async cargarInformacionEstablecimientosNegocio() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_establecimiento_negocio) {
      this.bar_chart_establecimiento_negocio.destroy();
      this.data_bar_establecimiento_negocio.labels = [];
      this.data_bar_establecimiento_negocio.datasets = [];
    }
    const first_day =
      this.filtro_establecimiento_negocio.split(',')[3] +
      '-' +
      this.filtro_establecimiento_negocio.split(',')[2] +
      '-01';
    const last_day =
      this.filtro_establecimiento_negocio.split(',')[1] +
      '-' +
      this.filtro_establecimiento_negocio.split(',')[0] +
      '-31';
    let total_establecimiento_negocio = 0;
    await this.rest
      .getJWT(`establecimientosXnegocio/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data;
        ventas_mes.forEach((element: any) => {
          this.data_bar_establecimiento_negocio.labels.push(element._id);
          this.data_bar_establecimiento_negocio.datasets.push(element.total);
          total_establecimiento_negocio = total_establecimiento_negocio + element.total;
          this.total_establecimiento_negocio = total_establecimiento_negocio;
        });
      })
      .catch((err) => {
        throw err;
      });
      console.log('Aqui.......Aqui........', this.bar_establecimiento_negocio)
    // Se crea la grafica
    this.bar_chart_establecimiento_negocio = this.graficasService.createBarChart(
      this.bar_establecimiento_negocio,
      this.data_bar_establecimiento_negocio,
      'No. de establecimientos por tipo de negocio',
      'No. de establecimientos',
      'Tipos de negocio'
    );
  }
  /****** Establecimientos por número de sillas *******/
  public async cargarInformacionEstablecimientosSillas() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_establecimiento_sillas) {
      this.bar_chart_establecimiento_sillas.destroy();
      this.data_bar_establecimiento_sillas.labels = [];
      this.data_bar_establecimiento_sillas.datasets = [];
    }
    const first_day =
      this.filtro_establecimiento_sillas.split(',')[3] + '-' + this.filtro_establecimiento_sillas.split(',')[2] + '-01';
    const last_day =
      this.filtro_establecimiento_sillas.split(',')[1] + '-' + this.filtro_establecimiento_sillas.split(',')[0] + '-31';
    this.total_establecimiento_sillas = 0;
    await this.rest
      .getJWT(`establecimientosXsillas/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data[0].cant_sillas;
        ventas_mes.forEach((element: any) => {
          this.data_bar_establecimiento_sillas.labels.push(element.label);
          this.data_bar_establecimiento_sillas.datasets.push(element.count);
          this.total_establecimiento_sillas = this.total_establecimiento_sillas + element.count;
        });
      })
      .catch((err) => {
        throw err;
      });
    // Se crea la grafica
    this.bar_chart_establecimiento_sillas = this.graficasService.createBarChart(
      this.bar_establecimiento_sillas,
      this.data_bar_establecimiento_sillas,
      'No. de establecimientos por número de sillas',
      'No. de establecimientos',
      'Rangos de número de sillas'
    );
  }
  /****** Establecimientos por ciudad *******/
  public async cargarInformacionEstablecimientosCiudad() {
    /** Si se aplica un filtro se borra la data pevia */
    if (this.bar_chart_establecimiento_ciudad) {
      this.bar_chart_establecimiento_ciudad.destroy();
      this.data_bar_establecimiento_ciudad.labels = [];
      this.data_bar_establecimiento_ciudad.datasets = [];
    }
    const first_day =
      this.filtro_establecimiento_ciudad.split(',')[3] + '-' + this.filtro_establecimiento_ciudad.split(',')[2] + '-01';
    const last_day =
      this.filtro_establecimiento_ciudad.split(',')[1] + '-' + this.filtro_establecimiento_ciudad.split(',')[0] + '-31';
    this.total_establecimiento_ciudad = 0;
    await this.rest
      .getJWT(`establecimientosXciudad/${this.id_organizacion}/${first_day}/${last_day}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data;
        ventas_mes.forEach((element: any) => {
          this.data_bar_establecimiento_ciudad.labels.push(element._id);
          this.data_bar_establecimiento_ciudad.datasets.push(element.total);
          this.total_establecimiento_ciudad = this.total_establecimiento_ciudad + element.total;
        });
      })
      .catch((err) => {
        throw err;
      });
    // Se crea la grafica
    this.bar_chart_establecimiento_ciudad = this.graficasService.createBarChart(
      this.bar_establecimiento_ciudad,
      this.data_bar_establecimiento_ciudad,
      'No. de establecimientos por ciudad',
      'No. de establecimientos',
      'Ciudades'
    );
  }
  /****** Puntos por establecimiento *******/
  public async cargarInformacionEstablecimientosPunto() {
    this.data_bar_establecimiento_punto.labels = ['1', '2', '3-5', '6-10', '11-20', '+21'];
    this.total_establecimiento_puntos = 0;
    await this.rest
      .getJWT(`establecimientosXtotalPuntos/${this.id_organizacion}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data[0];
        this.data_bar_establecimiento_punto.datasets.push(ventas_mes.puntos1[0].count);
        this.data_bar_establecimiento_punto.datasets.push(ventas_mes.puntos2[0].count);
        this.data_bar_establecimiento_punto.datasets.push(ventas_mes.puntos3_5[0].count);
        this.data_bar_establecimiento_punto.datasets.push(ventas_mes.puntos6_10[0].count);
        this.data_bar_establecimiento_punto.datasets.push(ventas_mes.puntos11_20[0].count);
        this.data_bar_establecimiento_punto.datasets.push(ventas_mes.puntos21[0].count);
        this.total_establecimiento_puntos =
          ventas_mes.puntos1[0].count +
          ventas_mes.puntos2[0].count +
          ventas_mes.puntos3_5[0].count +
          ventas_mes.puntos6_10[0].count +
          ventas_mes.puntos11_20[0].count +
          ventas_mes.puntos21[0].count;
      })
      .catch((err) => {
        throw err;
      });
    this.bar_chart_establecimiento_punto = this.graficasService.createBarChart(
      this.bar_establecimiento_punto,
      this.data_bar_establecimiento_punto,
      'Puntos de entrega por establecimiento',
      'No. de establecimientos',
      'Cantidad de puntos de entrega'
    );
  }
  /****** Puntos por establecimiento *******/
  public async cargarInformacionEstablecimientosPromProd() {
    this.total_establecimiento_prom_prod = 0;
    await this.rest
      .getJWT(`establecimientosXpromprod/${this.id_organizacion}`)
      .toPromise()
      .then((resp: any) => {
        resp.data.forEach((element: any) => {
          this.data_bar_establecimiento_prom_prod.labels.push(element._id);
          this.data_bar_establecimiento_prom_prod.datasets.push(element.total);
          this.total_establecimiento_prom_prod = this.total_establecimiento_prom_prod + element.total;
        });
      })
      .catch((err) => {
        throw err;
      });
    // Se crea la grafica
    this.bar_chart_establecimiento_prom_prod = this.graficasService.createBarChart(
      this.bar_establecimiento_prom_prod,
      this.data_bar_establecimiento_prom_prod,
      'Promedio productos por pedidos',
      'No. de pedidos',
      'Total productos solicitados'
    );
  }
  /****** Estabelcimientos por tipo  *******/
  public async cargarInformacionEstablecimientoTipo() {
    this.total_establecimiento_tipo = 0;
    await this.rest
      .getJWT(`establecimientosXfiguraNegocio/${this.id_organizacion}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data[0].tipo_negocio;
        ventas_mes.forEach((element: any) => {
          this.data_pie_establecimiento_tipo.labels.push(element.label + ' (' + element.count + ')');
          this.data_pie_establecimiento_tipo.datasets.push(element.count);
          this.total_establecimiento_tipo = this.total_establecimiento_tipo + element.count;
        });
      })
      .catch((err) => {
        throw err;
      });
    // Se crea la grafica
    this.pie_chart_establecimiento_tipo = this.graficasService.createPieChart(
      this.pie_establecimiento_tipo,
      this.data_pie_establecimiento_tipo,
      'Establecimientos naturales y persona júridica'
    );
  }
  /****** Estabelcimientos con domicilio  *******/
  public async cargarInformacionEstablecimientoDomicilio() {
    this.total_establecimiento_domicilios = 0;
    await this.rest
      .getJWT(`establecimientosXdomicilio/${this.id_organizacion}`)
      .toPromise()
      .then((resp: any) => {
        resp = resp.data[0];
        this.data_pie_establecimiento_domicilio.labels.push('Con domicilio', 'Sin domicilio');
        if (resp.con_domicilio.length > 0) {
          this.data_pie_establecimiento_domicilio.datasets.push(resp.con_domicilio[0].total);
          this.total_establecimiento_domicilios += resp.con_domicilio[0].total;
        } else {
          this.data_pie_establecimiento_domicilio.datasets.push(0);
        }
        if (resp.sin_Domicilio.length > 0) {
          this.data_pie_establecimiento_domicilio.datasets.push(resp.sin_Domicilio[0].total);
          this.total_establecimiento_domicilios += resp.sin_Domicilio[0].total;
        } else {
          this.data_pie_establecimiento_domicilio.datasets.push(0);
        }
      })
      .catch((err) => {
        throw err;
      });
    // Se crea la grafica
    this.pie_chart_establecimiento_domicilio = this.graficasService.createPieChart(
      this.pie_establecimiento_domicilio,
      this.data_pie_establecimiento_domicilio,
      'Establecimientos con y sin domicilios'
    );
  }
  /****** Establecimientos en cartera *******/
  public async cargarInformacionEstablecimientosCartera() {
    this.total_establecimiento_cartera = 0;
    await this.rest
      .getJWT(`establecimientosXcartera/${this.id_organizacion}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data[0].cartera;
        ventas_mes.forEach((element: any) => {
          this.data_pie_establecimiento_cartera.labels.push(element.label + ' (' + element.count + ')');
          this.data_pie_establecimiento_cartera.datasets.push(element.count);
          this.total_establecimiento_cartera = this.total_establecimiento_cartera + element.count;
        });
      })
      .catch((err) => {
        throw err;
      });
    // Se crea la grafica
    this.pie_chart_establecimiento_cartera = this.graficasService.createPieChart(
      this.pie_establecimiento_cartera,
      this.data_pie_establecimiento_cartera,
      'Establecimientos de cartera vencida'
    );
  }
  /****** Establecimientos en convenio *******/
  public async cargarInformacionEstablecimientosConvenio() {
    this.total_establecimiento_convenio = 0;
    await this.rest
      .getJWT(`establecimientosXconvenio/${this.id_organizacion}`)
      .toPromise()
      .then((ventas_mes: any) => {
        ventas_mes = ventas_mes.data[0].convenio;
        ventas_mes.forEach((element: any) => {
          this.data_pie_establecimiento_convenio.labels.push(element.label + ' (' + element.count + ')');
          this.data_pie_establecimiento_convenio.datasets.push(element.count);
          this.total_establecimiento_convenio = this.total_establecimiento_convenio + element.count;
        });
      })
      .catch((err) => {
        throw err;
      });
    // Se crea la grafica
    this.pie_chart_establecimiento_convenio = this.graficasService.createPieChart(
      this.pie_establecimiento_convenio,
      this.data_pie_establecimiento_convenio,
      'Establecimientos con convenio'
    );
  }

  /**
   * Recupera los establecimientos que han hecho pedidos de los productos de la organización
   * y recupera las coordenadas de cada punto de entrega para actualziar el mapa en el DOM
   */
  async cargarMapa() {
    this.coord_org = await this.mapService.getLatLong(this.ciudad_organizacion).toPromise();
    this.coord_org = this.coord_org.results[0].geometry.location;
    this.rest
      .getJWT('establecimientos_organizacion/pedidos_realizados/' + this.id_organizacion)
      .toPromise()
      .then((resp: any) => {
        console.log('respresp', resp)
        this.establecimientos = resp;
        this.establecimientos.forEach((element: any) => {

          this.locations_puntos_entrega.push(element.data_punto[0].coord || {});
        });
      });
  }

  /**
   * Recibe la información del punto seleccionado y actualiza la tabla que meustra la inforamción del punto
   * @param pin data del punto seleccionado en el mapa del DOM
   */
  showDataPunto(pin: any) {
    this.establecimientos.forEach((element: any) => {
      if (element.data_punto[0].coord.lat == pin.latitude && element.data_punto[0].coord.lng == pin.longitude) {
        console.log('element.data_punto[0].data_horeca[0]', element.data_punto[0].data_horeca[0])
       // this.punto_seleccionado_nombre_establecimiento = element.data_punto[0].data_horeca[0].tipo_negocio;
        this.punto_seleccionado_nombre = element.data_punto[0].data_horeca[0].tipo_negocio;
        //this.punto_seleccionado_direccion = element.data_punto[0].direccion + ', ' + element.data_punto[0].ciudad;
      }
    });
  }

  /**
   * Manejo de errores por fallo en peticiones al back
   */
  private mostrarMensajeError() {
    this.modalCarga?.close();
    const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {
      this.router.navigate(['/inicio-organizacion']);
    };
  }
}
