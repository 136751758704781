<div class="row">
  <div class="col-8 col-md-9">
    <br />
    <h5 *ngIf="totalClientes" class="font-weight-300">{{ totalClientes }} cliente{{totalClientes > 1 ? 's' : ''}} de tu plan
    </h5>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="row">
      <form class="col-6 pr-3 ng-untouched ng-pristine ng-valid">

        <div class="buscador">
          <input  type="text" placeholder="Buscar por nombre / Razón social / Nit"
          (keyup.enter)="buscarNombre()"
          [(ngModel)]="buscador"
          [ngModelOptions]="{standalone: true}">              
          
          <fa-icon (click)="buscarNombre()" [icon]="faSearch" *ngIf="!buscadorActivo"></fa-icon>
          <fa-icon (click)="limpiarFiltros()" [icon]="faTrash" *ngIf="buscadorActivo"></fa-icon>

        </div>
      </form>
    </div>
           
  </div>
  <div class="col-12 col-md-12" >
    <div class="card overflow-hidden px-4">
      <div class="card-body card-bodyS px-0" style="overflow: scroll;" *ngIf="totalPaginas">
        <ng2-smart-table   
        *ngIf="carga_info"
        [settings]="settings" 
        [source]="source" 
        (custom)="onCustom($event)"
        >
      </ng2-smart-table>
      <div *ngIf="loading" class="loading-overlay">
        <div class="spinner"></div>
        <span>Cargando...</span>
      </div>
      </div>
      <div class="card-body card-bodyS px-0" style="overflow: scroll;" *ngIf="!totalPaginas || totalPaginas === 0">
        <div class="row text-center">
          <div class="col-12">
            <img
              src="../../../assets/img/icon-warning-amarillo.png"
              style="max-width: 200px; height: auto"
              alt="ícono para indicar estado vacío" />
          </div>
          <div class="col-12">
            <h5>No se encontraron clientes que coincidan con tu búsqueda</h5>
          </div>
        </div>
      </div>
      <div class="paginator"  *ngIf="carga_info && totalPaginas > 0">
        <button class="paginator-btn" (click)="goToPage(1)">
          <fa-icon [icon]="faChevronLeft"></fa-icon>
          <fa-icon [icon]="faChevronLeft"></fa-icon>
        </button> <!-- Flecha al inicio -->
        <button class="paginator-btn" (click)="goToPage(currentPage - 1)">
          <fa-icon [icon]="faChevronLeft"></fa-icon>
        </button> <!-- Flecha atrás -->
        
        <!-- Números de página -->
        <button class="paginator-btn" *ngFor="let page of pageNumbers" (click)="goToPage(page)"
        [ngClass]="{'active': page === currentPage}"
        >
          {{ page }}
        </button>
      
        <button class="paginator-btn" (click)="goToPage(currentPage + 1)">
          <fa-icon [icon]="faChevronRigth"></fa-icon>
        </button> <!-- Flecha adelante -->
        <button class="paginator-btn" (click)="goToPage(totalPaginas)">
          <fa-icon [icon]="faChevronRigth"></fa-icon>
          <fa-icon [icon]="faChevronRigth"></fa-icon>

        </button> <!-- Flecha al final -->
      </div>
    </div>
  </div>
</div>