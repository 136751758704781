import { CurrencyPipe } from '@angular/common';
import { Component, DoCheck, Input, OnInit } from '@angular/core';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbDateStruct, NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { Producto } from 'src/app/models/producto.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { CargandoGenericoComponent } from '../cargando-generico/cargando-generico.component';
import { SimpleComponent } from '../simple/simple.component';

@Component({
  selector: 'app-crear-saldo',
  templateUrl: './crear-saldo.component.html',
  styleUrls: ['./crear-saldo.component.css'],
})
export class CrearSaldoComponent implements OnInit, DoCheck {
  minDate = {};

  /** Referencia a las funciones de Number para usar en el template */
  public number = Number;
  /** Vars para manejar la selección de fecha */
  model?: NgbDateStruct;
  date: Date = new Date();
  /**  Variable para manejar el codigo del saldo */
  public codigo_promo = '';
  /** Maxima cantidad de unidades disponibles */
  public max_inv = 0;
  /** Flag para levantar error en el template */
  public err_max_unidades = false;
  /** Flag para indicar que se puede usar el boton de crear saldo */
  public crear_saldo_enabled = false;
  /** Guarda los datos del producto base/inicial */
  public producto_base_inventario_unidades = 0;
  public producto_base_id: any;
  /** Referencias a iconos FontAwesome */
  public faTimes = faTimes;
  public faChevronDown = faChevronDown;

  /** Información del producto a mostrar */
  @Input() public producto?: Producto;
  @Input() public precios: any[] = []; //Para usar en la info del producto
  @Input() public precios_form: any[] = []; //Para usar en el form
  @Input() public fotos: string[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public auth: AuthService,
    private modalService: NgbModal,
    private rest: RestService,
    private currencyPipe: CurrencyPipe
  ) {
    const today = new Date();
    this.minDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,  // Los meses en JavaScript van de 0 a 11
      day: today.getDate()
    };
  }

  ngOnInit(): void {
    this.max_inv = this.producto?.precios?.[0].inventario_unidad || 0;
    this.producto_base_inventario_unidades = this.precios_form[0].inventario_unidad;
    this.producto_base_id = this.producto?._id;
  }

  ngDoCheck(): void {
    /** Revisa que las unidades no sean mayores a las que se pueden poner en saldo */
    /** Si se supera, se devuelve al maximo y se levanta un mensaje de alerta */
    const max_inv_und = this.max_inv;
    if (this.precios_form[0].inventario_unidad >= max_inv_und) {
      this.precios_form[0].inventario_unidad = max_inv_und;
      this.err_max_unidades = true;
    } else {
      this.err_max_unidades = false;
    }
    /** Actualizar número de cajas segun cuantas unidades se han puesto en saldo */
    if (this.precios_form[0].und_x_caja > 0) {
      this.precios_form[0].inventario_caja =
        Math.round((this.precios_form[0].inventario_unidad / this.precios_form[0].und_x_caja) * 100) / 100;
    } else {
      this.precios_form[0].inventario_caja = 0;
    }
    /** Revisar que el formulario esté completo y válido */
    if (
      this.model &&
      this.precios_form[0].inventario_unidad > 0 &&
      this.precios_form[0].precio_unidad &&
      this.codigo_promo !== ''
    ) {
      this.crear_saldo_enabled = true;
    } else {
      this.crear_saldo_enabled = false;
    }
  }

  /**
   * Intenta la publicación del saldo en el servicio de productos
   * Si funciona, se muestra un modal con esa información y se vuelve a cargar
   * la información del componente de saldos y promociones
   * Si no funciona, se muestra un error y no se cierra el modal de crear saldo
   */
  async publicarSaldo() {
    if (this.producto) {
      const ngbModalOptions: NgbModalOptions = {
        backdrop: 'static',
        keyboard: false,
        centered: true,
      };
      let modalRef = this.modalService.open(CargandoGenericoComponent, ngbModalOptions);
      try {
        /** Actualizar el valor de la fecha */
        if (this.model) {
          this.date.setFullYear(this.model.year);
          this.date.setMonth(this.model.month - 1);
          this.date.setDate(this.model.day);
        }
        /** Se carga producto nuevo en saldo */
        this.precios_form[0].puntos_ft_unidad = 0;
        this.precios_form[0].puntos_ft_caja = 0;
        this.precios_form[0].precio_descuento = this.precios_form[0].precio_descuento.replace(/[^\d,-]/g, '');
        this.producto.precios = this.precios_form;
        this.producto.fecha_vencimiento = this.date;
        this.producto.saldos = true;
        this.producto.codigo_promo = this.codigo_promo;
        this.producto.mostrarPF = false;
        delete this.producto._id;
        this.producto.codigo_ft = undefined;
        this.producto.estadoActualizacion = 'Aceptado';
        if (this.producto_base_id.codigo_distribuidor) {
          this.producto.codigo_distribuidor = this.producto_base_id.codigo_distribuidor;
        } else {
          this.producto.codigo_distribuidor = this.auth.user_distribuidor?._id;
        }
        this.producto.codigo_organizacion = this.producto_base_id.codigo_organizacion;


        const resp_prod: any = await this.rest.postJWT('producto', this.producto).toPromise();
        /** Se actualiza producto base */
        const producto_base: any = {};
        producto_base.precios = this.precios_form;
        delete producto_base.precios[0].precio_descuento;
        producto_base.precios[0].inventario_unidad =
          this.producto_base_inventario_unidades - resp_prod.data.precios[0].inventario_unidad;
        if (producto_base.precios[0].und_x_caja > 0) {
          producto_base.precios[0].inventario_caja =
            Math.round((this.producto_base_inventario_unidades / producto_base.precios[0].und_x_caja) * 100) / 100;
        }
        await this.rest.putJWT(`producto/${this.producto_base_id}`, producto_base).toPromise();
        /** Se agrega el nuevo producto en saldo a la lista de productos del distribuidor */
        const productos_dist: any = await this.rest
          .getJWT(`productos_por_distribuidor/distribuidor/${this.auth.user_distribuidor?._id}`)
          .toPromise();
        const prods_ids: string[] = [];
        for (const aux of productos_dist.productos) {
          prods_ids.push(aux._id);
        }
        prods_ids.push(resp_prod.data._id);
        await this.rest
          .putJWT(`productos_por_distribuidor/${productos_dist._id}`, { productos: prods_ids })
          .toPromise();
        /** Modal exito y salir */
        modalRef.close();
        this.activeModal.close();
        modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
        modalRef.componentInstance.title = '¡Genial!';
        modalRef.componentInstance.msg = '¡El saldo fue creado con éxito!';
        modalRef.componentInstance.btn_msg = 'Listo';
        modalRef.componentInstance.close_callback = () => {
          window.location.reload();
        };
      } catch (err) {
        modalRef.close();
        modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'Ocurrió un error en la creación del saldo. Por favor intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
      }
    }
  }

  /**************************************** Validadores ****************************************/
  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(evento: any) {
    const keyCode = evento.keyCode;
    const input = evento.target as HTMLInputElement;
    const excludedKeys = [8, 37, 39, 46]; // Incluye teclas como Backspace, Left Arrow, Right Arrow, y Delete
  
    // Permitir teclas de control o navegación
    if (
      excludedKeys.includes(keyCode) ||
      (keyCode >= 48 && keyCode <= 57) || // Números 0-9 en el teclado principal
      (keyCode >= 96 && keyCode <= 105) || // Números 0-9 en el teclado numérico
      keyCode === 190 // Punto decimal
    ) {
      // Previsualizar el valor completo incluyendo la tecla presionada
      const newValue = parseFloat(input.value + (evento.key !== ' ' ? evento.key : ''));
      
      // Validar si el nuevo valor excede 10000
      if (!isNaN(newValue) && newValue > this.precios[0].precio_unidad) {
        evento.preventDefault(); // Si es mayor que 10000, previene la entrada
      }
    } else {
      // Previene cualquier otra tecla que no sea un número o las teclas permitidas
      evento.preventDefault();
    }
  }

  /**
   * Transforma el dinero minimo de compra de número a moneda y viceversa
   * a moneda se utilizará para reemplazar el input y dar mejor UX al usuario
   * y a número plano para guardar el dato correctamente en la base de datos
   * ademas de poder editar mejor el valor en el input
   */
  public transformCurrency(event: any) {
    this.precios_form[0].precio_descuento = event.value.replace(/[^\d,-]/g, '');
  }
  public transformAmount(event: any) {
    this.precios_form[0].precio_descuento = this.currencyPipe.transform(event.value, '$ ', 'symbol', '1.0-0');
  }
}
