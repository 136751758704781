<div class="inicio row align-items-center rounded m-0 p-2">
  <div class="saludo col-12 col-md-6 col-lg-12 row m-0 ">
    <div class="col-12 my-auto">
      <div class="row h-100">
        <div class="col-2 d-flex justify-items-center align-items-center p-0 my-auto">
          <img src="{{ authService.user_distribuidor?.logo || placeholder_dist }}" alt="Logo de la empresa"
            class="logo p-0" />
        </div>
        <div class="col-7 nombre-empresa pt-2 my-auto">
          <p class="main-title">
            {{ authService.user_distribuidor!.nombre }}
          </p>
          <p class="sub-title">¡Hola {{ authService.user!.nombres }}!</p>
        </div>
        <div class="btn-container col-3 d-flex justify-content-end align-items-center m-0 p-0 cursorPointer "
          routerLink="/cuenta-distribuidor">
          <button id="btn-editar" class="btn">
            <fa-icon [icon]="faPen" size="1x" [styles]="{ color: '#41403E' }"></fa-icon>
          </button>
        </div>
      </div>
    </div>
    
   
  </div>
  <div class="puntos col-12 col-md-6 col-lg-12 row align-items-center pt-2 m-0"
    [routerLink]="flag_plan_activo ? ['/selecciona-plan'] : []" style="cursor: pointer">
    <div class="col-2 align-items-center justify-content-center p-0">
      <img src="../../../assets/img/iconos-planes/1-principiante.png" class="logo p-0"
        alt="Canasta con verduras y frutas" />
    </div>
    <div class="col-10 col p-0">
      <h5 class="m-0" *ngIf="flag_plan_activo">Plan: {{nombrePlan}}</h5>
      <span *ngIf="flag_plan_activo">{{ cantidadClientes }}/{{ cantidadMaxima }} establecimientos</span>
      <h5 class="m-0" *ngIf="!flag_plan_activo">Sé un eslabón</h5>
      <span *ngIf="!flag_plan_activo">Aprovecha y vincula tus clientes</span>
    </div>
  </div>
</div>