import { Component, OnInit } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import {
  faChevronLeft,
  faChevronRight,
  faMapMarkerAlt,
  faSearch,
  faStoreAlt,
  faTimesCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { EstablecimientosService } from '../../services/establecimientos/establecimientos.service';
import { DistribuidorService } from '../../services/distribuidor/distribuidor.service';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { LocalDataSource } from 'ng2-smart-table';
import { PreAprobarClienteComponent } from 'src/app/modal/pre-aprobar-cliente/pre-aprobar-cliente.component';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { Router } from '@angular/router';
import { CargarClientesExcelComponent } from 'src/app/modal/cargar-clientes-excel/cargar-clientes-excel.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
import { RestService } from 'src/app/services/rest/rest.service';

@Component({
  selector: 'app-clientes-info',
  templateUrl: './clientes-info.component.html',
  styleUrls: ['./clientes-info.component.css'],
})
export class ClientesInfoComponent implements OnInit {
  /** Referencias a íconos FontAwesome para la UI */
  faSearch = faSearch;
  faTimesCircle = faTimesCircle;
  faStoreAlt = faStoreAlt;
  faMapMarkerAlt = faMapMarkerAlt;
  faTrash = faTrash;
  faChevronLeft = faChevronLeft;
  faChevronRigth = faChevronRight;
  buscadorActivo: boolean = false;
  /** Extraer el id del distribuidor logeado mediante localstorage */
  userInfo = JSON.parse(localStorage.getItem('auth') || '');
  idUserInfo = this.userInfo['user_distribuidor']['_id'];
  /** Nombre del cliente a buscar */
  nombre_cliente = '';
  /**Variable con datos del cliente */
  estado: any;
  idEstablecimiento: any;
  idUserHoreca: any;
  puntosEntrega: any;
  puntoEntrega: any;
  punto: any;
  nombreEstablecimiento: any;
  distribuidores: any;
  idPuntoEntrega: any;
  pedidos: any;
  trabajador: any;
  pedido: any;
  clientes: any;
  pedidoByPunto: any;
  tipo_dist_vinculado: any;
  /** Datos y configuracion de la tabla */
  public settings: any = {};
  public data: any = [];
  cantidadMaxima: any = 0;
  totalPaginas: number = 1;
  carga_info: boolean = false;
  source: LocalDataSource = new LocalDataSource();
  public cantidadClientes: number = 0;
  public paginaActual: number = 1; // Página actual
  public cantidadPorPagina: number = 10; // Cantidad de registros por página
  totalItems = 100; // Número total de registros
  itemsPerPage = 10; // Registros por página
  currentPage = 1; // Página actual
  loading = false
  totalClientes: any;
  idDistribuidor: any
  buscador: string  = '';
  constructor(
    private authService: AuthService,
    private establecimientosSerivce: EstablecimientosService,
    private distribuidorService: DistribuidorService,
    private clientService: ClientsService,
    private rest: RestService,
    private currency: CurrencyPipe,
    private modalService: NgbModal,
    private router: Router
  ) {
    this.configSmartTable();
  }

  ngOnInit(): void {
    this.idDistribuidor =this.authService.user_distribuidor?._id;
    this.getDistribuidoresVinculados(this.paginaActual);
  }
  get pageNumbers(): number[] {
    const start = Math.floor((this.currentPage - 1) / 10) * 10 + 1;
    const end = Math.min(start + 9, this.totalPaginas);
    const pages = [];
    
    for (let i = start; i <= end; i++) {
      pages.push(i);
    }

    return pages;
  }
  goToPage(page: number): void {
    if (page < 1 || page > this.totalPaginas) return;
    this.currentPage = page;
    this.getDistribuidoresVinculados(page)
  }
  //Método para limpiar filtros
  limpiarFiltros(){
    this.buscadorActivo = false;
    this.buscador = '';
    this.getDistribuidoresVinculados(1)
  }
  /** Trae el data de los distribuidores para vincular*/
  getDistribuidoresVinculados(page: any) {
    if(!this.buscador || this.buscador === ''){
      this.buscadorActivo = false;
    }
    this.loading = true;
    this.distribuidorService.getDistribuidoresVinculadosAPiNew(page, this.idDistribuidor, this.buscador).subscribe((data) => {
      this.carga_info = true;
      this.source.load(data.vinculaciones);  // Carga los datos en la tabla
      this.totalClientes = data.totalClientes;
      // Asegúrate de que totalPaginas se esté calculando correctamente
      this.totalPaginas = data.totalPaginas;  
      // Actualiza el paginador con los datos correctos
      this.source.setPaging(page, 10, this.totalPaginas > 10);  // Establece el paginador
      this.source.refresh(); // Refresca el source para actualizar la tabla
      this.loading = false;

    });
  }
  

  /**
   * Configura la tabla
   */
  private configSmartTable(): void {
    this.settings = {
      pager: {
        display: true,
        perPage: 10, // 10 elementos por página
        // Aquí puedes agregar más configuraciones si es necesario
        showFirstLast: true, // Muestra los botones de primera y última página
        showPrevNext: true, // Muestra los botones de siguiente y anterior
        align: 'center', // Alineación del paginador
      },
      actions: {
        columnTitle: 'Acción',
        add: false,
        edit: false,
        delete: false,
        custom: [
          {
            name: 'ver',
            title: 'Ver',
          },
        ],
      },
      edit: {
        editButtonContent: 'Editar',
        saveButtonContent: 'Guardar',
        cancelButtonContent: 'Cancelar',
      },
      hideSubHeader: false,
      noDataMessage: 'NO TIENES CLIENTES O AÚN NO TIENES CLIENTES VINCULADOS',
      columns: {
        estado_vinculacion:{
          filter: false,
          title: 'Estado de vinculación',
          type: 'string',
        },
        tipo_negocio:{
          filter: false,
          title: 'Tipo de negocio',
          type: 'string',
          valuePrepareFunction: (value: any) => value?.[0] || 'N/A',
        },
        nombre:{
          filter: false,
          title: 'Establecimiento',
          type: 'string',
          valuePrepareFunction: (value: any) => value?.[0] || 'N/A',
        },
        tipo_persona:{
          filter: false,
          title: 'Tipo de persona',
          type: 'string',
          valuePrepareFunction: (value: any) => value?.[0] || 'N/A',
        },
        nit:{
          filter: false,
          title: 'NIT/CC/CE',
          type: 'string',
          valuePrepareFunction: (value: any) => value?.[0] || 'N/A',
        },
        razon_social:{
          filter: false,
          title: 'Razón social',
          type: 'string',
          valuePrepareFunction: (value: any) => value?.[0] || 'N/A',
        },
        emailPropietario:{
          filter: false,
          title: 'Email (propietario)',
          type: 'string',
          valuePrepareFunction: (value: any) => value?.[0] || 'N/A',
        },
        telPropietario:{
          filter: false,
          title: 'Teléfono (propietario)',
          type: 'string',
          valuePrepareFunction: (value: any) => value?.[0] || 'N/A',
        },
        pais: {
          filter: false,
          title: 'País',
          type: 'string',
        },
        departamento: {
          filter: false,
          title: 'Departamento',
          type: 'string',
        },
        ciudad: {
          filter: false,
          title: 'Ciudad',
          type: 'string',
        },
        punto_entrega:{
          filter: false,
          title: 'Punto de entrega',
          type: 'string',
        },
        
        direccion: {
          filter: false,
          title: 'Dirección',
          type: 'string',
        },
        sillas: {
          filter: false,
          title: 'Sillas',
          type: 'number',
        },
        precio_especial:{
          filter: false,
          title: 'Precio especial',
        },
        domicilio:{
          filter: false,
          title: 'Domicilio',
        },
        estadoCartera:{
          filter: false,
          title: 'Estado de cartera',
        },
        nombreEncargado:{
          filter: false,
          title: 'Nombre del encargado del punto',
        },
        emailEncargado:{
          filter: false,
          title: 'Email del encargado',
        },
        telefonoEncargado:{
          filter: false,
          title: 'Teléfono móvil del encargado',
        },
        totalPedidos3Meses:{
          filter: false,
          title: '#pedidos (últ. 3 meses)',
          valuePrepareFunction: (value: any) => value?.[0] || '0',
        },
        totalPedidos3MesesCompra:{
          filter: false,
          title: 'Valor de pedidos (últ. 3 meses COP)',
          valuePrepareFunction: (value: any) => value?.[0] || '0',

        },
        totalProductos3Meses:{
          filter: false,
          title: '#productos (últ. 3 meses)',
          valuePrepareFunction: (value: any) => value?.[0] || '0',

        }
      },
    };
  }
  onPageChange(page: number) {
    this.paginaActual = page;
    this.getDistribuidoresVinculados(page);
  }
  buscarNombre(){
    console.log('buscando...')
    if(this.buscador !== '' && this.buscador.length > 2){
      this.loading = true;
      this.distribuidorService.getDistribuidoresVinculadosAPiNew('1', this.idDistribuidor, this.buscador).subscribe((data) => {
        this.carga_info = true;
        this.buscadorActivo = true;
        this.source.load(data.vinculaciones);  // Carga los datos en la tabla
        this.totalClientes = data.totalClientes;
        this.totalPaginas = data.totalPaginas;  
        this.source.setPaging(1, 10, this.totalPaginas > 10);  // Establece el paginador
        this.source.refresh(); // Refresca el source para actualizar la tabla
        this.loading = false;
  
      });
    }else{
      this.buscadorActivo = false;
      this.getDistribuidoresVinculados(this.paginaActual);
    }
  }
  public async onCustom(event: any): Promise<void> {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      if (event.action == 'ver') {
        this.router.navigate(['/detalle-cliente/' + event.data._id]);
      }
      if (event.action == 'delete') {
        const data_actualizada = {
          estado: 'Cancelado',
        };
        const ngbModalOptions: NgbModalOptions = {
          backdrop: 'static',
          keyboard: false,
          centered: true,
        };
        const modalRef = this.modalService.open(ConfirmacionComponent, ngbModalOptions);
        modalRef.componentInstance.msg = '¿Estás seguro de cancelar la vinculación con este punto de entrega?';
        modalRef.componentInstance.btn_msg_no = 'No, volver';
        modalRef.componentInstance.btn_msg_yes = 'Si, cancelar';
        modalRef.componentInstance.callback_no = () => {};
        modalRef.componentInstance.callback_yes = () => {
          this.clientService.cancelarVinculacionPuntoDistribuidor(event.data.id, data_actualizada).subscribe((res) => {
            window.location.reload();
          });
        };
      }
    }
  }
  async validatePermissions() {
    // eslint-disable-next-line prefer-const
    let blockUser = await this.authService.validatePermissionsUser();
    return blockUser;
  }
  /**
   * Lanza el modal que se encarga de recibir el archivo
   * de Excel con los clientes,
   * y toda la lógica necesaria para la creación de estos clientes preaprobados
   */
  async cargarClientesDesdeExcel() {
    // eslint-disable-next-line prefer-const
    let validador = await this.validatePermissions();
    if (validador) {
      const ngbModalOptions: NgbModalOptions = {
        //Evita que al hacer click por fuera se cierre el modal
        backdrop: 'static',
        keyboard: false,
        centered: true,
      };

      this.modalService.open(CargarClientesExcelComponent, ngbModalOptions);
    }
  }
}
