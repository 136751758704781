/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AfterViewInit, Component, DoCheck, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faTimes, faChevronRight, faTrash } from '@fortawesome/free-solid-svg-icons';
import { CarritoService } from '../services/carrito/carrito.service';
import { DistribuidorService } from '../services/distribuidor/distribuidor.service';
import { RestService } from '../services/rest/rest.service';
import { LocalStorageService } from '../services/local-storage/local-storage.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { ProdSugeridosAddComponent } from 'src/app/modal/prod-sugeridos-add/prod-sugeridos-add.component';
import { AuthService } from 'src/app/services/auth/auth.service';
import { CargandoGenericoComponent } from '../modal/cargando-generico/cargando-generico.component';
import { ComparandoDistribuidoresComponent } from '../modal/comparando-distribuidores/comparando-distribuidores.component';
import { CurrencyPipe } from '@angular/common';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
import { ProductsService } from '../services/products/products.service';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'app-carrito',
  templateUrl: './carrito.component.html',
  styleUrls: ['./carrito.component.css'],
})
export class CarritoComponent implements OnInit {
  private destroy$ = new Subject<void>();
  // Objeto pedido para función de crear
  public pedido = {
    usuario_horeca: <any>'',
    punto_entrega: '',
    trabajador: <any>'',
    distribuidor: '',
    fecha: new Date(),
    ciudad: '',
    direccion: '',
    total_pedido: 0,
    subtotal_pedido: 0,
    descuento_pedido: 0,
    puntos_ganados: 0,
    puntos_redimidos: 0,
    codigo_descuento: <any>[],
    tiempo_estimado_entrega: '',
    tiempo_tracking_hora: new Date(),
    pre_factura: '',
    productos: <any>[],
    // Arreglo para guardar lista de productos comprados
    listProductos: <any>[],
    // Arreglo para guardar lista de puntos de entrega
    puntoEntrega: <any>[],
    // Arreglo data del usuario para lista de compra
    dataUser: <any>[],
    // Arreglo tipo de usuario para lista de compra
    tipoNegocioUser: <any>'',
    id_pedido: <any>undefined,
    metodo_pago: '',
  };
  // Fecha actual
  public current_date = new Date(moment.utc().format('DD MMMM YYYY'));
  // Variables carrito
  public punto_entrega: any;
  public productosArray = <any>[];
  public codigosDescuentoArray = <any>[];
  public listaCodigosDescuentoOriginal: any = [];
  public listaCodigosDescuento: any;
  public codigoSeleccionado: any;
  // Referencias a íconos FontAwesome para la UI
  public faTimes = faTimes;
  public faChevronRight = faChevronRight;
  public faTrash = faTrash;
  // Imagen producto placeholder
  public product_placeholder = '../../assets/img/product-placeholder.png';
  public distribuidor_placeholder = '../../assets/img/icon-organizacion.png';
  // Productos en carrito
  public puntosGanados: any = 0;
  public puntosUsados: any = 0;
  public descuento: any = 0;
  public productos: any;
  // Cantidad de productos en carrito
  public productos_length = 0;
  // Cantidad de unidades en carrito
  public total_unidades_compra = 0;
  // Data del pedido
  public order: any;
  // Mensaje de descuento máximo
  public descuento_excedido = false;
  public limite_codigos = false;
  // Total a pagar
  public totalPrice = 0;
  public subtotalPrice = 0;
  // ID distribruibido
  public id: any = '';
  // Data del distribuidor
  public distribuidor: any;
  public vinculacion_distribuidor_punto: any;
  // Data completa punto de entrega
  public punto_entrega_data: any;
  // Productos en carrito
  public productosencarrito: any[] = [];
  // Productos de completa tu compra
  public productosCompletaCompra: any[] = [];
  // Modal de carga
  public modalCarga?: NgbModalRef;
  // Bandera para detalles de edición de pedido en carrito
  public banderaEditar = false;
  // Id del pedido en curso para opción editar
  public pedidoId = '';
  // Parametros
  public parametros: any;
  // Evita que al hacer click por fuera se cierre el modal
  public ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
    centered: true,
    size: 'md',
  };
  // Flag editar pedido
  public flag_editar_pedido = false;
  public edicionPed = false;
  // Pedido a editar
  public pedio_editar: any;
  metodosPagoDistribuidor: any[] = [];
  public metodoPagoSelecionado = '';
  pedidoGuardado: any;
  subscribeCartValidation!: Subscription;
  pedidoActualizado = false;
  carro_vacio: boolean = false;
  private cartInitialized = false; // Nueva bandera

  constructor(
    private modalService: NgbModal,
    private cartservice: CarritoService,
    private router: Router,
    private distribuidorService: DistribuidorService,
    private restService: RestService,
    public localStorage: LocalStorageService,
    private currencyPipe: CurrencyPipe,
    public authService: AuthService,
    public productsService: ProductsService,

    private location: Location
  ) {}
  async ngOnInit() {
    this.loadCartData();
  }
  async loadCartData() {
    const volverPedido = await this.localStorage.getItem<any>('volver_pedir_pedido');
    if (volverPedido && volverPedido._id) {
      console.log('volver a pedir');
      try {
        const respVolver = volverPedido;
        console.log('respVolver', respVolver);
        // 2. Verificar si es un objeto y tiene la propiedad _id (sin cambios):
        if (typeof respVolver === 'object' && respVolver !== null && respVolver._id) {
          this.validacionPedidoVolverAPedir(respVolver);
        } else {
          console.warn('Datos inválidos en volver_pedir_pedido:', respVolver);
          localStorage.removeItem('volver_pedir_pedido'); // Limpiar datos incorrectos
        }
      } catch (error) {
        console.error('Error al parsear volver_pedir_pedido:', error);
        localStorage.removeItem('volver_pedir_pedido'); // Limpiar datos incorrectos
      }
      return;
    }

    const editarPedido = await this.localStorage.getItem<any>('editar_pedido_curso');
    if (editarPedido) {
      console.log('editano pedido');
      if (typeof editarPedido === 'string' && editarPedido.trim() !== '') {
        try {
          const respEditar = JSON.parse(editarPedido);
          if (typeof respEditar === 'object' && respEditar !== null && respEditar._id) {
            this.flag_editar_pedido = true;
            this.pedio_editar = respEditar;
            this.metodoPagoSelecionado = this.pedio_editar.metodo_pago;
            this.cargaGeneralInfoCarrito(null, null, true);
          } else {
            localStorage.removeItem('editar_pedido_curso');
          }
        } catch (error) {
          localStorage.removeItem('editar_pedido_curso');
        }
        return;
      }
    }

    this.cargarCarrito();
  }
  /**
   * Opción para volver a hacer el pedido donde valida en el back el inventario y la existencia de producto , luego carga info en carrito
   * @param resp información del pedido realizado anteriormente
   */
  validacionPedidoVolverAPedir(resp: any) {
    /** información del pedido detallado */
    this.restService
      .getJWT('pedido/validacion/volverPedir/' + resp._id)
      .toPromise()
      .then((resp2: any) => {
        this.id = resp2.pedido_original[0].distribuidor;
        this.id = this.id.replace(/['"]+/g, '');
        this.pedidoGuardado = resp2.pedido_original[0];
        //this.localStorage.setItem<any>('pedidosug', this.pedidoGuardado);
        if (resp2.success) {
          if (resp2.data.length === 0) {
            this.cargarProductosCarro(resp2);
          } else {
            this.modalCarga?.close();
            const modalRef = this.modalService.open(ProdSugeridosAddComponent, this.ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
            modalRef.componentInstance.title = '¡Ten en cuenta!';
            modalRef.componentInstance.msg = 'Los siguientes productos han variado en precio y/o existencia';
            modalRef.componentInstance.btn_msg = 'Volver';
            modalRef.componentInstance.dataResult = resp2.data;
            modalRef.componentInstance.close_callback = (respModal: any) => {
              if (respModal === 'cargar') {
                this.cargarProductosCarro(resp2);
              } else {
                this.localStorage.removeItem('volver_pedir_pedido');
                this.router.navigate(['/pedidos/', this.pedidoGuardado._id]);
                //this.actualizarPedido(resp._id, null, true);
              }
            };
          }
        } else {
          this.location.back();
          //this.cargarProductosCarro(resp2);
        }
      });
  }

  /**
   *
   *
   * METODO NUEVO CARRITO
   *
   */
  cargarCarrito() {
    console.log('llegando al cargar carrito');
    this.subscribeCartValidation = this.cartservice.cart$.subscribe(async (productos) => {
      this.productos_length = productos.length;
      if (this.productos_length > 0) {
        this.productos = productos;
        this.localStorage.setItem<any>('distribuidorCarrito', productos[0].codigo_distribuidor);
        this.id = localStorage.getItem('distribuidorCarrito');
        this.id = this.id.replace(/['"]+/g, '');
        //this.validarProd();
        //Totales
        this.cartservice.totalPrice$.subscribe((totalPrice) => {
          this.totalPrice = totalPrice;
          this.subtotalPrice = totalPrice;
        });
        //Descuentos y puntos
        this.cartservice.orderList$.subscribe(async (order) => {
          this.order = order;
          this.descuento_excedido = this.cartservice.descuentosVerificacion(this.descuento, this.subtotalPrice).status;
          this.totalPrice = this.cartservice.descuentosVerificacion(this.descuento, this.subtotalPrice).total;
          await this.calculosPuntosGanados();
          this.total_unidades_compra = await this.calculoUnidades();
        });
        //Productos
        this.cartservice.cart$.subscribe(async (product) => {
          this.productosencarrito = product;
          await this.encontrarProductoEnCarrito();
        });
        if (this.productos_length > 0) {
          await this.validarDistribuidorVinculado();
        }
      } else {
        this.carro_vacio = true;
      }
    });
  }
  /**
   *
   *
   * FIN METODO NUEVO CARRITO
   *
   */
  /**
   * Recorre el servicio del carrito y carga esa información en el DOM
   */
  async cargaGeneralInfoCarrito(pedido: any, productos: any, editar: any) {
    if (productos) {
      const dataproductos = productos;
      // eslint-disable-next-line prettier/prettier
      for (const prod of dataproductos) {
        await this.cartservice.addCart(
          prod.dataCompletaP,
          prod.unidad,
          prod.caja,
          prod.caja,
          prod.dataCompletaP.precios[0].inventario_unidad
        );
      }
      this.cartservice.orderNext();
    }
    // Productos
    this.subscribeCartValidation = this.cartservice.cart$.subscribe(async (productos) => {
      this.productos = productos;

      //ACA
      this.productos_length = productos.length;
      if (this.productos_length > 0) {
        this.localStorage.setItem<any>('distribuidorCarrito', productos[0].codigo_distribuidor);
        this.id = localStorage.getItem('distribuidorCarrito');
        this.id = this.id.replace(/['"]+/g, '');
        this.validarProd();
        //Totales
        this.cartservice.totalPrice$.subscribe((totalPrice) => {
          this.totalPrice = totalPrice;
          this.subtotalPrice = totalPrice;
        });
        //Descuentos y puntos
        this.cartservice.orderList$.subscribe(async (order) => {
          this.order = order;
          this.descuento_excedido = this.cartservice.descuentosVerificacion(this.descuento, this.subtotalPrice).status;
          this.totalPrice = this.cartservice.descuentosVerificacion(this.descuento, this.subtotalPrice).total;
          await this.calculosPuntosGanados();
          this.total_unidades_compra = await this.calculoUnidades();
        });
        //Productos
        this.cartservice.cart$.subscribe(async (product) => {
          this.productosencarrito = product;
          await this.encontrarProductoEnCarrito();
        });
        if (this.productos_length > 0) {
          await this.validarDistribuidorVinculado();
        }
      } else {
        this.carro_vacio = true;
      }
    });
  }
  validarProd() {
    /** Flag para mostrar puntos feat si estan dentro del rango de fechas */
    this.productos.forEach((producto: any) => {
      // Fecha actual
      this.current_date = new Date(moment.utc().format('DD MMMM YYYY'));
      producto.flag_aplica_puntos_feat = false;
      const fecha_apertura = new Date(moment.utc(producto.fecha_apertura_puntosft).format('DD MMMM YYYY'));
      const fecha_cierre = new Date(moment.utc(producto.fecha_cierre_puntosft).format('DD MMMM YYYY'));
      if (
        producto.fecha_apertura_puntosft &&
        producto.fecha_cierre_puntosft &&
        fecha_apertura <= this.current_date &&
        fecha_cierre >= this.current_date
      ) {
        producto.flag_aplica_puntos_feat = true;
      }
    });
  }

  async validarDistribuidorVinculado() {
    const userM = this.authService.user_horeca;
    //this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.id = JSON.parse(localStorage.getItem('distribuidorCarrito')!);
    this.id = this.id.replace(/['"]+/g, '');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.punto_entrega_data = JSON.parse(localStorage.getItem('punto_entrega_seleccionado')!);
    // Estado de vinculación entre un punto de entrega y distribuidor
    let idPunto = '';
    if (this.punto_entrega_data instanceof Array) {
      idPunto = this.punto_entrega_data[0]._id;
    } else {
      idPunto = this.punto_entrega_data._id;
    }
    let dataDist = await this.distribuidorService.getEstadoVinculacionByDistribuidorPunto(this.id, idPunto).toPromise();
    if (!dataDist.data[0]) {
      ///this.modalCarga.close();
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = `El distribuidor ya no tiene vinculación con el punto del pedido`;
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
        this.router.navigate(['/pedidos/', this.pedidoGuardado._id]);
      };
      return;
    } else {
      this.vinculacion_distribuidor_punto = dataDist;
      this.vinculacion_distribuidor_punto = this.vinculacion_distribuidor_punto.data[0];
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, prefer-const

      let metodoP = this.vinculacion_distribuidor_punto.distribuidor.metodo_pago.split(',');
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, prefer-const
      let arrMetodoPago = [];
      for (const metodos of metodoP) {
        arrMetodoPago.push(metodos);
      }
      this.metodosPagoDistribuidor = arrMetodoPago;
      // Información de distribuidor y sus productos
      await this.fetchDistribuidor();
      // Cargar descuentos listad
      this.loadCodigosDescuento();
      // Parámetros generale
      this.loadParametros();
      //this.modalCarga.close();
    }
  }
  /********************************** Cargar la información al carrito **********************************/

  /**
   * Recupera data del distribuidor que recibirá en pedido
   */
  async fetchDistribuidor() {
    this.distribuidorService.getDistribuidorProductosData(this.id).subscribe(async (result) => {
      this.distribuidor = await result;
      await this.encontrarProductoEnCarrito();
      this.fetchProdsCompletaCompra();
    });
  }
  /**
   * Opción para editar el pedido en curso carga la información del pedido en el carrito
   * primero cancela el pedido y borra toda la data auxiliar y crea un nuevo pedido con lo editado
   */
  editarPedidoEnCurso() {
    //this.crearPedido();
    if (!this.pedio_editar || !this.pedio_editar._id) {
      console.error('No se puede editar un pedido sin ID.');
      return;
    }

    let pedidoArr = {
      pedido_id: this.pedio_editar._id,
      productos: this.order.products
        ? this.order.products.map((prod: { product: any; unidad: any }) => ({
            product_id: prod.product,
            cantidad: prod.unidad,
          }))
        : [],
    };
    if (pedidoArr.productos.length === 0) {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Espera!';
      modalRef.componentInstance.msg = `¡Debes agregar al menos un producto!`;
      modalRef.componentInstance.btn_msg = 'Listo';
      modalRef.componentInstance.close_callback = () => {};
    } else {
      this.updatePedido(pedidoArr);
    }
  }
  updatePedido(pedido: any) {
    const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.msg = `¿Estás seguro que deseas editar este pedido?`;
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = `Si, editar`;
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      modalRef.close(); // Cierra el modal de confirmación ANTES de abrir el siguiente

      this.productsService
        .updatePedidoNew(pedido)
        .pipe(
          // Usar pipe para manejar la respuesta y errores de forma más elegante
          tap((resp: any) => {
            // tap para el éxito
            const successModalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
            successModalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
            successModalRef.componentInstance.title = '¡Genial!';
            successModalRef.componentInstance.msg = `¡Tu pedido ha sido actualizado!`;
            successModalRef.componentInstance.btn_msg = 'Listo';
            successModalRef.componentInstance.close_callback = () => {
              this.edicionPed = true;
              localStorage.removeItem('editar_pedido_curso');
              this.cartservice.emptyCart();
              this.router.navigate(['/pedidos/', pedido.pedido_id]);
            };
          }),
          catchError((error: any) => {
            // catchError para manejar errores
            console.error('Error al actualizar pedido:', error); // Log del error para debuggear
            const errorModalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions); // Modal de error
            errorModalRef.componentInstance.img_src = '../../../assets/img/icon-warning-rojo.png'; // Imagen de error
            errorModalRef.componentInstance.title = 'Error';
            errorModalRef.componentInstance.msg = `Hubo un error al actualizar el pedido. Por favor, inténtalo de nuevo.`;
            errorModalRef.componentInstance.btn_msg = 'Ok';
            return []; // o throwError(error) si quieres propagar el error
          })
        )
        .subscribe(); // No olvides suscribirte al observable
    };
  }
  cargarProductosCarro(resp2: any) {
    const resultado = resp2.listProductos;
    //distribuidor
    this.localStorage.setItem<any>('distribuidorCarrito', resp2.pedido_original[0].distribuidor);
    this.id = localStorage.getItem('distribuidorCarrito');
    this.id = this.id.replace(/['"]+/g, '');
    this.localStorage.setItem<any>('punto_entrega_seleccionado', resp2.pedido_original[0].data_punto[0]);
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, prefer-const
    let punto = JSON.parse(localStorage.getItem('punto_entrega_seleccionado')!);
    this.authService.punto_seleccionado = resp2.pedido_original[0].data_punto[0];
    this.cargaGeneralInfoCarrito(resp2.pedido_original[0]._id, resultado, false);
    /*if (!punto) {
      punto = resp2.pedido_original[0].data_punto;
      this.localStorage.setItem<any>('punto_entrega_seleccionado', punto);
      this.authService.punto_seleccionado = resp2.pedido_original[0].data_punto;
      this.cargaGeneralInfoCarrito(resp2.pedido_original[0]._id, resultado);
    } else {
      if (punto._id === resp2.pedido_original[0].data_punto[0]._id) {
        this.authService.punto_seleccionado = resp2.pedido_original[0].data_punto[0];
        this.localStorage.setItem<any>('punto_entrega_seleccionado', resp2.pedido_original[0].data_punto[0]);
        this.cargaGeneralInfoCarrito(resp2.pedido_original[0]._id, resultado);
      } else {
        const modalRef = this.modalService.open(ComparandoDistribuidoresComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Ten en cuenta!';
        modalRef.componentInstance.msg =
          'El pedido sugerido a cargar, no corresponde al punto de entrega actual seleccionado.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.dataResult = {};
        modalRef.componentInstance.close_callback = async (respModal: any) => {
          if (respModal === 'cargar') {
            await this.cartservice.emptyCartSugerido();
            this.authService.punto_seleccionado = resp2.pedido_original[0].data_punto[0];
            this.localStorage.setItem<any>('punto_entrega_seleccionado', resp2.pedido_original[0].data_punto[0]);
            this.cargaGeneralInfoCarrito(resp2.pedido_original[0]._id, resultado);
            //this.cargaGeneralInfoCarrito();
          } else {
            this.authService.punto_seleccionado = punto;
            this.cargaGeneralInfoCarrito(null, null);
          }
          //this.amptyCart();
          //this.router.navigate(['/pedidos']);
        };
      }
    }*/
  }
  /**
   * Trae los productos para poblar la parte de Completa de compra
   * con los saldos y promociones del distribuidor seleccionado
   */
  async fetchProdsCompletaCompra() {
    try {
      const resp_prods: any = await this.restService
        .getJWT(`saldos_promos_por_distribuidor/distribuidor/${this.distribuidor?.distribuidor._id}`)
        .toPromise();
      this.productosCompletaCompra = resp_prods.data.productos;
    } catch (err) {
      console.log(err);
    }
  }

  /*************************************** Editar y funciones del carrito ***************************************/

  /**
   * Cálculo de puntos asociados a los productos seleccionados tanto en unidades como cajas
   */
  calculosPuntosGanados() {
    let puntos = 0;
    for (const i in this.productos) {
      /** Suma los puntos solo si estan dentro del rango */
      const fecha_apertura = new Date(moment.utc(this.productos[i].fecha_apertura_puntosft).format('DD MMMM YYYY'));
      const fecha_cierre = new Date(moment.utc(this.productos[i].fecha_cierre_puntosft).format('DD MMMM YYYY'));
      if (this.productos[i].mostrarPF) {
        const order = this.order.products.find((y: { product: any }) => y.product == this.productos[i]._id);
        puntos = puntos + this.productos[i].precios[0].puntos_ft_unidad * order.unidad;
      }
    }
    this.puntosGanados = puntos;
  }

  /**
   * Carga información de los códigos de descuento que tiene disponible el punto de entrega seleccionado , validando que esten vigentes y disponibles
   */
  loadCodigosDescuento() {
    this.localStorage.getItem<any>('punto_entrega_seleccionado').then((resp) => {
      this.pedido.punto_entrega = resp;
      this.punto_entrega = resp;
      /** Trae los datos del trabajador */

      this.restService
        .getJWT('codigos_generados/vigentes/' + resp._id)
        .toPromise()
        .then((resp2: any) => {
          this.listaCodigosDescuento = resp2;
          /** asignación adecuada para inicio de dos array igual */
          this.listaCodigosDescuento.forEach((element: any) => {
            this.listaCodigosDescuentoOriginal.push(element);
          });
        });
    });
  }

  /**
   * Botón aplicar que agrega un codigo de descuento al pedido actual
   */
  async addCodDescuento() {
    if (this.codigosDescuentoArray.length == 3) {
      this.limite_codigos = true;
      setTimeout(() => {
        this.limite_codigos = false;
      }, 2000);
    } else {
      const index = await this.listaCodigosDescuento
        .map((element: any) => element._id)
        .indexOf(this.codigoSeleccionado);
      this.codigosDescuentoArray.push({
        _id: this.codigoSeleccionado,
        codigo_creado: this.listaCodigosDescuento[index].codigo_creado,
      });
      /** suma puntos usados */
      this.puntosUsados = this.puntosUsados + this.listaCodigosDescuento[index].valor_paquete;
      this.descuento = /* this.descuento +  */ this.puntosUsados * this.parametros[0].valor_1puntoft;
      this.listaCodigosDescuento.splice(index, 1);
      if (this.descuento > this.subtotalPrice) {
        this.descuento_excedido = true;
        this.totalPrice = this.subtotalPrice - this.descuento;
      } else {
        this.totalPrice = this.subtotalPrice - this.descuento;
      }
    }
  }

  /**
   * Se retorna o se quita el codigo de descuento aplicado antes de crear como tal el pedido
   */
  returnedCodDescuento(codigoId: any) {
    this.listaCodigosDescuentoOriginal.map((element: any) => {
      if (element._id == codigoId) {
        this.listaCodigosDescuento.push(element);
        /** resta puntos usados */
        this.puntosUsados = this.puntosUsados - element.valor_paquete;
        this.descuento = this.descuento - element.valor_paquete * this.parametros[0].valor_1puntoft;
        this.totalPrice = this.subtotalPrice - this.descuento;
        if (this.descuento < this.subtotalPrice) {
          this.descuento_excedido = false;
        }
      }
    });
    /** clean list */
    const index2 = this.codigosDescuentoArray.map((element: any) => element._id).indexOf(codigoId);
    this.codigosDescuentoArray.splice(index2, 1);
  }

  /**
   * Carga de parámetros generales para calor de punto feat en conversion para calcular el equivalente del descuento al pedido en pesos
   */
  loadParametros() {
    /** Trae los datos del trabajador */
    this.restService
      .getJWT('parametrizacion/')
      .toPromise()
      .then((resp: any) => {
        this.parametros = resp;
      });
  }

  /**
   * El objetivo de esto es sumar o restar la cantidad de cajas o unidades que se comprará de cada producto
   * @param operation es la operación que sera ejecutada (false: resta, true: suma)
   * @param i index del producto en el que se aplicara la operación.
   * @param item identifica si se ejecutará la operación en cajas o productos
   */
  public calculatePrice(operation: boolean, i: number, item: string, cant: any) {
    if (cant > 0) {
      this.cartservice.sumaresta(operation, i, item, cant);
      this.descuento_excedido = this.cartservice.descuentosVerificacion(this.descuento, this.subtotalPrice).status;
      this.totalPrice = this.cartservice.descuentosVerificacion(this.descuento, this.subtotalPrice).total;
    }
  }
  quantity(operation: boolean, i: number, item: string) {
    this.cartservice.sumaresta(operation, i, item, 0);
    this.descuento_excedido = this.cartservice.descuentosVerificacion(this.descuento, this.subtotalPrice).status;
    this.totalPrice = this.cartservice.descuentosVerificacion(this.descuento, this.subtotalPrice).total;
  }

  /**
   * Calcula el total de cajas de un producto en base a las unidades pedidas
   * @param und_x_caja unidades que vienen por caja.
   * @param total_unidades total unidades pedidas por el usuario de un producto.
   */
  totalCajasPedido(und_x_caja: number, total_unidades: number) {
    if (und_x_caja !== 0) {
      const total_cajas_pedido = Math.round((total_unidades / und_x_caja) * 100) / 100;
      return total_cajas_pedido;
    } else {
      return 0;
    }
  }

  /**
   * Calcula las unidades pendientes a pedir para completar otra caja
   * @param und_x_caja unidades que vienen por caja.
   * @param total_unidades total unidades pedidas por el usuario de un producto.
   */
  unidadesPendientesCaja(und_x_caja: number, total_unidades: number) {
    if (und_x_caja !== 0) {
      const unidades_pendientes_caja = und_x_caja - (total_unidades % und_x_caja);
      return unidades_pendientes_caja;
    } else {
      return 0;
    }
  }

  /**
   * Esta función tiene como objetivo añadir un nuevo producto al carrito
   * @param product es el producto que se va a añadir
   * @param unidad cantidad de unidades a agregar
   * @param caja cantidad de cajas a agregar
   */
  agregarProduct(
    product: any,
    unidad: number,
    caja: number,
    und_x_caja: number,
    inv_unidad: number,
    flag_nuevo_producto: boolean
  ) {
    const unidades = parseInt(unidad.toString());
    const cajas = parseInt(caja.toString());
    const unds_x_caja = parseInt(und_x_caja.toString());
    const inventario = parseInt(inv_unidad.toString());
    localStorage.setItem('distribuidorCarrito', this.id);
    this.cartservice.addCart(product, unidades, cajas, unds_x_caja, inventario);
    if (flag_nuevo_producto === true) {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
      modalRef.componentInstance.title = '¡Genial!';
      modalRef.componentInstance.msg = 'Se ha agregado el producto al carrito, modifica las unidades desde allí.';
      modalRef.componentInstance.btn_msg = 'Listo';
    }
  }

  /**
   * Esta función se encarga de remover un producto del carrito.
   * @param i index del producto que sera removido.
   */
  removeCart(i: number) {
    /** Elimina el producto del carrio */
    this.cartservice.removeCart(i);
    this.descuento_excedido = this.cartservice.descuentosVerificacion(this.descuento, this.subtotalPrice).status;
    this.totalPrice = this.cartservice.descuentosVerificacion(this.descuento, this.subtotalPrice).total;
  }

  /**
   * Vaciar carrito
   */
  async amptyCart() {
    this.localStorage.removeItem('volver_pedir_pedido'),
      this.localStorage.removeItem('editar_pedido_curso'),
      this.localStorage.removeItem('cart'),
      this.localStorage.removeItem('pedidosug');
    this.cartservice.emptyCart();
  }

  /**
   * Esta función tiene como objetivo identificar que productos existen
   * en el carrito de compras. Gracia a esto podemos saber en que momentos hacer el cambio de botones
   * de agregar item por el de agregar y restar items
   */
  async encontrarProductoEnCarrito() {
    if (this.distribuidor) {
      Promise.all(
        this.distribuidor.productos.map(async (element: any) => {
          let enCarrito;
          this.productosencarrito.forEach(async (carrito) => {
            if (carrito._id == element._id) {
              enCarrito = carrito;
              const index = this.productosencarrito.indexOf(enCarrito);
              if (index > -1) {
                element.index = index + 1;
              }
            }
          });
        })
      );
    }
  }

  /**
   * Metodo para guardar pedido
   * @returns pedido generado
   */
  async crearPedido() {
    if (!this.metodoPagoSelecionado) {
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = `Debes agregar un método de pago`;
      modalRef.componentInstance.btn_msg = 'Volver';
    } else {
      /** Valida que se cumpla el valor minimo de pedido de un distribuidor */
      if (this.distribuidor.distribuidor.valor_minimo_pedido > this.totalPrice) {
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg = ` ¡El valor del pedido es menor al que es permitido por el distribuidor (${this.currencyPipe.transform(
          this.distribuidor.distribuidor.valor_minimo_pedido,
          '$ ',
          'symbol',
          '1.0-0'
        )}), agrega más productos y podrás completar la compra!`;
        modalRef.componentInstance.btn_msg = 'Volver';
      } else {
        /** Asignación de valores para envío */
        this.pedido.usuario_horeca = this.authService.user_horeca ? this.authService.user_horeca._id : '';
        this.pedido.trabajador = this.authService.user ? this.authService.user._id : '';
        this.pedido.distribuidor = this.distribuidor.distribuidor._id;
        this.pedido.tiempo_estimado_entrega = this.distribuidor.distribuidor.tiempo_entrega;
        this.pedido.ciudad = this.punto_entrega.ciudad;
        this.pedido.direccion = this.punto_entrega.direccion;
        this.pedido.total_pedido = this.totalPrice;
        this.pedido.subtotal_pedido = this.subtotalPrice;
        this.pedido.descuento_pedido = this.descuento;
        this.pedido.puntos_ganados = this.puntosGanados;
        this.pedido.puntos_redimidos = this.puntosUsados;
        this.pedido.codigo_descuento = this.extractIds(this.codigosDescuentoArray);
        this.pedido.productos = this.order.products;
        this.pedido.metodo_pago = this.metodoPagoSelecionado;
        // Si es la edicio de un pedido, el ID ya existe
        if (this.flag_editar_pedido) {
          this.pedido.id_pedido = this.pedio_editar.id_pedido;
        }
        /** Valida que hay unidades en inventario por producto para completar el pedido */
        try {
          this.modalCarga = this.modalService.open(CargandoGenericoComponent, this.ngbModalOptions);
          /** Variables */
          let resp_prod: any;
          let unidades_inventario = 0;
          let unidades_pedido = 0;
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          let arrProducto;
          // eslint-disable-next-line prefer-const
          arrProducto = [];
          for (const prod_aux of this.pedido.productos) {
            resp_prod = await this.restService.getJWT(`producto/${prod_aux.product}`).toPromise();
            unidades_inventario = resp_prod.precios[0].inventario_unidad;
            unidades_pedido = prod_aux.unidad;
            // Si es la edicio de un pedido, se deben sumar las uidades del pedido en edición al inv.
            if (this.flag_editar_pedido) {
              const unidades_pedido_inicial = this.pedio_editar.productos.find(
                (prod: any) => prod.product == prod_aux.product
              );
              if (unidades_pedido_inicial) {
                unidades_inventario += unidades_pedido_inicial.unidad;
              } else {
                unidades_inventario += prod_aux.unidad;
              }
            }
            if (unidades_pedido > unidades_inventario) {
              this.modalCarga.close();
              const modalRef2 = this.modalService.open(SimpleComponent, this.ngbModalOptions);
              modalRef2.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
              modalRef2.componentInstance.title = '¡Oh oh!';
              modalRef2.componentInstance.msg = `No hay suficientes unidades de ${resp_prod.nombre} para completar el pedido. Por favor cambia el pedido e intenta de nuevo`;
              modalRef2.componentInstance.btn_msg = 'Volver';
              return;
            } else {
              resp_prod.precios[0].inventario_unidad = unidades_inventario - prod_aux.unidad;
            }
            arrProducto.push(resp_prod);
          }
          this.pedido.listProductos = arrProducto;
          this.pedido.puntoEntrega = this.punto_entrega;
          this.pedido.tipoNegocioUser = this.authService.user_horeca ? this.authService.user_horeca.tipo_negocio : '';
          // eslint-disable-next-line prefer-const
          this.pedidoGuardado = JSON.parse(localStorage.getItem('pedidosug')!);
          if (this.pedidoGuardado || this.pedio_editar) {
            this.actualizarPedido(this.pedidoGuardado._id, this.pedido, true);
          } else {
            //this.pedido.tipoUsuario = arrProducto;
            // eslint-disable-next-line prettier/prettier

            const resp_pedido: any = await this.restService.postJWT('pedido', this.pedido).toPromise();
            // Notifica a las organizaciones
            this.notificarOrganizacion();
            //Si el usuario que está haciendo el pedido tiene permisos de aprobación, el pedido pasa automáticamente a Aprobado Interno
            if (
              this.authService.user?.tipo_trabajador == 'PROPIETARIO/REP LEGAL' ||
              this.authService.user?.tipo_trabajador == 'ADMINISTRADOR APROBADOR'
            ) {
              const _id_pedido: string = resp_pedido.pedido._id;
              const _id_trab: string = this.authService.user?._id || '';
              //Actualiza el inventario de los productos en el catalogo
              for (const prod_aux of this.pedido.productos) {
                resp_prod = await this.restService.getJWT(`producto/${prod_aux.product}`).toPromise();
                resp_prod.precios[0].inventario_unidad = resp_prod.precios[0].inventario_unidad - prod_aux.unidad;
                resp_prod.precios[0].inventario_caja = Math.max(
                  Math.round((resp_prod.precios[0].inventario_unidad / prod_aux.und_x_caja) * 100) / 100,
                  0
                );
                await this.restService
                  .putJWT(`producto/${prod_aux.product}`, { precios: resp_prod.precios })
                  .toPromise();
              }
              const respuestaActPed = await this.restService
                .putJWT(`pedido_update/${_id_pedido}`, { estado: 'Aprobado Interno' })
                .toPromise();
              //.putJWT(`pedido/${_id_pedido}/${_id_trab}`, { estado: 'Aprobado Interno' }).toPromise();
            }
            //this.modalCarga.close();
            this.pedidoActualizado = true;
            const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
            modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
            modalRef.componentInstance.title = '¡Genial!';
            modalRef.componentInstance.msg =
              'Tu pedido se creó con éxito, ahora podrás monitorearlo desde la pestaña de pedidos.';
            modalRef.componentInstance.btn_msg = 'Listo';
            modalRef.componentInstance.close_callback = () => {
              this.amptyCart();
              this.localStorage.removeItem('volver_pedir_pedido'),
                this.localStorage.removeItem('editar_pedido_curso'),
                this.localStorage.removeItem('cart'),
                this.localStorage.removeItem('pedidosug');

              this.modalCarga?.close();
              this.router.navigate(['/pedidos']);
            };
          }
        } catch (err) {
          this.modalCarga?.close();
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg = 'Ocurrió un error creando el pedido ¡Por favor intenta de nuevo más tarde!';
          modalRef.componentInstance.btn_msg = 'Volver';
          modalRef.componentInstance.close_callback = () => {
            this.amptyCart();
            this.localStorage.removeItem('volver_pedir_pedido'),
              this.localStorage.removeItem('editar_pedido_curso'),
              this.localStorage.removeItem('cart'),
              localStorage.removeItem('pedidosug');

            this.router.navigate(['/pedidos']);
          };
        }
      }
    }
  }
  async actualizarPedido(idPedido: any, pedido: any, sugerido: boolean) {
    let estadoAct = 'Aprobado Interno';
    let metodo = '';
    let productos;
    let total_pedidoP;
    let subtotal_pedidoP;
    let descuento_pedidoP;
    let puntos_redimidosP;
    let puntos_ganadosP;
    let codigo_descuentoP;
    if (!pedido) {
      estadoAct = 'Sugerido cancelado';
      metodo = 'Sugerido cancelado';
      // eslint-disable-next-line no-self-assign
    } else {
      metodo = pedido.metodo_pago;
      productos = pedido.productos;
      total_pedidoP = pedido.total_pedido;
      subtotal_pedidoP = pedido.subtotal_pedido;
      descuento_pedidoP = pedido.descuento_pedido;
      puntos_redimidosP = pedido.puntos_redimidos;
      puntos_ganadosP = pedido.puntos_ganados;
      codigo_descuentoP = pedido.codigo_descuento;
    }
    const updatePedido = {
      metodo_pago: metodo,
      estado: estadoAct,
      productos: productos,
      listProducto: pedido.listProductos,
      total_pedido: total_pedidoP,
      subtotal_pedido: subtotal_pedidoP,
      descuento_pedido: descuento_pedidoP,
      puntos_redimidos: puntos_redimidosP,
      puntos_ganados: puntos_ganadosP,
      codigo_descuento: codigo_descuentoP,
    };
    if (!pedido) {
      //delete updatePedido.productos;
      delete updatePedido.total_pedido,
        delete updatePedido.subtotal_pedido,
        delete updatePedido.descuento_pedido,
        delete updatePedido.puntos_redimidos,
        delete updatePedido.puntos_ganados,
        delete updatePedido.codigo_descuento;
    }
    const updateTraking = {
      estado_nuevo: estadoAct,
    };
    const updateReporte = {
      estaActTraking: estadoAct,
    };
    const updateMaster = {
      idPedido: idPedido,
      sugerido: sugerido,
      updatePedido: updatePedido,
      updateTraking: updateTraking,
      updateReporte: updateReporte,
    };
    const resp_pedido: any = await this.restService.postJWT('updateMaster', updateMaster).toPromise();
    localStorage.removeItem('pedidosug');
    if (resp_pedido.success) {
      this.pedidoActualizado = true;
      // eslint-disable-next-line prefer-const
      let msg = 'Tu pedido se actualizó con éxito, ahora podrás monitorearlo desde la pestaña de pedidos';
      // eslint-disable-next-line prefer-const
      let title = '¡Proceso exitoso!';
      // eslint-disable-next-line prefer-const
      let img = '../../../assets/img/icon-check-verde.png';
      if (metodo === 'Sugerido cancelado') {
        msg = 'Tu pedido sugerido se ha cancelado con éxito';
        title = '¡Proceso exitoso!';
        img = '../../../assets/img/icon-warning-amarillo.png';
      }
      const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
      modalRef.componentInstance.img_src = img;
      modalRef.componentInstance.title = title;
      modalRef.componentInstance.msg = msg;
      modalRef.componentInstance.btn_msg = 'Volver';
      modalRef.componentInstance.close_callback = () => {
        this.amptyCart();
        this.modalCarga?.close();
        this.router.navigate(['/pedidos']);
      };
    }
  }
  /**
   * Notifica a las organizaciones que se compro uno de sus productos y los puntos ganados
   */
  async notificarOrganizacion() {
    const organizaciones_a_notificar: any[] = [];
    this.productos.forEach((producto: any) => {
      let puntos_ganados = 0;
      if (producto.saldos == false && producto.promocion == false) {
        // Solo suma los puntos si esta dentro del rango de fechas
        const fecha_apertura = new Date(moment.utc(producto.fecha_apertura_puntosft).format('DD MMMM YYYY'));
        const fecha_cierre = new Date(moment.utc(producto.fecha_cierre_puntosft).format('DD MMMM YYYY'));
        if (
          producto.fecha_apertura_puntosft &&
          producto.fecha_cierre_puntosft &&
          fecha_apertura &&
          fecha_cierre &&
          fecha_apertura <= this.current_date &&
          fecha_cierre >= this.current_date
        ) {
          const pedido = this.order.products.filter((obj: any) => obj.product === producto._id);
          puntos_ganados = pedido[0].puntos_ft_unidad > 0 ? pedido[0].puntos_ft_unidad * pedido[0].unidad : 0;
        }
        const codigo_organizacion =
          producto.codigo_organizacion_producto != undefined ? producto.codigo_organizacion_producto : '"No Registra"';
        // Los puntos solo aplican para productos de catalogo (no saldos)
        const mensaje = {
          mensaje:
            'El cliente ' +
            this.punto_entrega.nombre +
            ' ha pedido el producto ' +
            producto.nombre +
            ' con el código ' +
            codigo_organizacion +
            ' y ganó ' +
            puntos_ganados +
            ' puntos',
          organizacion: producto.codigo_organizacion,
        };
        organizaciones_a_notificar.push(mensaje);
      }
    });
    await this.restService.putJWT(`notificar/trabajadores/organizacion`, organizaciones_a_notificar).toPromise();
  }

  /**************************************** Otras funcionalidades ***************************************/

  /** Generic Function to extract just id from array - use to verify an desk test */
  extractIds(arr: any) {
    const _id = [];
    for (const u in arr) {
      _id.push(arr[u]._id);
    }
    return _id;
  }

  /**
   * Cálculo de unidades para la caja de lista de productos
   * @productos lista de productos
   */
  calculoUnidades() {
    let unidades = 0;
    for (const iterator of this.order.products) {
      unidades += iterator.unidad;
    }
    return unidades;
  }

  /**
   * ir a detalle del distribuidor al que se le esta haciendo el pedido
   */
  continuarComprando() {
    this.router.navigate(['/distribuidores', this.id]);
  }

  calcularPrecioCaja(producto: any) {
    let precioDescuento =
      producto?.precios[0]?.precio_unidad - producto?.precios[0]?.precio_unidad * (producto?.prodPorcentajeDesc / 100);
    precioDescuento = Math.round(precioDescuento);
    return precioDescuento * producto?.precios[0]?.und_x_caja;
  }
}
