import { AgmMap, MapsAPILoader } from '@agm/core';
import { Options } from '@angular-slider/ngx-slider';
import { AfterViewInit, Component, DoCheck, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faClock, faTrashAlt, faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmacionComponent } from 'src/app/modal/confirmacion/confirmacion.component';
import { SimpleComponent } from 'src/app/modal/simple/simple.component';
import { PuntoEntrega } from 'src/app/models/punto_entrega.model';
import { Trabajador } from 'src/app/models/trabajador.model';
import { AuthService } from 'src/app/services/auth/auth.service';
import { MapService } from 'src/app/services/map/map.service';
import { PlacesService } from 'src/app/services/places/places.service';
import { RestService } from 'src/app/services/rest/rest.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastService } from 'src/app/services/tools/toast.service';

@Component({
  selector: 'app-detalle-punto-entrega',
  templateUrl: './detalle-punto-entrega.component.html',
  styleUrls: ['./detalle-punto-entrega.component.scss'],
})
export class DetallePuntoEntregaComponent implements OnInit, DoCheck, AfterViewInit {
  /** Referencias a íconos FontAwesome */
  faClock = faClock;
  faTrashAlt = faTrashAlt;
  faChevronLeft = faChevronLeft;
  faCheckCircle = faCheckCircle;
  /** Opciones de los modales que se van a lanzar */
  ngbModalOptions: NgbModalOptions = {
    backdrop: 'static',
    keyboard: false,
  };
  /** Datos sobre el punto de entrega */
  id_punto_entrega = '';
  punto_entrega?: PuntoEntrega;
  /** Controlador de los días de atención */
  dias_atencion: boolean[] = [false, false, false, false, false, false, false, false];
  dias: string[] = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado', 'Domingo', 'Festivos'];
  horario_lbl = '';
  /** Variables de control para el mapa */
  public map: any = { lat: 4.678508639544325, lng: -74.05550588007192 };
  @ViewChild('AgmMap') agmMap!: AgmMap;
  /** Listas de todos los trabajadores y de los trabajadores asociados */
  trabajadores: Trabajador[] = [];
  trabajador_actual = '';
  trabajadores_asociados: Trabajador[] = [];
  buffer_trabajadores_asociados: Trabajador[] = [];
  /**Guardara booleano para habilitar o no boton de continuar */
  public is_boton_habilitado = false;
  public validator_ciudad_existe = false;
  public validator_dia_seleccionado = false;
  /** Variables de control para el slider del horario de atención */
  minValue = 8;
  maxValue = 17;
  options: Options = {
    floor: 0,
    ceil: 24,
    step: 0.5,
    showTicks: false,
    translate: (): string => {
      return '';
    },
  };
  /** Variables para seleccionar el sitio del punto de entrega */
  departamentos: any;
  ciudades: any;
  /** Formularios reactivos */
  public puntoForm: FormGroup;

  mapClickListener: any;
  @ViewChild('editAddress') editAddress!: ElementRef;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private restService: RestService,
    private modalService: NgbModal,
    public authService: AuthService,
    private mapService: MapService,
    private formBuilder: FormBuilder,
    public toastService: ToastService,
    public places: PlacesService,
    private zone: NgZone,
    private mapsAPILoader: MapsAPILoader
  ) {
    this.puntoForm = this.formBuilder.group({
      puntoNombreEstablecimiento: ['', Validators.required],
      puntoTelefono: ['', [Validators.required, Validators.pattern(/^(?=[0-9]*$)(?:.{7}|.{10})$/)]],
      puntoSillas: ['', Validators.required],
      puntoDomicilio: ['', Validators.required],
      puntoPais: ['', Validators.required],
      puntoDepartamento: ['', Validators.required],
      puntoCiudad: ['', Validators.required],
      puntoDireccion: ['', Validators.required],
      puntoHorarios: [''],
      puntoEmpleadoNombre: ['', Validators.required],
      puntoEmpleadoApellido: ['', Validators.required],
      puntoEmpleadoCorreo: ['', [Validators.required, Validators.email]],
      puntoEmpleadoCelular: ['', [Validators.required, Validators.pattern(/^(?=[0-9]*$)(?:.{7}|.{10})$/)]],
      Lunes: [''],
      Martes: [''],
      Miércoles: [''],
      Jueves: [''],
      Viernes: [''],
      Sábado: [''],
      Domingo: [''],
      Festivos: [''],
    });
  }

  ngDoCheck(): void {
    this.checkIfFormIsValid();
    this.checkDiaSeleccionado();
  }

  async ngOnInit() {
    /**Se obtiene el departamento con API Imagine Apps*/
    this.id_punto_entrega = this.activatedRoute.snapshot.params.id;
    this.departamentos = await this.places.getDepartmentFromServerImagine();
    await this.getPuntoDeEntrega();
    /**Mostrar todos los campos invalidos de haberlos */
    this.puntoForm.markAllAsTouched();
  }

  ngAfterViewInit() {
    this.getCoordAutocomplete();
  }

  getCoordAutocomplete() {
    this.mapsAPILoader.load().then(() => {
      // Check if editAddress is defined before accessing its nativeElement
      if (this.editAddress) {
        const autoCompleteMaps = new google.maps.places.Autocomplete(this.editAddress.nativeElement);
        // Rest of your code remains the same
        autoCompleteMaps.addListener(`place_changed`, () => {
          this.zone.run(() => {
            const place: google.maps.places.PlaceResult = autoCompleteMaps.getPlace();
            // ... (rest of your logic)
          });
        });
      } else {
        console.error('editAddress reference not available');
      }
    });
  }

  enterMap() {
    let direccionBusqueda = '';
    if (this.puntoForm.get('puntoCiudad')?.value) {
      direccionBusqueda = `${this.puntoForm.get('puntoDepartamento')?.value},
        ${this.puntoForm.get('puntoCiudad')?.value}`;
    }
    if (this.puntoForm.get('puntoDireccion')?.value) {
      direccionBusqueda = direccionBusqueda
        ? `${this.puntoForm.get('puntoDireccion')?.value}, ${direccionBusqueda}`
        : this.puntoForm.get('puntoDireccion')?.value;
    }
    this.mapService
      .getLatLong(direccionBusqueda)
      .toPromise()
      .then((resp_map: any) => {
        if (resp_map.status == 'OK') {
          if (resp_map.results[0]) {
            this.map.lat = resp_map.results[0].geometry.location.lat;
            this.map.lng = resp_map.results[0].geometry.location.lng;
          }
        }
      });
  }

  /**
   * Recupera la lista de departamentos y ciudades de la API de Imagine Apps
   */
  async getPlaces() {
    let id_dpto = 0;
    for (const dpto of this.departamentos) {
      if (dpto.name == this.puntoForm.get('puntoDepartamento')?.value) {
        break;
      }
      id_dpto++;
    }
    if (this.departamentos[id_dpto] != undefined) {
      this.ciudades = await this.places.getCitiesFromServerImagine(this.departamentos[id_dpto].code);
    } else {
      this.ciudades = [];
    }
  }

  /**
   * Recupera la información guardada del punto de entrega
   */
  getPuntoDeEntrega() {
    this.restService
      .getJWT('punto_entrega/' + this.id_punto_entrega)
      .toPromise()
      .then((resp: any) => {
        if (resp == null) {
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'No fue posible encontrar la información de este punto. Por favor intenta de nuevo más tarde.';
          modalRef.componentInstance.btn_msg = 'Volver';
          modalRef.componentInstance.close_callback = () => {
            this.router.navigate(['/cuenta']);
          };
          return;
        }
        this.punto_entrega = new PuntoEntrega(
          resp.usuario_horeca,
          resp.nombre,
          resp.pais,
          resp.departamento,
          resp.ciudad,
          resp.telefono,
          resp.direccion,
          resp.informacion_contacto,
          resp.sillas,
          resp.domicilios,
          resp.numero_trabajadores,
          resp.tipo_aprobador,
          resp.dias_atencion || [],
          resp.horario,
          resp._id,
          resp.estado,
          undefined,
          resp.coord
        );
        this.procesarDiasDeAtencion();
        this.poblarTrabajadores();
        /**Se debe convertir el String de horario a un valor para darle el valor determinado al slider */
        const horarios = resp.horario.toString().split('-');
        this.minValue = this.timeToValue(horarios[0]);
        this.maxValue = this.timeToValue(horarios[1]);
        /**
         * Una vez recuperada la información del punto de entrega
         * re realiza el biding con el formulario para ver la data en el DOM
         */
        this.puntoForm.patchValue({
          puntoNombreEstablecimiento: this.punto_entrega.nombre,
          puntoTelefono: this.punto_entrega.telefono,
          puntoSillas: this.punto_entrega.sillas,
          puntoDomicilio: this.punto_entrega.domicilios,
          puntoPais: this.punto_entrega.pais,
          puntoDepartamento: this.punto_entrega.departamento,
          puntoCiudad: this.punto_entrega.ciudad,
          puntoDireccion: this.punto_entrega.direccion,
          puntoHorarios: this.punto_entrega.horario,
          puntoEmpleadoNombre: this.punto_entrega.informacion_contacto[0],
          puntoEmpleadoApellido: this.punto_entrega.informacion_contacto[1],
          puntoEmpleadoCorreo: this.punto_entrega.informacion_contacto[2],
          puntoEmpleadoCelular: this.punto_entrega.informacion_contacto[3],
          Lunes: this.dias_atencion[0],
          Martes: this.dias_atencion[1],
          Miércoles: this.dias_atencion[2],
          Jueves: this.dias_atencion[3],
          Viernes: this.dias_atencion[4],
          Sábado: this.dias_atencion[5],
          Domingo: this.dias_atencion[6],
          Festivos: this.dias_atencion[7],
        });
        /**Se obtiene la ciudad con API Imagine Apps*/
        this.getPlaces();
        /** Procesamos la dirección en el mapa */
        if (this.punto_entrega.coord?.lat) {
          this.map.lat = Number(this.punto_entrega.coord?.lat);
          this.map.lng = Number(this.punto_entrega.coord?.lng);
        } else {
          this.buscarDireccion();
        }
      })
      .catch((err) => {
        console.log(err);
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'No fue posible encontrar la información de este punto. Por favor intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/cuenta']);
        };
      });
  }

  /**
   * Recupera la lista de trabajadores asociados al usuario horeca
   * y de esos también toma los trabajadores que tienen asignados
   * a este punto
   */
  poblarTrabajadores() {
    const id_horeca: string = this.authService.user_horeca?._id || '';
    let new_trab: Trabajador;
    this.restService
      .getJWT('trabajador')
      .toPromise()
      .then((resp: any) => {
        for (const aux of resp) {
          if (id_horeca == aux.usuario_horeca && aux.solicitud_vinculacion == 'Aprobado') {
            new_trab = new Trabajador(
              aux.nombres,
              aux.apellidos,
              aux.correo,
              aux.clave,
              aux.telefono,
              aux.celular,
              aux.pais,
              aux.departamento,
              aux.ciudad,
              aux.tipo_documento,
              aux.numero_documento,
              aux.tipo_trabajador,
              aux.soliticutd_vinculacion,
              aux.show_slides,
              aux.usuario_horeca,
              aux.puntos_entrega || [],
              undefined,
              undefined,
              false,
              aux._id
            );
            this.trabajadores.push(new_trab);
            if (new_trab.puntos_entrega?.includes(this.punto_entrega?._id || '')) {
              this.trabajadores_asociados.push(new_trab);
              /**Se guarda una copia de los trabajadores asociados de tal forma que al guardar los cambios
               * comparemos los nuevos trabajadores vs los antiguos y se hagan los cambios en la bdd */
              this.buffer_trabajadores_asociados.push(new_trab);
            }
          }
        }
        /**Para una mejor UX se muestra la lista ordenada alfabeticamente */
        this.trabajadores.sort((a, b) => a.nombres.localeCompare(b.nombres));
        this.trabajadores_asociados.sort((a, b) => a.nombres.localeCompare(b.nombres));
      })
      .catch((err) => {
        console.log(err);
        const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
        modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
        modalRef.componentInstance.title = '¡Oh oh!';
        modalRef.componentInstance.msg =
          'No fue posible encontrar la información de este punto. Por favor intenta de nuevo más tarde.';
        modalRef.componentInstance.btn_msg = 'Volver';
        modalRef.componentInstance.close_callback = () => {
          this.router.navigate(['/cuenta']);
        };
      });
  }

  /**
   * Mira el arreglo de días de atencion traido desde el back, y dependiendo de los
   * días que encuentra levanta flags en el arreglo de booleans de días de atencion
   * para usar los checkboxes del template. También toma los días de atención y
   * los agrega al texto de la UI que se encarga de mostrar esta parte.
   */
  procesarDiasDeAtencion() {
    let index = -1;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    for (const dia of this.punto_entrega!.dias_atencion) {
      index = this.dias.indexOf(dia);
      if (index != -1) {
        this.horario_lbl += `${dia.substr(0, 3)}, `;
        this.dias_atencion[index] = true;
      }
    }
    this.horario_lbl = this.horario_lbl.substr(0, this.horario_lbl.length - 2); //Elimina la última coma y espacio
    this.horario_lbl += ` - ${this.punto_entrega?.horario}`;
  }

  /**
   * Toma la direccion registrada del punto de entrega y asigna a la variable de control
   * del mapa los valores encontrados de latitud y longitud por el servicio de mapas de
   * Google Maps
   */
  buscarDireccion(change?: boolean) {
    if (this.puntoForm.get('puntoCiudad')?.value != '') {
      this.mapService
        .getLatLong(`${this.puntoForm.get('puntoDepartamento')?.value}, ${this.puntoForm.get('puntoCiudad')?.value}`)
        .toPromise()
        .then((resp_map: any) => {
          if (resp_map.status == 'OK') {
            if (resp_map.results[0]) {
              this.map.lat = resp_map.results[0].geometry.location.lat;
              this.map.lng = resp_map.results[0].geometry.location.lng;
              if (change) {
                this.puntoForm.get('puntoDireccion')?.setValue(resp_map.results[0].formatted_address);
              }
              const bounds = new google.maps.LatLngBounds();
              bounds.extend(this.map);
              this.agmMap.mapReady.subscribe((map) => {
                map.fitBounds(bounds);
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  /**
   * Permite traducir un número del slider de horario a una
   * hora legible por el usuario
   * @param value El valor a traducir entre 0.0 y 24.0 en saltos de 0.5
   * @returns Un String entre 0:00 y 24:00 pensado como horas en saltos de 30 minutos
   */
  valueToTime(value: number): string {
    const value_str: string[] = value.toString().split('.');
    const hr: string = value_str[0];
    let mn = '00';
    if (value_str[1] != null) {
      mn = '30';
    }
    return hr + ':' + mn;
  }

  /**
   * Permite traducir un número del slider de horario a una
   * hora legible por el usuario
   * @param value El valor a traducir entre 0.0 y 24.0 en saltos de 0.5
   * @returns Un String entre 0:00 y 24:00 pensado como horas en saltos de 30 minutos
   */
  timeToValue(time: string): number {
    const horario: string[] = time.toString().split(':');
    const hora: number = parseInt(horario[0], 10);
    let minuto: number = parseInt(horario[1], 10);
    if (minuto != 0) {
      minuto = 0.5;
    }
    return hora + minuto;
  }

  /**
   * Cambia el estado del punto de entrega a "Desactivado"
   */
  desactivarPunto() {
    const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
    modalRef.componentInstance.msg = '¿Estás seguro de desactivar este punto de entrega?';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Desactivar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      const obj_aux: any = {
        estado: 'Desactivado',
      };
      this.restService
        .putJWT('punto_entrega/' + this.id_punto_entrega, obj_aux)
        .toPromise()
        .then(() => {
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'El punto de entrega fue desactivado correctamente.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            window.location.reload();
          };
        })
        .catch((err) => {
          console.log(err);
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'El punto de entrega no pudo ser desactivado por un error inesperado. Intenta de nuevo más tarde.';
          modalRef.componentInstance.btn_msg = 'Volver';
          modalRef.componentInstance.close_callback = () => {
            //this.router.navigate(["/cuenta"]);
          };
        });
    };
  }

  /**
   * Cambia el estado del punto de entrega a "Desactivado"
   */
  activarPunto() {
    const modalRef = this.modalService.open(ConfirmacionComponent, this.ngbModalOptions);
    modalRef.componentInstance.msg = '¿Estás seguro de activar este punto de entrega?';
    modalRef.componentInstance.btn_msg_no = 'Cancelar';
    modalRef.componentInstance.btn_msg_yes = 'Activar';
    modalRef.componentInstance.callback_no = () => {};
    modalRef.componentInstance.callback_yes = () => {
      const obj_aux: any = {
        estado: 'Activo',
      };
      this.restService
        .putJWT('punto_entrega/' + this.id_punto_entrega, obj_aux)
        .toPromise()
        .then(() => {
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'El punto de entrega fue activado correctamente.';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            window.location.reload();
          };
        })
        .catch((err) => {
          console.log(err);
          const modalRef = this.modalService.open(SimpleComponent, this.ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg =
            'El punto de entrega no pudo ser activado por un error inesperado. Intenta de nuevo más tarde.';
          modalRef.componentInstance.btn_msg = 'Volver';
          modalRef.componentInstance.close_callback = () => {
            //this.router.navigate(["/cuenta"]);
          };
        });
    };
  }

  /**Esta funcion tiene como objeto seleccionar un trabajdor de la lista
   * Para así agregarlo a la lista de trabajadores asignados que se muestra en el DOM
   * pero ante verifica si ya se encuentra dentro de los seleccionados, si está no lo agrega
   * Todos estos se guardan en un array para que al guardar los cambios se reflejen en la base de datos*/
  seleccionarTrabajador(target: any) {
    for (const iterator of this.trabajadores_asociados) {
      if (iterator == target) {
        return;
      }
    }
    this.trabajadores_asociados.push(target);
  }

  /**Esta funcion tiene como objeto actualizar los puntos de entrega de cada trabajador
   * Se recuperan todos los puntos de ventas por trabajador
   * Se verifica si se va a agregar (true) o a borrar (false)
   * Si se va a borrar se verifica si existe el punto en el trabajador y luego se elimina
   * Si se va a actualizar; se comaparan los puntos de ventas del trabajador con el que quiere agregar,
   * si el punto de venta no esta asignado al trabajador se agrega, en caso contrario no se agrega para evitar duplicidad
   */
  actualizarTrabajador(trab_id: any, punto_id: any, method: boolean) {
    let puntos_trabajador_almacenados: string[] = [];
    let obj = {};
    this.restService
      .getJWT('trabajador/' + trab_id)
      .toPromise()
      .then((resp: any) => {
        puntos_trabajador_almacenados = resp.puntos_entrega;
        if (method === true) {
          puntos_trabajador_almacenados.push(punto_id);
        } else if (method === false) {
          puntos_trabajador_almacenados = puntos_trabajador_almacenados.filter((item: any) => item._id != punto_id);
        }
        /*Nuevo aray con los puntos de entrega del trabajador actualizados y se cargan a la base de datos*/
        obj = { puntos_entrega: puntos_trabajador_almacenados };
        this.restService
          .putJWT('trabajador/' + trab_id, obj)
          .toPromise()
          .catch((err) => {
            console.log(err);
          });
      });
  }

  /**Esta funcion tiene como objeto guardar los cambios en el formulario de punto de venta */
  guardarCambios() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    /*********** Se consolida la información de contacto nuevamente *************/
    if (this.punto_entrega) {
      this.punto_entrega.informacion_contacto = [
        this.puntoForm.get('puntoEmpleadoNombre')?.value,
        this.puntoForm.get('puntoEmpleadoApellido')?.value,
        this.puntoForm.get('puntoEmpleadoCorreo')?.value,
        this.puntoForm.get('puntoEmpleadoCelular')?.value,
      ];
      /******************************* Trabajadores *****************************/
      /* Busca los trabajadores que van a ser borrados, comparandolos con un buffer
       * si el trabajador del buffer no esta en elarray nuevo, procede a borrarlo*/
      this.buffer_trabajadores_asociados.forEach((e) => {
        const removeIndex = this.trabajadores_asociados.map((item) => item._id).indexOf(e._id);
        if (removeIndex == -1) {
          this.actualizarTrabajador(e._id, this.punto_entrega?._id, false);
        }
      });
      /* Busca los trabajadores que van a ser agregados, comparandolos con un buffer
       * si el trabajador del array nuevo no está en el buffer, procede a borrarlo */
      this.trabajadores_asociados.forEach((e) => {
        const removeIndex = this.buffer_trabajadores_asociados.map((item) => item._id).indexOf(e._id);
        if (removeIndex == -1) {
          this.actualizarTrabajador(e._id, this.punto_entrega?._id, true);
        }
      });
      /*************************** Horarios de atención **************************/
      const nuevo_horario = this.valueToTime(this.minValue) + ' - ' + this.valueToTime(this.maxValue);
      this.punto_entrega.horario = nuevo_horario;
      /**************************** Fechas de atención ***************************/
      const nuevas_fechas = [];
      if (this.puntoForm.get('Lunes')?.value == true) {
        nuevas_fechas.push('Lunes');
      }
      if (this.puntoForm.get('Martes')?.value == true) {
        nuevas_fechas.push('Martes');
      }
      if (this.puntoForm.get('Miércoles')?.value == true) {
        nuevas_fechas.push('Miércoles');
      }
      if (this.puntoForm.get('Jueves')?.value == true) {
        nuevas_fechas.push('Jueves');
      }
      if (this.puntoForm.get('Viernes')?.value == true) {
        nuevas_fechas.push('Viernes');
      }
      if (this.puntoForm.get('Sábado')?.value == true) {
        nuevas_fechas.push('Sábado');
      }
      if (this.puntoForm.get('Domingo')?.value == true) {
        nuevas_fechas.push('Domingo');
      }
      if (this.puntoForm.get('Festivos')?.value == true) {
        nuevas_fechas.push('Festivos');
      }
      this.punto_entrega.dias_atencion = nuevas_fechas;
      /**************************** Se completa el objeto ***************************/
      this.punto_entrega.nombre = this.puntoForm.get('puntoNombreEstablecimiento')?.value;
      this.punto_entrega.telefono = this.puntoForm.get('puntoTelefono')?.value;
      this.punto_entrega.sillas = this.puntoForm.get('puntoSillas')?.value;
      this.punto_entrega.domicilios = this.puntoForm.get('puntoDomicilio')?.value;
      this.punto_entrega.ciudad = this.puntoForm.get('puntoCiudad')?.value;
      this.punto_entrega.departamento = this.puntoForm.get('puntoDepartamento')?.value;
      this.punto_entrega.pais = this.puntoForm.get('puntoPais')?.value;
      this.punto_entrega.direccion = this.puntoForm.get('puntoDireccion')?.value;
      this.punto_entrega.coord = { lat: this.map.lat, lng: this.map.lng };
      this.punto_entrega.formato_coordenada = {
        type: 'Point',
        coordinates: [parseFloat(this.map.lng), parseFloat(this.map.lat)],
      };
      /*************************** Se carga la informaciñon **************************/
      this.restService
        .putJWT('punto_entrega/' + this.punto_entrega?._id, this.punto_entrega)
        .toPromise()
        .then(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-check-verde.png';
          modalRef.componentInstance.title = '¡Genial!';
          modalRef.componentInstance.msg = 'Tus datos han sido actualizados con éxito';
          modalRef.componentInstance.btn_msg = 'Listo';
          modalRef.componentInstance.close_callback = () => {
            window.location.reload();
          };
        })
        .catch(() => {
          const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
          modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
          modalRef.componentInstance.title = '¡Oh oh!';
          modalRef.componentInstance.msg = 'Ocurrió un error inesperado ¡Por favor intenta de nuevo más tarde!';
          modalRef.componentInstance.btn_msg = 'Volver';
          modalRef.componentInstance.close_callback = () => {};
        });
    } else {
      const modalRef = this.modalService.open(SimpleComponent, ngbModalOptions);
      modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
      modalRef.componentInstance.title = '¡Oh oh!';
      modalRef.componentInstance.msg = '¡Por favor asegúrate de llenar todos los datos y vuelve a intentarlo!';
      modalRef.componentInstance.btn_msg = 'Volver';
    }
  }

  /**
   * Verifica si el formulario es valido y habilita o deshabilita el botón
   */
  checkIfFormIsValid() {
    this.is_boton_habilitado = false;
    // this.is_boton_habilitado = !this.puntoForm.invalid;
    if (this.validator_dia_seleccionado == true) {
      this.is_boton_habilitado = !this.puntoForm.invalid;
    }
  }

  /**
   * Recibe el dia que se está marcando en el Dom y realiza el cambio
   * en la data dado que no fue posible hacerlo con  ngmodel
   */
  getCheckbox(i: number) {
    this.dias_atencion[i] = !this.dias_atencion[i];
  }

  /**
   * Verifica que por lo menos un día de atención haya sido seleccionado
   */
  checkDiaSeleccionado() {
    this.validator_dia_seleccionado = false;
    if (
      this.puntoForm.get('Lunes')?.value == true ||
      this.puntoForm.get('Martes')?.value == true ||
      this.puntoForm.get('Miércoles')?.value == true ||
      this.puntoForm.get('Jueves')?.value == true ||
      this.puntoForm.get('Viernes')?.value == true ||
      this.puntoForm.get('Sábado')?.value == true ||
      this.puntoForm.get('Domingo')?.value == true ||
      this.puntoForm.get('Festivos')?.value == true
    ) {
      this.validator_dia_seleccionado = true;
    }
  }

  /**
   * No Avanza al siguiente paso y alerta/muestra lo errado
   * Meuestra toast si existe un input invalido en el formulario
   */
  showAlert() {
    this.toastService.show('Tienes campos pendientes por revisar', { classname: 'bg-danger text-light', delay: 10000 });
  }

  /**
   * Alerta si el formulario esta incompleto o un input es invalido
   */
  alertaFormularioInvalido() {
    const modalRef = this.modalService.open(SimpleComponent);
    modalRef.componentInstance.img_src = '../../../assets/img/icon-warning-amarillo.png';
    modalRef.componentInstance.title = '¡Oh oh!';
    modalRef.componentInstance.msg = '¡Por favor asegúrate de llenar todos los datos y vuelve a intentarlo!';
    modalRef.componentInstance.btn_msg = 'Volver';
    modalRef.componentInstance.close_callback = () => {};
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateNumber(event: any) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || excludedKeys.includes(keyCode))) {
      event.preventDefault();
    }
  }

  /**
   * Este metodo evita que en los inputs number se ingrese texto
   */
  validateOnlyText(event: any) {
    const keyCode = event.keyCode;
    if (keyCode >= 48 && keyCode <= 57) {
      event.preventDefault();
    }
  }

  public mapReadyHandler(map: google.maps.Map): void {
    this.mapClickListener = map.addListener('click', (e: google.maps.MouseEvent) => {
      this.zone.run(() => {
        this.map.lat = e.latLng.lat();
        this.map.lng = e.latLng.lng();

        this.buscarDireccionAutocompletada();
      });
    });
  }

  buscarDireccionAutocompletada() {
    this.mapsAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      const latlng = { lat: this.map.lat, lng: this.map.lng };
      geocoder.geocode({ location: latlng }, (results) => {
        if (results[0]) {
          this.puntoForm.get('puntoDireccion')?.setValue(results[0].formatted_address);
        }
      });
    });
  }
}
