import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-register',
  templateUrl: './header-register.component.html',
  styleUrls: ['./header-register.component.css'],
})
export class HeaderRegisterComponent implements OnInit {
  constructor() {}

  public isMenuCollapsed = true;

  ngOnInit(): void {}

  public verDocumento(link: any) {
    window.open(link, '_blank');
  }
}
