<div class="row">
  <div class="col-8 col-md-9">
    <br />
    <!-- <h5 class="font-weight-300">{{ cantidadClientes }}/{{ cantidadMaxima }} clientes de tu plan</h5> -->
    <h5 class="font-weight-300">{{ data.length }} cliente{{data.length !== 1 ? 's' : ''}} de tu plan
    </h5>
  </div>
  <!--<div class="col-3 col-md-3">
    <div class="card" style="cursor: pointer">
      <div class="card-body card-body-btn-hist" (click)="verSolicitudes()">
        <p>
          Historial de solicitudes &nbsp;
          <fa-icon [icon]="faChevronRight"></fa-icon>
        </p>
      </div> todos, nuevos, activos (compras), creados, graficas
    </div>
  </div>
  <div class="d-none d-md-block col-0 col-md-3">
    <button class="btn-blanco" (click)="cargarClientesDesdeExcel()">Cargar pre aprobados de xls</button>
  </div>
  <div class="col-12 col-md-3">
    <button (click)="onPreAprobar()" class="btn-morado">+ Pre aprobar cliente</button>
  </div>-->
</div>
<div class="row">
  <div class="col-12 col-md-12" *ngIf="data">
    <div class="card overflow-hidden px-4">
      <div class="card-body px-0" style="overflow: scroll;">
        <ng2-smart-table [settings]="settings" [source]="source" (custom)="onCustom($event)"></ng2-smart-table>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-8" *ngIf="!data">
    <div class="card overflow-hidden px-4">
      <div class="card-body scroll-tabla px-0">
        NO TIENES CLIENTES O AÚN NO TIENES CLIENTES VINCULADOS
      </div>
    </div>
  </div>
  <!--<div class="col-12 col-md-4 mt-4 mt-md-0">
    <div class="row">
     <div class="col-5 text-center">
        <br />
        <p>Solicitudes ({{ distribuidores?.length }})</p>
      </div>
      <div class="col-7 text-center">
        <div class="card" style="cursor: pointer">
          <div class="card-body card-body-btn-hist" (click)="verSolicitudes()">
            <p>
              Historial de solicitudes &nbsp;
              <fa-icon [icon]="faChevronRight"></fa-icon>
            </p>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="scroll-clientes">
      <div class="row">
        <div class="col-12">
          <div *ngIf="distribuidores?.length > 0; else mostrarError">
            <div *ngFor="let item of distribuidores; let i = index">
              <div *ngIf="item.estado === 'Pendiente'">
                <app-solicitud-card [dist_vinculado]="item"></app-solicitud-card>
              </div>
            </div>
          </div>
          <ng-template #mostrarError>No hay solicitudes pendientes</ng-template>
        </div>
      </div>
    </div>
  </div>-->
</div>